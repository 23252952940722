import { Skeleton } from "@mui/material";
import { useLocation } from "../util/db";


  export default function ItemLocation({ locationId }) {
    const { data: itemLocationData } = useLocation(locationId);

    // If the itemLocationData is undefined, it means we're still waiting for the data
    if (itemLocationData === undefined) {
        return <>Loading...</>;
    }

    // If itemLocationData is null, it means the location was not found
    if (itemLocationData === null) {
        return <br /> ;
      }

    // If we made it this far, itemLocationData should be an object with a name property
    return <span>{itemLocationData.name}</span>;
}







  