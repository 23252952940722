import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { usePublishItem, updateList, useList } from "./../util/db";
import Chip from "@mui/material/Chip";
import UndoIcon from "@mui/icons-material/Undo";
import CancelIcon from "@mui/icons-material/Cancel";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { requireAuth } from "./../util/auth";
import {
  Divider,
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useClipboard } from "../util/functions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { validate } from "uuid";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function PublishItems(props) {
  // const { publishItem, loading, error } = usePublishItem();
  const { items = [], selectedListId, inputItem } = props;
  const {loading, setLoading} = useState(false);
  console.log("items to publish", items);
  const [showModal, setShowModal] = useState(false);
  // const [selectedFields, setSelectedFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [numItems, setNumItems] = useState(items.length);
  const originalFieldsRef = useRef(selectedFields);
  const [isPublishing, setIsPublishing] = useState(false);
  const excludedFields = [
    "locationId",
    "updatedAt",
    "createdAt",
    "listId",
    "id",
    "owner",
  ];
  const { copied, copyToClipboard } = useClipboard();
  const { listData, listStatus } = useList(props.selectedListId);
  const isPublished = listData && listData.published;
  const [isModified, setIsModified] = useState(false);
  console.log("isPublished", isPublished);
  const [listUpdated, setListUpdated] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const requiredFields = ["name", "id", "listId", "owner"];
  const [publishError, setPublishError] = useState('');
  const capitalizeFirstLetter = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const handleChipClick = (field) => {
    if (selectedFields.includes(field)) {
      setSelectedFields((prevFields) => prevFields.filter((f) => f !== field));
    } else {
      setSelectedFields((prevFields) => [...prevFields, field]);
    }
  };
  const [openDialog, setOpenDialog] = useState(false);

  const handleAddMoreFieldsClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (listUpdated) {
      console.log("List was updated!");

      // Add any other logic here you'd like to run when the list is updated

      // Reset listUpdated state to false for subsequent updates
      setListUpdated(false);
    }
  }, [listUpdated]);

  const handleUpdateFields = async () => {
    try {
      await updateList(selectedListId, { selectedFields: selectedFields });
      console.log("Selected fields updated successfully!");
      setSnackbarOpen(true);
      setIsModified(false); // Reset isModified state
    } catch (error) {
      console.error("Failed to update selected fields.");
    }
  };

  const listName = listData?.name;
  const sanitizedListName = listName?.replace(/\s+/g, "-");
  // const url = `https://itemgenie.com/${sanitizedListName}-${selectedListId}`;
  const url = `https://itemgenie.com/${sanitizedListName}-${selectedListId}`;

  const handleCopyClick = () => {
    copyToClipboard(url);
  };

  useEffect(() => {
    const modified =
      JSON.stringify(selectedFields.sort()) !==
      JSON.stringify(originalFieldsRef.current.sort());
    setIsModified(modified);
  }, [selectedFields]);

  useEffect(() => {
    if (listData && listData.selectedFields) {
      setSelectedFields(listData.selectedFields);
      originalFieldsRef.current = listData.selectedFields;
      console.log("listData", listData);
    }
  }, [listData]);

  /// Note to Future Mike -- Not Very Happy With This UseEffect.

  //   useEffect(() => {
  //     let isMounted = true;

  //     const publishIfReady = async () => {
  //         if (isMounted && !isPublishing && items) {
  //             await handlePublish();
  //         }
  //     };

  //     publishIfReady();

  //     return () => {
  //         isMounted = false;  // Set isMounted to false to avoid setting state on unmounted component
  //     };
  // }, [items]);

  /// Note to Future Mike -- Not Very Happy With This UseEffect.

  // useEffect(() => {
  //   let isMounted = true;

  //   if (listData && !listData.published) return;

  //   console.log("triggered???");
  //   const checkAndUpdateItems = async () => {
  //     while (isMounted) {

  //       if (items.length !== numItems) {

  //         // console.log("length compare", items.length, numItems);

  //         // setNumItems(items.length);
  //         console.log("triggered???");
  //         ////// THIS IS THE PROBLEM LINE BELOW
  //         await handlePublish();
  //       }

  //       await new Promise((resolve) => setTimeout(resolve, 1000));
  //     }
  //   };

  //   checkAndUpdateItems();
  //   console.log("use effect is running");

  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  // useEffect(() => {
  //   let isMounted = true;

  //   if (listData && !listData.published && isMounted) return;

  //   console.log("Triggered useEffect for item validation and publication.");

  //   console.log("items length", items.length, numItems);

  //   const checkAndUpdateItems = async () => {
  //     if (items && isMounted && items.length !== numItems) {
  //       console.log("Length compare", items.length, numItems);
  //       await handlePublish();
  //       setNumItems(items.length); // Update the state to reflect the new number of items

  //       // This is the correct use: ensure all items are valid before publishing
  //     } else {
  //       console.log(
  //         "Not all items are valid for publishing, or no change in item count."
  //       );
  //     }

  //     await new Promise((resolve) => setTimeout(resolve, 1000)); // Throttle the checks
  //   };

  //   checkAndUpdateItems();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [items]);


  const handleSaveChanges = async () => {
    setIsSavingChanges(true); // Set the state to indicate that changes are being saved
    // await handlePublish();
    await handleUpdateFields();
    setIsSavingChanges(false); // Reset the state when changes are saved
  };

  // const handlePublish = async () => {
  //   try {
  //     if (!items || !Array.isArray(items) || items.length === 0) {
  //       console.log(
  //         "Items is undefined, null, or an empty array, returning early."
  //       );
  //       return;
  //     }
  //     console.log("is this happening?");
  //     const allFields = Object.keys(items[0]);
  //     let allPublished = true;

  //     for (const item of items) {
  //       const itemId = item.id;
  //       if (!itemId) {
  //         console.error(
  //           `Invalid or incomplete data for item with ID: ${itemId}`
  //         );
  //         allPublished = false;
  //         continue; // Skip to the next item if current one is invalid
  //       }

  //       const success = await publishItem(
  //         itemId,
  //         selectedFields,
  //         selectedListId,
  //         allFields
  //       );
  //       if (!success) {
  //         console.error(`Failed to publish item with ID: ${itemId}`);
  //         allPublished = false; // Maintain the state, will reset `isPublishing` after loop
  //       } else {
  //         console.log(`Item with ID: ${itemId} published successfully!`);
  //       }
  //     }

  //     if (allPublished) {
  //       try {
  //         await updateList(selectedListId, {
  //           published: true,
  //           selectedFields: selectedFields,
  //         });
  //         console.log("List marked as published successfully!");
  //         setListUpdated(true);
  //         setIsModified(false);
  //       } catch (error) {
  //         console.error("Failed to mark the list as published.", error);
  //       }
  //     }
  //   } finally {
  //     // Ensure we reset this state no matter what happens
  //   }

  //   await updateList(selectedListId, {
  //     published: true,
  //     selectedFields: selectedFields,
  //   });

  //   console.log("List marked as published successfully!");

  //   setListUpdated(true);
  //   setIsModified(false);

  //   setShowModal(false);
  // };


  const handleSimplePublish = async (newPublishStatus) => {
    setIsPublishing(true);
    setPublishError('');
    try {
      await updateList(selectedListId, { published: newPublishStatus });
      console.log(`List ${newPublishStatus ? 'published' : 'unpublished'} successfully.`);
    } catch (error) {
      console.error('Error updating publish status:', error);
      setPublishError('Failed to update publish status.');
    }
    setIsPublishing(false);
  };

  // const handleUnpublish = async () => {
  //   try {
  //     await updateList(selectedListId, { published: false });
  //     console.log("List unpublished successfully.");
  //     // Optional: update local state or refetch data if needed
  //   } catch (error) {
  //     console.error("Error unpublishing list:", error);
  //   }
  // };

  return (
    <>
      <Box
        p={2}
        bgcolor="grey.100"
        display="flex"
        flexDirection="column"
        pt={1}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">{listData?.name}</Typography>

          <div style={{ position: "relative", display: "inline-block" }}>
            <IconButton
              onClick={handleCopyClick}
              variant="filled"
              size="small"
              color="primary"
              // color="inherit"
            >
              <ContentCopyIcon />
            </IconButton>

            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                position: "absolute",
                bottom: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                visibility: copied ? "visible" : "hidden",
                opacity: copied ? 1 : 0,
                transition: "opacity 0.3s",
              }}
            >
              Copied
            </Typography>
          </div>
        </Box>

        <Box display="flex" alignItems="center">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography
              color="textSecondary"
              variant="subtitle2"
              sx={{ textDecoration: "underline" }}
            >
              {url}
            </Typography>
          </a>
        </Box>
      </Box>

      <Box py={1}>
        <Divider />
      </Box>



      <Box py={1}>
        {!items.length ? (
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSimplePublish(true)}
              disabled={true}
            >
              Publish
            </Button>
          </Box>
        ) : (
          <div>
            <Box display="flex" justifyContent="flex-end">
              {!isPublished && isModified && !listUpdated && (
                <Box mr={1}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={handleUpdateFields}
                    disabled={loading}
                  >
                    Save Changes
                  </Button>
                </Box>
              )}
              {isPublished && isModified && !listUpdated && (
                <Box mr={1}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveChanges}
                    disabled={loading || isSavingChanges}
                  >
                    {isSavingChanges ? "Saving..." : "Save Changes"}
                  </Button>
                </Box>
              )}

              {isPublished && (
                <>
                  <Box mr={1}>
                    <Button
                      size="large"
                      variant="outlined"
                      color="error"
                      onClick={() => handleSimplePublish(false)}
                      disabled={loading || !items.length}
                      // startIcon={<UndoIcon />}
                    >
                      Unpublish List
                    </Button>
                  </Box>
                </>
              )}

              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSimplePublish(true)}
                  disabled={loading}
                  sx={{
                    backgroundColor: isPublished ? "#e91e63" : "",
                    pointerEvents: isPublished ? "none" : "auto",
                  }}
                >
                  {isPublished ? "Published" : "Publish"}
                </Button>
              </>
            </Box>

            <Box pt={1}>
              {selectedFields.slice(0, 5).map((field) => (
                <Chip
                  key={field}
                  label={capitalizeFirstLetter(field)}
                  clickable
                  style={{ margin: "5px" }}
                  deleteIcon={<CancelIcon />}
                  onDelete={() => {
                    setSelectedFields((prevFields) =>
                      prevFields.filter((f) => f !== field)
                    );
                  }}
                />
              ))}

              <Chip
                label="Select More Fields"
                clickable
                variant="outlined"
                icon={<AddIcon />}
                onClick={handleAddMoreFieldsClick}
                style={{ margin: "5px" }}
              />

              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <Box display="flex" justifyContent="space-between" p={2}>
                  <DialogTitle>
                    Add Fields to List Items
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={handleCloseDialog}
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "12px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                </Box>
                <DialogContent>
                  {Object.keys(items[0])
                    .filter(
                      (field) =>
                        !excludedFields.includes(field) &&
                        !selectedFields.includes(field)
                    )
                    .map((field) => (
                      <Chip
                        key={field}
                        label={capitalizeFirstLetter(field)}
                        clickable
                        onClick={() => handleChipClick(field)}
                        style={{ margin: "5px" }}
                      />
                    ))}
                </DialogContent>
              </Dialog>
            </Box>

            {/* </Modal> */}
          </div>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Changes Saved
        </Alert>
      </Snackbar>
    </>
  );
}

export default requireAuth(PublishItems);
