import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Section from "./Section";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useTheme } from "@mui/styles";

function Navbar2(props) {
  const theme = useTheme();
  const auth = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && theme.name === "dark"
      ? props.logoInverted
      : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            <Link to="/">
              <Box component="img" src={logo} alt="Logo" sx={{ height: 28 }} />
            </Link>
            <Box sx={{ ml: 2, display: { md: "block", xs: "none" } }}>
              <Button component={Link} to="/about" color="inherit">
                About
              </Button>
              <Button component={Link} to="/pricing" color="inherit">
                Pricing
              </Button>
              <Button
                color="inherit"
                aria-label="More"
                aria-controls="more-menu"
                aria-haspopup="true"
                onClick={(event) => {
                  handleOpenMenu(event, "more-menu");
                }}
              >
                More
                <ExpandMoreIcon />
              </Button>
              <Menu
                id="more-menu"
                open={menuState && menuState.id === "more-menu" ? true : false}
                anchorEl={menuState && menuState.anchor}
                onClick={handleCloseMenu}
                onClose={handleCloseMenu}
                keepMounted={true}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem component={Link} to="/faq">
                  FAQ
                </MenuItem>
                <MenuItem component={Link} to="/contact">
                  Contact
                </MenuItem>
                <MenuItem
                  component="a"
                  href="https://medium.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </MenuItem>
              </Menu>
            </Box>
            <IconButton
              onClick={() => setDrawerOpen(true)}
              color="inherit"
              size="large"
              sx={{ ml: "auto", display: { md: "none", xs: "block" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ ml: "auto", display: { md: "block", xs: "none" } }}>
              {!auth.user && (
                <>
                  <Button component={Link} to="/auth/signin" color="inherit">
                    Sign in
                  </Button>

                  <Button
                    component={Link}
                    to="/auth/signup"
                    variant="contained"
                    color="primary"
                    sx={{ ml: 1 }}
                  >
                    Sign up
                  </Button>
                </>
              )}

              {auth.user && (
                <>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    Account
                    <ExpandMoreIcon />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem component={Link} to="/dashboard">
                      Dashboard
                    </MenuItem>
                    <MenuItem component={Link} to="/settings/general">
                      Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      Signout
                    </MenuItem>
                  </Menu>
                </>
              )}

              <IconButton
                color="inherit"
                onClick={theme.toggle}
                style={{ opacity: 0.6 }}
                size="large"
              >
                {theme.name === "dark" && <NightsStayIcon />}

                {theme.name !== "dark" && <WbSunnyIcon />}
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List onClick={() => setDrawerOpen(false)} sx={{ width: "250px" }}>
          <ListItem component={Link} to="/about" button={true}>
            <ListItemText>About</ListItemText>
          </ListItem>
          <ListItem component={Link} to="/pricing" button={true}>
            <ListItemText>Pricing</ListItemText>
          </ListItem>
          <ListItem component={Link} to="/faq" button={true}>
            <ListItemText>FAQ</ListItemText>
          </ListItem>
          <ListItem component={Link} to="/contact" button={true}>
            <ListItemText>Contact</ListItemText>
          </ListItem>
          <ListItem
            button={true}
            component="a"
            href="https://medium.com"
            target="_blank"
            rel="noreferrer"
          >
            <ListItemText>Blog</ListItemText>
          </ListItem>

          {!auth.user && (
            <>
              <ListItem component={Link} to="/auth/signin" button={true}>
                <ListItemText>Sign in</ListItemText>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/auth/signup"
                  variant="contained"
                  color="primary"
                >
                  Sign up
                </Button>
              </ListItem>
            </>
          )}

          {auth.user && (
            <>
              <ListItem component={Link} to="/dashboard" button={true}>
                <ListItemText>Dashboard</ListItemText>
              </ListItem>
              <ListItem component={Link} to="/settings/general" button={true}>
                <ListItemText>Settings</ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button={true}
                onClick={(event) => {
                  auth.signout();
                }}
              >
                <ListItemText>Sign out</ListItemText>
              </ListItem>
            </>
          )}

          <ListItem>
            <IconButton
              color="inherit"
              onClick={theme.toggle}
              style={{ opacity: 0.6 }}
              size="large"
            >
              {theme.name === "dark" && <NightsStayIcon />}

              {theme.name !== "dark" && <WbSunnyIcon />}
            </IconButton>
          </ListItem>
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar2;
