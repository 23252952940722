import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useLocation } from "./../util/db";
import { Button } from "@mui/material";

function LocationRow(props) {
  const { id, name, parentLocationId } = props;

  const { data: parentLocationData } = useLocation(parentLocationId);

  return (
    <TableRow hover={true}>
      <TableCell>{name}</TableCell>
      <TableCell>
        {parentLocationData ? parentLocationData.name : "--"}
      </TableCell>
      <TableCell align="right">
        <Button variant="outlined" size="small" color="primary">
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default LocationRow;
