import { Html5QrcodeScanner } from "html5-qrcode";
import {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Html5Qrcode } from "html5-qrcode";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Box, Button, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import CheckIcon from "@mui/icons-material/Check";
import QrCode2Icon from '@mui/icons-material/QrCode2';
const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.




const createConfig = (cameraId) => {
  const config = {
    fps: 100,
    qrbox: 250,
    aspectRatio: 1,
    disableFlip: false,
    // cameraId: cameraId || { facingMode: "environment" },
    // Uncomment the following if you need specific formats
    // formatsToSupport: [Html5QrcodeSupportedFormats.EAN_13],
  };

  return config;
};

const QRCodeAdd = forwardRef((props, ref) => {

  const scannerRef = useRef(null);
  const html5Qrcode = useRef(null);
  const [scanSuccessful, setScanSuccessful] = useState(false);
  const [isScannerRunning, setIsScannerRunning] = useState(false);
  const [scanCode, setScanCode] = useState();
  const {inputScanCode} = props;

  useEffect(() =>{

    console.log("QRCodeAdd inputScanCode", inputScanCode)

  }, [inputScanCode])


  useEffect(() => {
    // Your code here will run after the component is first rendered

    return () => {
      // Your code here will run when the component is about to unmount
      console.log("The component is about to unmount");
      //if (isScannerRunning) {
      handleStopScan();
      //}
    };
  }, []);

  useEffect(() => {
    html5Qrcode.current = new Html5Qrcode(qrcodeRegionId);
  }, []);

  const handleStartScan = async (event) => {
    // event.preventDefault();

    if (event) {
      event.preventDefault();
    }
    try {
    const config = createConfig(props);
    const verbose = props.verbose === true;

   
      await html5Qrcode.current.start(
        { facingMode: "environment" },
        config,
        async (message) => {
          props.qrCodeSuccessCallback(message);
          setScanSuccessful(true);
          await handleStopScan(); // No event object passed
        },
        props.qrCodeErrorCallback,
        verbose
      );
      setIsScannerRunning(true);
      console.log("Scanner started."); // <-- debug log
    } catch (error) {
      handleStopScan();
      if (error.name === "NotAllowedError") {
        alert("Please allow camera access to scan QR code.");
      } else {
        console.error("Please allow camera access to scan QR code.", error);
      }
    }
  };

  useEffect(() => {
    if (scanSuccessful) {
      handleStopScan();
    }
  }, [scanSuccessful]);

  const handleStopScan = async (event) => {
  // If an event is passed (like from a button click), prevent the default action
  if (event) {
    event.preventDefault();
  }

  try {
    await html5Qrcode.current.stop();

    // Only stop video tracks if scannerRef.current is not null
    if (scannerRef.current) {
      // Get any video tracks from the scanner's video element and stop them
      const videoElem = scannerRef.current.querySelector("video");
      if (videoElem && videoElem.srcObject) {
        const stream = videoElem.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
          if (track.kind === "video") {
            track.stop();
          }
        });

        videoElem.srcObject = null;
      }
    }
  } catch (error) {
    console.log("Scanner may not have been running:", error);
  } finally {
    setIsScannerRunning(false);
  }
};




  const [isScanning, setIsScanning] = useState(false);

  const handleScanButton = (event) => {
    event.preventDefault();
    if (!isScanning) {
      handleStartScan();
    } else {
      handleStopScan();
    }
    setIsScanning(!isScanning);
  };

  const buttonText = isScanning ? "Cancel" : "Add QR/Barcode";



  useImperativeHandle(ref, () => ({
    startScan: handleStartScan,
  }));

  const handleRescan = async (event) => {
    event.preventDefault();
    setScanSuccessful(false);
    await handleStartScan(); // Wait for handleStartScan to complete
  };

  if (scanSuccessful || inputScanCode) {
    const icon = "Successful Scan";
    return (
      <>

<div id={qrcodeRegionId} ref={scannerRef} className="scanner" />

        <Box

          display="flex"
          alignItems="center"
          py={2}
          onClick={handleScanButton}
        >
  
            <Button
            fullWidth
           
            variant="outlined"
              startIcon=
                {isScannerRunning ? 
                    
                    <QrCodeScannerIcon style={{ fontSize: 50 }} />
                    :

                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={<CheckIcon style={{ color: "#fff", fontSize:10 }} />}
                  color="success"
                >
                  <QrCode2Icon style={{ fontSize: 50 }} />
                </Badge>
                }
              
           
            //   onClick={handleRescan}
            >
             {isScannerRunning ? "Cancel" : "Rescan QR/Barcode" }
            </Button>

     
   
          </Box>
          {/* <Button
            // startIcon={<QrCodeScannerIcon style={{ fontSize: 50 }} />}
            variant="outlined"
            onClick={handleScanButton}
          >
           {isScannerRunning ? "Cancel" : "Add New QR/Barcode" }
          </Button> */}
      
      </>
    );
  } else
    return (
      //   <div className="scanner-container">
      <>

        <div id={qrcodeRegionId} ref={scannerRef} />

        <Box display="flex"  py={2} onClick={handleScanButton}>
          <Button
             fullWidth
            startIcon={<QrCodeScannerIcon style={{ fontSize: 50 }} />}
            variant="outlined"
            onClick={handleScanButton}
          >
            {buttonText}
          </Button>
        </Box>
    
      </>
      
      //
    );
});

export default QRCodeAdd;
