import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import ItemsSpreadsheet from "./../components/ItemsSpreadsheet";

function SpreadsheetPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <ItemsSpreadsheet />
    </>
  );
}

export default requireAuth(SpreadsheetPage);
