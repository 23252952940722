import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useDeleteLocationWithChildren,
  useDeleteLocation,
  updateUser,
  useFetchUser,
} from "../util/db";
import { useAuth } from "./../util/auth";
import { Container, ownerDocument } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";







const DeleteConfirmation = ({
  deleteWarningOpen,
  setDeleteWarningOpen,
  location,
  onLocationDeleted,
  handleDelete,
  isDeleting,
  handleConfirmedDelete,
  rememberChoice,
  setRememberChoice,
}) => {
  //   const [isDeleting, setIsDeleting] = useState(false);

  const auth = useAuth();
  //   const deleteLocationWithChildren = useDeleteLocationWithChildren();

  const { user, loading } = useFetchUser(auth.user.uid);

  useEffect(() => {
    console.log("DeleteConfirmation deleteWarningOpen", deleteWarningOpen);
  }, [deleteWarningOpen]);

  useEffect(() => {
    console.log("DeleteConfirmation location", location);
  }, [location]);

  useEffect(() => {
    console.log("DeleteConfirmation start");
  }, []);

  return (
    <>
      <Dialog
        open={deleteWarningOpen}
        onClose={() => setDeleteWarningOpen(false)}
      >
        <DialogTitle sx={{
 display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'

        }}
        
        >Delete Location
        <IconButton onClick={() => setDeleteWarningOpen(false)} >
            <CloseIcon />
        </IconButton>

          
        </DialogTitle>
    
        <DialogContent>
          <Alert severity="error">
            <AlertTitle>Warning</AlertTitle>
            This will permanently delete all data at this location, including nested locations and items.
          </Alert>
      
       
        <DialogActions>
          <Button onClick={() => setDeleteWarningOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleConfirmedDelete}
            color="error"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "Start delete"}
          </Button>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteConfirmation;
