import React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import * as colors from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createLocalStorageStateHook } from "use-local-storage-state";

const themeConfig = {
  // Light theme
  light: {
    palette: {
      mode: "light",
      primary: {
        // Use hue from colors or hex
        main: colors.blueGrey["700"],
        // Uncomment to specify light/dark
        // shades instead of automatically
        // calculating from above value.
        //light: "#4791db",
        //dark: "#115293",
      },
      secondary: {
        main: colors.pink["500"],
      },
      // Define your custom colors
      custom: {
        // purple: "#961fc2",
        // red: "#fd616c",
        // blue: "",
        purple: "#f1eaf4", // soft purple
        red: "#fc585c",    // bold red
        blue: "#84b1fd",   // bold blue
        green: "#40cb8f",
        orange: "#fdaf69",

      },

      background: {
        // Background for <body>
        // and <Section color="default">
        default: "#fff",
        // Background for elevated
        // components (<Card>, etc)
        paper: "#fff",
      },
    },
  },

  // Dark theme
  dark: {
    palette: {
      mode: "dark",
      primary: {
        // Lighter shade so it stands out on dark
        main: colors.blue["300"],
      },
      secondary: {
        main: colors.pink["500"],
      },
      background: {
        default: colors.grey["900"],
        paper: colors.grey["800"],
      },
    },
  },

  // Example theme (you can have more than just light/dark)
  // Switch to this theme by calling `theme.set("wacky")` in a component
  wacky: {
    palette: {
      mode: "light",
      primary: {
        main: colors.purple["300"],
      },
    },
    typography: {
      // This font will only be applied if you remove `fontFamily`
      // from the `shared` object below and specify it in each theme
      fontFamily: '"Comic Sans MS", "Comic Sans',
    },
  },

  // Values shared by all themes
  // These will be merged into the current theme
  shared: {
    typography: {
      fontSize: 14,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      // Uncomment to make button lowercase
      button: { textTransform: "none" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    // Customize specific components
    // Docs: https://mui.com/material-ui/customization/theme-components/
    components: {
      // Customize global style component
      MuiCssBaseline: {
        styleOverrides: {
          "#root": {
            // Flex column that is height
            // of viewport so that footer
            // can push self to bottom by
            // with auto margin-top
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            // Prevent child elements from
            // shrinking when content
            // is taller than the screen
            // (quirk of flex parent)
            "& > *": {
              flexShrink: 0,
            },
          },
        },
      },
      // Customize Link component
      MuiLink: {
        defaultProps: {
          // Only underline links on hover
          underline: "hover",
        },
      },

      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingRight: 0,
          },
        },
      },


      MuiDialog: {
        styleOverrides: {
          paper: {
            "@media (min-width: 900px)": {
       
              borderRadius: "16px", 
            },
          },
        },
      },


      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            // Remove focus styles on mobile devices
            "@media (hover: none)": {
              "&:focus": {
                boxShadow: "none",
                backgroundColor: "inherit", // Assuming you want to keep the original background color
              },
            },
          },
        },
        defaultProps: {
          disableElevation: true,
          disableRipple: true,  // Disabling ripple effect
        },
      },

      // Customize Button component (example)
      /*
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            "&:hover": {
              boxShadow: "none",
            },
          },
          sizeLarge: {
            height: "48px",
          },
        },
      },
      */
    },
  },
};

// Create a local storage hook for storing theme preference
const useStoredTheme = createLocalStorageStateHook(
  "storedTheme",
  // Default theme ("light", "dark", undefined)
  // Use undefined to fall back to system preference (recommended)
  undefined
);

export const ThemeProvider = (props) => {
  // Get stored theme preference
  let [storedTheme, setStoredTheme] = useStoredTheme();

  // Get system dark mode preference
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  // Get theme preference with fallback to light/dark based on system setting
  const themeName = "light";

  // Create final theme object
  const theme = createTheme(
    deepmerge(themeConfig[themeName], themeConfig.shared),
    {
      // Extra values to add to theme object
      name: themeName,
      // You can remove the set and toggle functions if they are not needed elsewhere
      set: (name) => {}, // No-op function or handle differently if needed
      toggle: () => {}, // No-op function or handle differently if needed
    }
  );

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        {/* Set global MUI styles */}
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
