import React from 'react';
import { useRouter } from '../util/router';
import { useItem, useFirebaseImage } from './../util/db'; // Adjust the import path accordingly

function PublicItemPage(props) {
    const itemId = item.id;  // Extract the item ID from the match prop

    // Fetch the item data using the provided hook
    const { data: item, status } = useItem(itemId);

    // Fetch the item's image using the provided `useFirebaseImage` hook
    const imageUrl = useFirebaseImage(item?.imagePath);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (!item) {
        return <div>Item not found.</div>;
    }

    return (
        <div>
            <h1>{item.name}</h1>
            {imageUrl && <img src={imageUrl} alt={item.name} />}
            <p>{item.description}</p>
        </div>
    );
}
export default PublicItemPage;
