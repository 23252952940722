import { useState, useEffect, useRef } from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import LocationTreeView from "../components/LocationTreeView";
import {
  useItem,
  useLocationsByOwner,
  updateLocation,
  useItemsByOwner,
  getItemsByScanCode,
  getLocationsByScanCode,
  updateList,
} from "../util/db";
import {
  getLatestItem,
  getLatestLocation,
  findLocationPath,
} from "../util/functions";
import EditLocationModal from "../components/EditLocationModal";
import { isNestedChild } from "../util/functions";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import { useAuth } from "./../util/auth";
import CreateIcon from "@mui/icons-material/Create";
import { useHistory } from "react-router-dom";
import QRCodeScanner from "../components/QRCodeScanner";
import DashboardItems from "../components/DashboardItems";
import { useParams } from "react-router-dom";
import CustomItemFieldsDrawer from "../components/CustomItemFieldsDrawer";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';


import {
  Box,
  Paper,
  Container,
  Typography,
  Toolbar,
  Grid,
  Slide,
  Button,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  CardHeader,
  CircularProgress,
  Divider,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import BreadcrumbNav from "../components/BreadcrumbNav";
import { KeyboardReturnRounded, QrCodeScanner } from "@mui/icons-material";
import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";
import EditListModal from "../components/EditListModal";
import ListsByOwner from "../components/ListsByOwner";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PublishItems from "../components/PublishItems";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
/*
const handleSelectLocation = (v) => {
  console.log("location handleSelectLocation v", v);
};
*/
import { fetchLists } from "./../util/functions";
import HowToLists from "../components/HowToLists";
import { list } from "firebase/storage";

function ListPage(props) {
  const history = useHistory();
  const auth = useAuth();
  const userId = auth.user.uid;
  const theme = useTheme();
  const { locationId, itemId } = useParams();
  const [startScan, setStartScan] = useState(false);
  // const aspectRatio = "16:9";
  const [firstLoad, setFirstLoad] = useState(true);
  const handleButtonClick = () => {
    setShowScanner(true);
    setStartScan(true);
  };
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    console.log("startScan value: ", startScan);
  }, [startScan]);

  const handleScannerClose = () => {
    setShowScanner(false);
  };
  /*
  const [itemId, setItemId] = useState();

  useEffect(()=>{
    setItemId(paramItemId);
  }, [paramItemId])
  */
  const [loading, setLoading] = useState(true);
  const [creatingList, setCreatingList] = useState(false);
  const { data: itemData, status: itemStatus } = useItem(itemId);
  const { data: items, status: itemsStatus } = useItemsByOwner(auth.user.uid);
  const [locationPath, setLocationPath] = useState([]);
  const [editingList, setEditingList] = useState(false);
  const [lists, setLists] = useState([]);

  const [selectedListId, setSelectedListId] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [editingLocation, setEditingLocation] = useState(false);
  const { data: allLocations = [], status: allLocationsStatus } =
    useLocationsByOwner(auth.user.uid);

  const [lens, setLens] = useState();
  const [updatingListId, setUpdatingListId] = useState(null);
  const [selectedList, setSelectedList] = useState(null);

  const [refreshData, setRefreshData] = useState(false);

  const [selectedLocationId, setSelectedLocationId] = useState(null);

  console.log("selected list id", selectedListId);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  // Function to open/close the mobile drawer
  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleDoneCreatingList = () => {
    setCreatingList(false);

    fetchLists(userId, itemData, setLoading, setLists);
  };

  const handleDoneEditingList = () => {
    setEditingList(false);
    setSelectedListId(selectedListId);
    fetchLists(userId, itemData, setLoading, setLists);
  };

  console.log("selectedListID afterediting", selectedListId);

  useEffect(() => {
    setLoading(true);
    fetchLists(userId, itemData, setLoading, setLists);
  }, []);

  // const filteredItems = items?.filter((item) => item.listId === selectedListId);
  const filteredItems = items?.filter((item) => item.listId?.includes(selectedListId));


  const handleSelectList = (listId) => {
    console.log("handleSelectList", listId);
    setSelectedListId(listId);
    localStorage.setItem('selectedListId', listId);
  };

  const handleEditListClick = (e, listId) => {
    // e.stopPropagation();
    console.log("handleEditlistClick");
    setEditingList(true);
  };

  function handleSelectItem(l) {
    if (l == null) {
      return;
    }

    console.log("location handleSelectItem", l);
    console.log("location handleSelectItem setSelectedLocation", l);
    setSelectedLocationId(l);
    setLens("item");
  }

  useEffect(() => {
    if (itemData == null) {
      return;
    }

    console.log("location xkcd itemData", itemData);
    if (selectedLocationId == null) {
      console.log("location itemData setSelectedLocation", itemData.locationId);
      setSelectedLocationId(itemData.locationId);
      //setLens('location')
    }
  }, [itemData]);

  useEffect(() => {
    console.log("location all", allLocations);
  }, [allLocations]);

  useEffect(() => {
    if (locationId == null) {
      return;
    }
    console.log("location watch locationId", locationId);
    console.log("location locationId setSelectedLocation", locationId);
    setSelectedLocationId(locationId);
  }, [locationId]);

  const breadcrumbRef = useRef();
  const [breadcrumbVisible, setBreadcrumbVisible] = useState(false);


  useEffect(() => {
    // Get the last selected list ID from local storage
    const lastSelectedListId = localStorage.getItem('selectedListId');
    
    // Check if lists are loaded and if there's a last selected list
    if (lists.length > 0) {
      if (lastSelectedListId) {
        setSelectedListId(lastSelectedListId);
      } else {
        // Default to the first list's ID
        setSelectedListId(lists[0].id);
      }
    }
  }, [lists]); // Dependency array includes 'lists'


  // Effect to monitor online status changes
  useEffect(() => {
    function updateOnlineStatus() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setBreadcrumbVisible(false);
        } else {
          setBreadcrumbVisible(true);
        }
      },
      { threshold: 1.0, rootMargin: "0px 0px 0px 0px" }
    );

    if (breadcrumbRef.current) {
      observer.observe(breadcrumbRef.current);
    }

    return () => {
      if (breadcrumbRef.current) {
        observer.unobserve(breadcrumbRef.current);
      }
    };
  }, []);

  function handleUpdatingItem(itemId) {
    console.log(
      "location handleUpdatingItem itemId locationId",
      itemId,
      locationId
    );

    setLens("item");
  }

  const handleNavigation = (text) => {
    const regex = /localhost:\d+/;
    const match = text.match(regex);

    if (match || text.includes("itemgenie.com")) {
      const url = new URL(text);
      const pathname = url.pathname;
      history.push(pathname);
      return true;
    }
    return false;
  };

  const [scanCode, setScanCode] = useState();
  const handleScanResult = async (decodedText, decodedResult) => {
    //if (decodedText == null) {return;}
    //setScanCode(decodedText);
    console.log(
      "location handleScanResult decodedText decodedResult",
      decodedText,
      decodedResult
    );

    if (decodedText == null) {
      return;
    }

    // Is this one of our sites codes.
    if (handleNavigation(decodedText)) {
      return;
    }

    const items = await getItemsByScanCode(decodedText);
    const locations = await getLocationsByScanCode(decodedText);

    console.log("location handleScanResult items", items);

    const item = getLatestItem(items);
    const location = getLatestLocation(locations);

    console.log("location handleScanResult item", item);
    console.log("location handleScanResult location", location);

    // What now?

    // This will need thought.

    // If there is a location ... use the latest found location.
    // If there is not a location,
    // take user to latest found item.
    if (location !== null) {
      history.push(`/location/${location.id}`);
      return;
    }

    if (item !== null) {
      history.push(`/item/${item.id}`);
    }
  };

  if (!isOnline) {
    return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh" // 100% of the viewport height
      >
        <Card elevation={0}>
          <CardContent>
            <Typography variant="h5">
              You are offline.<br/><br/> Using lists requires an internet connection.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
    );
  }

  const ListsContent = () => {
    return (
      <>
        <Card variant="none" sx={{ background: "#f7f7f7", height:'100%' }}>
          <Toolbar>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              fullWidth
              onClick={() => {
                setCreatingList(true);
              }}
              startIcon={<ListAltIcon />}
            >
              Create List
            </Button>
          </Toolbar>

          {lists.map((list) => (
            <MenuItem
              value={
                <Container sx={{ padding: { xs: "0px" } }}>list.id</Container>
              }
              key={list.id}
              onClick={() => handleSelectList(list.id)}
              selected={selectedListId === list.id}
              style={{
                // minHeight:'0px',
                height: "auto",
                paddingBottom: "0px",
                paddingTop: "0px",
                backgroundColor:
                  selectedListId === list.id ? "#42e3f147" : "transparent",
              }}
            >
              <div
                //  ref={combinedRef}
                style={{
                  display: "flex",
                  alignItems: "center",
                  //  opacity: isDragging ? 0.5 : 1,
                  // height: "25px",
                  paddingRight: "16px",
                  paddingLeft: "16px",

                  width: "100%",
                  // maxWidth: "calc(100% - 100px)"
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    noWrap: true,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    sx={{
                      noWrap: true,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {list.name}
                  </Typography>
                </Box>

                <Tooltip title="Edit List">
                  <IconButton
                    size="small"
                    color="primary"
                    variant="outlined"
                    style={{ marginLeft: "1rem" }}
                    onClick={handleEditListClick}
                    aria-label="edit-list"
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </MenuItem>
            
          ))}

        </Card>
   
      </>
    );
  };

  return (
    <>
      <Meta title="Lists" />




      <Box
        sx={{
          width: "100%",
          // background: "#e8e7e7"
        }}
      >

{!isMobile && (
<Box py={1}></Box>
)}

        <Container maxWidth="lg">
          {isMobile && !mobileDrawerOpen && (
            
            <>
              <Toolbar
                disableGutters
                sx={{
                  alignItems: "center",
                  // paddingRight: "16px",
                  // flexGrow: "1",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ flexGrow: 1 }} py={2}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    aria-label="Open Mobile Drawer"
                    onClick={toggleMobileDrawer}
                    startIcon={<ReadMoreIcon />}
                  >
                    Lists (Add/Create/Edit)
                  </Button>
                </Box>
              </Toolbar>
            </>
          )}
        </Container>
      </Box>

      <Container maxWidth="lg" disableGutters>
        <Grid container>
          <>
            {isMobile ? (
              <Drawer
                anchor="left"
                open={mobileDrawerOpen}
                onClose={toggleMobileDrawer}
              >
                <Box
                  sx={{
                    backgroundColor: "#f7f7f7", // setting the background color to grey
                    width: "300px", // you might want to set a width or it will be minimal width by default
                    height: "100%",
                  }}
                >
     
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      width: "300px",
                      background: "#f7f7f7",
                      paddingTop: "20px",
                    }}
                  >
                    <ListsContent />
                    
                  </Grid>
                </Box>
              </Drawer>
            ) : (
              <>
              <Grid item xs={12} md={4}>
                <ListsContent />
                
              </Grid>

 
    </>
            )}
          </>

          <Grid item xs={12} md={8}>
            <Box>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />{" "}
                </Box>
              ) : selectedListId ? (
                <>
                  <Container maxWidth="lg">
                    <Box>
                      <PublishItems

                        items={filteredItems}
                        selectedListId={selectedListId}
                      />
                    </Box>
                  </Container>

                  {/* <Box py={1}>
                    <Divider />
                  </Box> */}

                  <DashboardItems
                    lists={lists}
                    breadcrumbVisible={breadcrumbVisible}
                    items={items}
                    spice="list"
                    locations={allLocations}
                    locationId={selectedLocationId}
                    inputItem={itemId}
                    onSelect={handleSelectItem}
                    locationPath={locationPath}
                    inputLocation={selectedLocationId}
                    onItemUpdate={handleUpdatingItem}
                    selectedListId={selectedListId}
                  />
                </>
              ) : (
                <Box pt={1} >
                  <Container >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start"
                       // 100% of the viewport height
                    >


              <HowToLists/> 




                    </Box>
                  </Container>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>

      {creatingList && (
        <EditListModal
          action={"create"}
          onDone={handleDoneCreatingList}
          onCancel={() => setCreatingList(false)}
          open={creatingList}
          onClose={() => setCreatingList(false)}
        />
      )}

      {editingList && (
        <>
          <EditListModal
            action={"edit"}
            selectedListId={selectedListId}
            // handleDelete={handleDelete}

            open={editingList}
            onDone={handleDoneEditingList}
            name="Test Name"
            onCancel={() => setEditingList(false)}
            onClose={() => setEditingList(false)}
          />
        </>
      )}
    </>
  );
}

export default requireAuth(ListPage);
