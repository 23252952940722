import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"; // Import the button component
import { useAuth } from "./../util/auth";
import { useRouter, Link } from "./../util/router";
import { redirectToBilling } from "./../util/stripe";
import { Typography } from "@mui/material";

function SettingsBilling(props) {
  const router = useRouter();
  const auth = useAuth();

  // useEffect(() => {
  //   // Check if the user does not have an active plan
  //   if (!auth.user.planIsActive) {
  //     // Redirect them to pricing
  //     router.replace("/pricing");
  //   }
  // }, [auth.user.planIsActive, router]);

  useEffect(() =>{

console.log("SettingsBilling auth.user", auth.user)

  }, [auth.user])


  const handleUpdatePlan = () => {
    // Redirect the user to Stripe billing
    redirectToBilling().catch((error) => {
      props.onStatus({
        type: "error",
        message: error.message,
      });
    });
  };

  return (
    <>
{!auth.user.planIsActive && (
        <>
        <Typography variant="h6">
        You have no active plan
        </Typography>
        <Box py={4}>
    <Button variant="contained" component={Link} to="/pricing" >Purchase a Plan</Button>
    </Box>
    </>
      )}



      {auth.user.stripeSubscriptionId && auth.user.planIsActive && (
        <>
          <div>
            You are subscribed to the <strong>{auth.user.planId} plan</strong>.
          </div>
          <div>
            Your plan status is{" "}
            <strong>{auth.user.stripeSubscriptionStatus}</strong>.
          </div>
          <Box py={4}>
          <Button variant="outlined" onClick={handleUpdatePlan}>Update / Cancel Plan</Button>
          </Box>
        </>
      )} 
    </>
  );
}

export default SettingsBilling;
