import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Typography, CardHeader, Divider, Tooltip } from '@mui/material';
import CustomItemFields from './CustomItemFields';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomItemFieldsDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
    sx={{
      width: {
        xs: '100%', // 100% width on mobile devices
        sm: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450, // 450px width on larger devices
      },
    }}
    role="presentation"
  >
      <CardHeader
      title={<Typography variant="subtitle1">Custom Item Fields</Typography>}
      action={
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon />
        </IconButton>
      }
    />
    <Divider/>
     <CustomItemFields />
   </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
       
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
 
    <Tooltip title="Item Field Settings" >
    <IconButton onClick={toggleDrawer(anchor, true)}><SettingsIcon /></IconButton> 
    </Tooltip>
   
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
                '& .MuiDrawer-paper': {
                  width: {
                    xs: '100%', // 100% width on mobile devices
                    sm: 'auto', // auto width on larger devices
                  },
                },
              }}
          >
            {list(anchor)}
          </Drawer>
     
        </React.Fragment>
    
      ))}
    </div>
  );
}



// <List>
// {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//   <ListItem key={text} disablePadding>
//     <ListItemButton>
//       <ListItemIcon>
//         {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//       </ListItemIcon>
//       <ListItemText primary={text} />
//     </ListItemButton>
//   </ListItem>
// ))}
// </List>
// <Divider />
// <List>
// {['All mail', 'Trash', 'Spam'].map((text, index) => (
  
  
  
//   <ListItem key={text} disablePadding>
//     <ListItemButton>
//       <ListItemIcon>
//         {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//       </ListItemIcon>
//       <ListItemText primary={text} />
//     </ListItemButton>
//   </ListItem>
// ))}
// </List>