import React from "react";
import Container from "@mui/material/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";
import { Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

function ContactSection2(props) {
  return (

      <Container maxWidth="md" style={{paddingTop:'20px'}}>
      <EmailOutlinedIcon style={{ fontSize: '80px'  }}/>
      <div>
    <Typography variant="h4" gutterBottom>Contact</Typography>
    <Typography variant="h5" color="textSecondary">
      The quickest way to get help is to email us at{' '}
      <a style={{color:'inherit'}} href="mailto:hello@itemgenie.com">hello@itemgenie.com</a>{' '}
or drop a question in the <a style={{color:'inherit'}} href="https://discord.gg/CgU3AWFmtb">Discord community</a>.


    </Typography>
  </div>

        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          sx={{ textAlign: "center" }}
        />
        <Contact
          showNameField={props.showNameField}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
        /> */}
      </Container>

  );
}

export default ContactSection2;
