import React, { useState } from "react";
import { Button } from "@mui/material";
import { getOnlineStatus } from "./../util/getOnlineStatus";
import { storage, useFirebaseImage } from "./../util/db";

function PhotoUploader(props) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  
  const imageUrl = useFirebaseImage(props.imagePath);

  return (
    <>
      {imageUrl && (
        <img src={imageUrl} alt="uploaded" style={{ maxWidth: "100%" }} />
      )}

      <input
        accept="image/*"
        style={{ display: "none" }}
        id="upload-photos"
        multiple
        type="file" 
        // onChange={handleChange}
      />
      <label htmlFor="upload-photos">
        <Button
          component="span"
          variant="contained"
          size="medium"
          color="primary"
        >
          Upload Photos
        </Button>
      </label>
    </>
  );
}

export default PhotoUploader;

