import React from 'react';
import {
  useItemsByOwner,
} from "../util/db";
import { calculateTotalPrice } from '../util/functions';
import { useAuth } from '../util/auth';
import { requireAuth } from "../util/auth";



function TotalPriceItems({ props }) {
  const auth = useAuth();

  // const { data: items, status: itemsStatus } = useItemsByOwner(auth.user.uid);
  // const { data, isLoading, isError } = useItemsByOwner(auth.user.uid);
  const { data: items, status: itemsStatus } = useItemsByOwner(auth.user.uid);


  console.log("auth uid", auth.user.id)
 
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (isError) {
  //   return <div>Error fetching items</div>;
  // }

  const totalPrice = calculateTotalPrice(items);

  return (
    <div>
      {/* <h2>Total Value of Items</h2> */}
      ${totalPrice.toFixed(2)}
    </div>
  );
}

export default requireAuth(TotalPriceItems);
