import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import HeroSection from "./../components/HeroSection";
import HeroSection3 from "./../components/HeroSection3";
import HeroSection4 from "./../components/HeroSection4";
import hero from "./../images/hero.png";
import mobile from "./../images/mobile.png";
import discordbg from "./../images/discord-bg.png";
import ClientsSection from "./../components/ClientsSection"
import FeaturesSection3 from "../components/FeaturesSection3";
import HeroSection5 from "./../components/HeroSection5";
import Meta from "../components/Meta";


function IndexPage(props) {
 
  return (
    <> <Meta />

    <div
      style={{
        // padding: "50px",
        width: "100%",
        textAlign: "center",
      }}
    >



      <HeroSection2
      title="Cataloging, Finding, Listing... Things Made Easy."
      subtitle="The supereasy way do anything with your things."
      buttonText="Get Started"
      size='medium'
      buttonColor="secondary"
      buttonPath="/auth/signup"
      />
    

<HeroSection
  title="Organize It Your Way."
  subtitle="Organize your items by place, container, box, or any way that works for you."
  // buttonText="Get Started"
  size='medium'
  buttonColor="secondary"
  bgColor="lightgreen"
  image={hero}

  />


       

    

      <FeaturesSection3 bgColor="light" />
      {/* <ClientsSection title="Trusted by 1000s of Items" subtitle="...oh, and a lot of homes and businesses"/> */}



      <HeroSection5
      title="A Simple Price Promise"
      subtitle="The first 100 items are free. The only things that we will charge money for are the things that cost us money, like large images, or lots of items, which all mean higher server costs for us. Everything else that is just our development time will be free, like custom fields, multiple users (coming soon), barcode and qrcode scanning, to name but a few."
      buttonText="Get Started"
      size='medium'
      // bgImage={discordbg}
      // bgColor="primary"
      // bgImageOpacity= "0.2"
      // buttonColor="secondary"
      // buttonPath="/auth/signup"
      />

      <HeroSection3
  title="Inventorize from Anywhere."
  subtitle="Stuck in a basement with no signal? No problem. When offline, locations and items will automatically be saved to your phone and uploaded when you're back online. All this without the need to download any extra app."
  buttonText="Get Started"
  size='medium'
  buttonColor="secondary"
  bgColor="lightpink"
  image={mobile}
  buttonPath="/auth/signup"
  />


<HeroSection4
      title="A friendly community  👩🏽‍💻 👨‍💻💬 🍻"
      subtitle="We invite everyone to join us on discord, where you ask questions, request new features, report bugs, or just say hi to us."
      buttonText="Go to ItemGenie's Discord"
      size='medium'
      image={discordbg}
      bgColor="discord"
      bgImageOpacity= "0.2"
      buttonColor="primary"
     
      />

<br/>
    </div>
    </>
  );
}

export default IndexPage;
