import {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Html5Qrcode } from "html5-qrcode";
import Button from "@mui/material/Button";
import { Box, CircularProgress } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const qrcodeRegionId = "html5qr-code-full-region";


// const saveCameraId = (cameraId) => {
//   localStorage.setItem('savedCameraId', cameraId);
//   console.log("saveCameraId - Camera ID saved:", cameraId);
// };

// const getSavedCameraId = () => {
//   const cameraId = localStorage.getItem('savedCameraId');
//   console.log("getSavedCameraId - Retrieved Camera ID:", cameraId);
//   return cameraId;
// };

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (cameraId) => {
  const config = {
    fps: 100,
    qrbox: 250,
    aspectRatio: 1,
    disableFlip: false,
    // cameraId: cameraId || { facingMode: "environment" },
    // Uncomment the following if you need specific formats
    // formatsToSupport: [Html5QrcodeSupportedFormats.EAN_13],
  };

  return config;
};

const QRCodeScanner = forwardRef((props, ref) => {
  const scannerRef = useRef(null);
  const html5Qrcode = useRef(null);
  const [scanSuccessful, setScanSuccessful] = useState(false);
  const [isScannerRunning, setIsScannerRunning] = useState(false);
  const [isCameraFeedReady, setIsCameraFeedReady] = useState(false);
  const aspectRatioValue = props.aspectRatio
    ? props.aspectRatio.replace(":", " / ")
    : undefined;

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = async () => {
    if (html5Qrcode.current) {
      await handleStopScan();
      html5Qrcode.current = null;
    }
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (openDrawer) {
      setTimeout(() => {
        html5Qrcode.current = new Html5Qrcode(qrcodeRegionId);
        handleStartScan();
      }, 500); // wait for 500ms
    } else if (html5Qrcode.current) {
      handleStopScan();
    }
  }, [openDrawer]);

  useEffect(() => {
    return () => {
      // Cleanup on component unmount
      if (html5Qrcode.current) {
        handleStopScan();
        html5Qrcode.current = null;
      }
    };
  }, []);



  const handleStartScan = async (event) => {
    if (event) {
      event.preventDefault();
    }
  
    try {
    
      const config = createConfig(props);
      const verbose = props.verbose === true;
  
      await html5Qrcode.current.start(
        { facingMode: "environment" },
        config,
        async (message) => {
          props.qrCodeSuccessCallback(message);
          setScanSuccessful(true);
          await handleStopScan();
          handleDrawerClose();
        },
        props.qrCodeErrorCallback,
        verbose
      );
  
      setIsScannerRunning(true);
      setIsCameraFeedReady(true);
    } catch (error) {
      console.error("Error fetching cameras or starting scanner:", error);
      handleStopScan();
      if (error.name === "NotAllowedError") {
        alert("Please allow camera access to scan QR code.");
      } else if (error.name === "NotFoundError") {
        alert("No camera found on this device.");
      } else {
        alert("Error starting scanner: " + error.message);
      }
    }
  };

  useEffect(() => {
    if (scanSuccessful) {
      handleStopScan();
    }
  }, [scanSuccessful]);

  const handleStopScan = async (event) => {
    // If an event is passed (like from a button click), prevent the default action
    if (event) {
      event.preventDefault();
    }

    //if (html5Qrcode.current && isScannerRunning) {
    if (html5Qrcode.current) {
      try {
        //await html5Qrcode.current.stop();
        //setIsScannerRunning(false);

        try {
          await html5Qrcode.current.stop();
        } catch (error) {
          console.log("Scanner may not have been running:", error);
        } finally {
          setIsScannerRunning(false);
        }

        // Only stop video tracks if scannerRef.current is not null
        if (scannerRef.current) {
          // Get any video tracks from the scanner's video element and stop them
          const videoElem = scannerRef.current.querySelector("video");
          if (videoElem && videoElem.srcObject) {
            const stream = videoElem.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach((track) => {
              if (track.kind === "video") {
                track.stop();
              }
            });

            videoElem.srcObject = null;
          }
        }
        setIsScannerRunning(false);
        setIsCameraFeedReady(false); // Reset state here
      } catch (error) {
        console.error("Failed to stop the scanner.", error);
      }
    }
  };

  const [scanState, setScanState] = useState("start");



  useImperativeHandle(ref, () => ({
    startScan: handleStartScan,
  }));

 

  return (
    <div>
   

      <Button size="large" variant="outlined" color="secondary" onClick={handleDrawerOpen}>
     <QrCodeScannerIcon />
    </Button>

      <Drawer anchor="top" open={openDrawer} onClose={handleDrawerClose} PaperProps={{ 
        sx: { 
            borderRadius: '0 0 20px 20px' 
        } 
    }}  >
        {!isCameraFeedReady && (
          <Box sx={{ textAlign: "center" }} p={4}>
            <CircularProgress color="secondary" />{" "}
          </Box>
        )}

        <div className="scanner-container">
          <Box sx={{ display: "flex", flexDirection: "column", overflow:'hidden' }}>
            <div id={qrcodeRegionId} ref={scannerRef} className="scanner"></div>
            <Box sx={{ textAlign: "center" }} py={1}>
              {/* {!scanSuccessful && ( */}
                <Button onClick={handleDrawerClose} color="primary">
                  Cancel
                </Button>
              {/* )} */}
            </Box>
          </Box>
        </div>
      </Drawer>
    </div>
  );
});

export default QRCodeScanner;
