import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <header>
        <h1>Privacy Policy</h1>
        <p><i>Last Updated: September 27, 2023</i></p>
    </header>
    <section>
        <h2>1. Introduction</h2>
        <p>ItemGenie values the privacy of its users. This Privacy Policy outlines how we collect, use, and protect your information when you use our website. By using our services, you agree to the collection, use, and disclosure of your information in accordance with this Policy.</p>
        
        <h2>2. Information We Collect</h2>
        <h3>2.1 Personal Information</h3>
        <p>We may collect personal information such as your name, email address, and contact number, mainly for account creation and user identification.</p>
        
        <h3>2.2 Location and Item Information</h3>
        <p>When you use ItemGenie, we collect information about the items you upload and their location. This information is essential for the core functionality of our services.</p>
        
        <h3>2.3 Usage and Log Information</h3>
        <p>We collect information related to how you use our services, including access times, pages viewed, IP address, and browser information.</p>
        
        <h2>3. How We Use Your Information</h2>
        <h3>3.1 Providing Services</h3>
        <p>We use your information to operate, maintain, and provide you with features and functionality of the website.</p>
        
        <h3>3.2 Communications</h3>
        <p>We use your information to communicate with you about our services, promotions, and updates.</p>
        
        <h3>3.3 Improving Our Services</h3>
        <p>We analyze the information to improve the functionality and user-friendliness of our services.</p>
        
        <h2>4. How We Share Your Information</h2>
        <h3>4.1 Service Providers</h3>
        <p>We may share your information with third-party service providers that perform services on our behalf.</p>
        
        <h3>4.2 Legal Obligations</h3>
        <p>We may disclose your information if required by law or in response to legal processes.</p>
        
        <h3>4.3 Business Transfers</h3>
        <p>Your information may be transferred in connection with a merger, acquisition, or sale of assets.</p>
        
        <h2>5. Your Choices and Controls</h2>
        <p>You can review, modify, or delete your personal information by logging into your ItemGenie account. You can also opt-out of receiving promotional communications from us.</p>
        
        <h2>6. Security</h2>
        <p>We implement reasonable security measures to protect your information. However, no method of transmission or storage is completely secure, and we cannot guarantee absolute security.</p>
        
        <h2>7. Changes to this Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Policy on our website.</p>
        
        <h2>8. Contact Us</h2>
        <p>For any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:support@itemgenie.com">support@itemgenie.com</a>.</p>
    </section>
    </LongContent>
  );
}

export default LegalPrivacy;
