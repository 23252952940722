import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <header>
        <h1>Terms and Conditions</h1>
        <p><i>Last Updated: September 27, 2023</i></p>
    </header>
    <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using ItemGenie’s website, you agree to abide by these Terms and Conditions. If you do not agree with these Terms, please do not use our services.</p>
        
        <h2>2. Registration</h2>
        <p>Users must register to access and use our services. You agree to provide accurate, current, and complete information during the registration process.</p>
        
        <h2>3. User Conduct</h2>
        <p>You agree not to use ItemGenie for any illegal or unauthorized purpose. You are responsible for all content and information you upload to our website. In addition to the general obligations under this section, you specifically agree not to post, upload, store, or share any content that:
<br/><br/>
Is illegal, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful, or offensive on racial, sexual, religious, or other grounds.
Contains explicit or graphic descriptions or accounts of sexual acts, including but not limited to pornography, child pornography, or other content deemed inappropriate.
Promotes or facilitates illegal activities, including but not limited to the distribution of illegal substances or the promotion of illegal acts.
Violates the intellectual property rights of any party, or otherwise infringes upon the rights of any third party.
<br/><br/>ItemGenie reserves the right to remove any content that violates these terms and may, in its sole discretion, terminate your account if you are found to be repeatedly posting such prohibited content.</p>
        
        <h2>4. Intellectual Property</h2>
        <p>All content and services provided by ItemGenie are the property of ItemGenie and are protected by copyright, trademark, and other intellectual property laws.</p>
        
        <h2>5. Termination</h2>
        <p>ItemGenie reserves the right to terminate your access to our services if you violate these Terms and Conditions.</p>
        
        <h2>6. Limitation of Liability</h2>
        <p>ItemGenie will not be liable for any indirect, incidental, or consequential damages arising out of or in connection with our services or these Terms and Conditions. Furthermore, ItemGenie is not responsible for any data loss or data breaches that may occur.</p>
        
        <h2>7. Changes to Terms and Conditions</h2>
        <p>We reserve the right to modify or replace these Terms and Conditions at any time. It is your responsibility to regularly check for any changes.</p>
        
        <h2>8. Governing Law</h2>
        <p>These Terms and Conditions are governed by the laws of the jurisdiction in which ItemGenie operates.</p>
        
        <h2>9. Contact Information</h2>
        <p>For any queries or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:support@itemgenie.com">support@itemgenie.com</a>.</p>
        <h2>10. Fair Use Policy</h2>
<p>ItemGenie offers unlimited locations and custom fields to provide flexibility and cater to the diverse needs of our users. While we are committed to maintaining this approach, we ask all users to exercise responsibility and fairness while utilizing our services.</p>
<p>Our fair use policy is designed to prevent fraudulent or abusive behavior that might degrade the performance of our system for other users. We kindly ask you not to manipulate our services or use them excessively in a way that is inconsistent with normal usage patterns.</p>
<p>If we detect any user violating our fair use policy, we reserve the right to review, limit, or suspend that user's account. We will make efforts to inform the user of any such action and provide reasons for the same. Our primary goal is to ensure a balanced and effective service for all users.</p>
<p>If you are uncertain about whether your usage might violate our fair use policy, or if you anticipate a significant increase in your usage pattern due to specific reasons, please reach out to us at <a href="mailto:support@itemgenie.com">support@itemgenie.com</a>. We are always here to help and guide our community.</p>

    </section>
    </LongContent>
  );
}

export default LegalTerms;
