import React, { useState, useRef, useEffect } from 'react';
import QRCode from 'qrcode.react';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { Container, Grid, Typography, Box, Divider, Toolbar, Button } from '@mui/material';
import ReactToPrint from 'react-to-print';

const labelTypes = [
  { 
    id: 'default', 
    name: 'Standard Grid', 
    width: 2, 
    height: 2, 
    columns: 3, 
    rows: 5,
    css: `
      /* CSS for Standard Grid */
      .label-default {
        /* Define your CSS here */
      }
    `
  },
  { 
    id: '5160', 
    name: 'Avery 5160 - Address Labels', 
    width: 2.625, 
    height: 1, 
    columns: 3, 
    rows: 10,
    css: `
      /* CSS for Avery 5160 - Address Labels */
      .label-5160 {
        width: 2.625in;
        height: 1in;
        padding: 0.1in;
        margin-right: -1px;
        margin-bottom: -1px;
        box-sizing: border-box;
      }
    `
  },
  { 
    id: '8160', 
    name: 'Avery 8160 - Address Labels', 
    width: 2.625, 
    height: 1, 
    columns: 3, 
    rows: 10,
    css: `
      /* CSS for Avery 8160 - Address Labels */
      .label-8160 {
        width: 2.625in;
        height: 1in;
        padding: 0.1in;
        margin-right: -1px;
        margin-bottom: -1px;
        box-sizing: border-box;
      }
    `
  },
];

const options = Array.from({ length: 100 }, (_, i) => ({ value: i + 1, label: String(i + 1) }));

const QRCodeGrid = (props) => {
  const [count, setCount] = useState(1);
  const [sameQR, setSameQR] = useState(false);
  const [qrValue, setQrValue] = useState(uuidv4());
  const [labelType, setLabelType] = useState(labelTypes[0]);
  const componentRef = useRef();

  useEffect(() => {
    if (sameQR) {
      setQrValue(uuidv4()); // Regenerate QR value when sameQR toggles to true
    }
  }, [sameQR]);

  // const qrCodes = Array.from({ length: count }, (_, i) => (
  //   <div key={i}  className={`label-${labelType.id}`} style={{
  //     // width: `${labelType.width}in`, 
  //     // height: `${labelType.height}in`, 
  //     float: 'left', 
  //     padding: '0.1in'
  //   }}>
  //     <QRCode value={sameQR ? qrValue : uuidv4()} size={Math.min(labelType.width, labelType.height) * 96 - 20} />
  //   </div>
  // ));

  const qrCodes = Array.from({ length: count }, (_, i) => (
    <div key={i} className={`label-${labelType.id}`} style={{ float: 'left', padding: '0.1in' }}>
      <style>{labelType.css}</style> {/* Apply CSS dynamically */}
      <QRCode value={sameQR ? qrValue : uuidv4()} size={Math.min(labelType.width, labelType.height) * 96 - 20} />
    </div>
  ));


  const handleLabelTypeChange = (selectedOption) => {
    setLabelType(selectedOption.value);
  };

  const handleChange = (selectedOption) => {
    setCount(selectedOption.value);
  };

  const handleCheckboxChange = (e) => {
    setSameQR(e.target.checked);
  };

  return (
    <div>
      <Container>
        <Box py={2}>
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1" fontWeight="600">{props.title}</Typography>
              <Typography variant="body1">{props.subtitle}</Typography>
            </Box>
          </Toolbar>
        </Box>

        <Box py={1}><Divider /></Box>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom><b>Choose the number of QR codes to generate:</b></Typography>
            <Select
            defaultValue={options[0]} onChange={handleChange} options={options} />
            </Grid>
            <Grid item xs={12}>
              <label>
                <input type="checkbox" checked={sameQR} onChange={handleCheckboxChange} style={{ height: '20px', width: '20px', verticalAlign: 'sub' }} />
                Use same QR code (often used when multiples of the same item exist)
              </label>
              <Box pt={2}>
                <Typography variant="h6">Select Label Type:</Typography>
                <Select defaultValue={labelTypes[0]} onChange={handleLabelTypeChange} options={labelTypes.map(lt => ({ value: lt, label: lt.name }))} />
              </Box>

              <Box textAlign="right" py={2}>
            <ReactToPrint copyStyles={true} trigger={() => (<Button color="secondary" variant="contained">PRINT QR CODES</Button>)} content={() => componentRef.current} />
            </Box>

            </Grid>

          
            <Box pt={2}>

         
              <div style={{ display: 'flex', flexWrap: 'wrap' }} ref={componentRef}>
                {qrCodes}
              </div>
            </Box>
          </Grid>

        </Container>
      </div>
    );
  };
  
  export default QRCodeGrid;
  