import React from "react";
import { createRoot } from "react-dom/client";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import './index.css';

// Use createRoot instead of ReactDOM.render
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// 

// serviceWorker.unregister();

serviceWorker.register();
