import React, { useEffect, useContext, useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { TreeItem } from "@mui/lab";
import { Button, Tooltip, Typography, Snackbar } from "@mui/material";
import { SelectedLocationContext } from "./LocationTreeView";
import EditLocationModal from "./EditLocationModal";
import {
  updateLocation,
  useDeleteLocationWithChildren,
  updateUser,
  useFetchUser,
} from "../util/db";
import { useAuth } from "../util/auth";
import SimpleDialogDemo from "./SimpleDialog";
import Debug from "./Debug";
import DeleteConfirmation from "./DeleteConfirmation";
import CreateIcon from "@mui/icons-material/Create";
import { IconButton, Box } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import QRCode from "qrcode.react";
import QrCodePrint from "./QrCodePrint";
import ReactDOMServer from "react-dom/server";
// import { ServerStyleSheet } from "styled-components";
import ReactToPrint from "react-to-print";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomContent from "./CustomContent";
import Alert from "@mui/material/Alert";


const LocationTreeItem = ({
  location,
  onMove,
  onSelect,
  children,
  showAll,
  ...props
}) => {
  const selectedLocationId = useContext(SelectedLocationContext);
  const [updatingLocationId, setUpdatingLocationId] = useState(null);
  const [creatingLocation, setCreatingLocation] = useState(false);
  const [editingLocation, setEditingLocation] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("info");
  // Define the `onDone` function
  const [formAlert, setFormAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [rememberChoice, setRememberChoice] = useState(false);
  const baseURL = "https://wheresmything.com/location/";
  const locationURL = baseURL + selectedLocationId;
  const deleteLocation = useDeleteLocationWithChildren();
  const qrCodeRef = useRef();
  const handleDoneCreatingLocation = () => {
    console.log("handleDoneCreatingLocation called");
    setCreatingLocation(false);
    // Do something else if needed
  };

  const handleDoneEditingLocation = () => {
    console.log("handleDoneEditingLocation called");
    setEditingLocation(false);
    // Do something else if needed
  };

  const auth = useAuth();
  const { user, loading } = useFetchUser(auth.user.uid);
  // const shouldShowWarning = auth.user.rememberDeleteWarningChoice !== true;

  const handleClick = (e) => {
    e.stopPropagation();
    setCreatingLocation(true);
  };

  useEffect(() => {
    if (!loading && auth.user.id) {
      console.log(
        "remember choice deleteconfirmation",
        rememberChoice,
        auth.user.uid
      );
      setRememberChoice(auth.user.rememberDeleteWarningChoice || false);
    }
  }, [auth.user.id, loading]);

  useEffect(() => {
    console.log("remember Choice changed:", rememberChoice, auth.user.uid);
    updateUser(auth.user.uid, { rememberDeleteWarningChoice: rememberChoice });
  }, [rememberChoice, auth.user.uid]);

  const handleDelete = () => {
    if (location?.defaultLocation) {
      setFormAlert({
        open: true,
        type: "error",
        message: "Deleting the default location is not allowed.",
      });
      return;
    }
    if (auth.user.rememberDeleteWarningChoice) {
      handleConfirmedDelete();
    } else {
      setDeleteWarningOpen(true);
    }
  };
  const [draggingOverLocationId, setDraggingOverLocationId] = useState(null);

  const handleSnackbarMessage = (data) => {
    console.log("handleSnackbarMessage called with data:", data);
    setSnackbarMessage(data.message);
    setSnackbarType(data.type || "info");
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  
    setSnackbarOpen(false);
  };


  const handleQrCodeClick = () => {
    if (qrCodeRef.current) {
      const reactToPrintContent = qrCodeRef.current;
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print QR Code</title></head><body>"
      );
      printWindow.document.write(reactToPrintContent.outerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
      };
    }
  };

  const handleConfirmedDelete = async () => {
    console.log("Deleting location with ID:", location.id);
    const locationId = location.id;
    const ownerId = auth.user.uid;

    setIsDeleting(true);
    try {
      await deleteLocation(locationId, ownerId);
      // Show success message or perform other actions after successful deletion
      // onLocationDeleted(); // call this function if provided, after successful deletion
    } catch (error) {
      // Handle error
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteWarning = async () => {
    setDeleteWarningOpen(true);
  };

  function handleDeleteLocationClick() {
    console.log("LocationTreeItem handleDeleteLocationClick");
    setDeleteWarningOpen(true);
  }

  const handleEditLocationClick = (e) => {
    e.stopPropagation();

    setEditingLocation(true);
  };

  const [{ isDragging }, drag] = useDrag(() => {
    console.log("LocationTreeItem Drag");

    
    return {
      type: "location",
      item: location,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    };
  });

  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;
    

  const TreeItemContent = () => (
    <div
      ref={combinedRef}
      style={{
        display: "flex",
        alignItems: "center",
        opacity: isDragging ? 0.5 : 1,
        minHeight: "48px",
        paddingRight: "16px",
        // maxWidth: "calc(100% - 100px)"
      }}
    >
      <Box
        display="flex"
        sx={{
          noWrap: true,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: isTouchDevice || location.id === "all" ? "none" : "block", cursor: "move" }}>
          <DragIndicatorIcon />
        </Box>

        <Typography
          sx={{
            noWrap: true,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
           
          }}
        >
          {location.name}
        </Typography>
      </Box>

      <Debug>{"(" + location.id.slice(0, 4) + ")"}</Debug>

{location.id !== "all" && (
<>
      {location.id === selectedLocationId && (
        <>
          <Tooltip title="Edit Location">
            <IconButton
              size="small"
              color="primary"
              variant="outlined"
              style={{ marginLeft: "1rem" }}
              onClick={handleEditLocationClick}
              aria-label="edit-location"
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Add Location">
            <IconButton
              size="small"
              color="primary"
              variant="outlined"
              style={{ marginLeft: "5px" }}
              onClick={handleClick}
              aria-label="add-location"
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>

          {/* <QrCodePrint locationURL={locationURL} /> */}
        </>
      )}
      </>
      )}
    </div>
  );

  const [{ isOver }, drop] = useDrop(() => {
    console.log("LocationTreeItem Drop");

    return {
      accept: "location",
      drop: (item) => {
        console.log("LocationTreeItem item location", item, location);
        return onMove(item.id, location.id);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    };
  });

  const combinedRef = (node) => {
    if (location.id !== "all") {
      drag(node);
      drop(node);
    }
   
  };

  const childrenRef = (node) => {
    drop(node);
  };

  function handleSelectLocation(l) {
    console.log("LocationTreeItem handleSelectLocation l", l);
    //  props.onSelect(l)
  }

  useEffect(() => {
    console.log("LocationTreeItem isDragging", isDragging);
  }, [isDragging]);

  // console.log("LocationTreeItem selectedLocationId", selectedLocationId);
  // console.log("LocationTreeItem location.id", location);

  const CustomContentWrapper = (props) => {
    return <CustomContent {...props} location={location} />;
  };

  return (
    <>
   
      <TreeItem
        // ContentComponent={CustomContentWrapper}
        // {...props}
        nodeId={location.id}
        location={location}
        label={<TreeItemContent />}
        onFocusCapture={(e) => e.stopPropagation()}
        onClick={() => onSelect(location.id)}
        sx={{ 
          backgroundColor: isOver ? "rgba(211, 211, 211, 0.2)" : "transparent",
         
        }}
      >
        {children}
      </TreeItem>

      {creatingLocation && (
        
        <EditLocationModal
        onSnackbarMessage={handleSnackbarMessage}
          action={"create"}
          selectedLocationId={selectedLocationId}
          onSave={(updatedLocation) => {
            updateLocation(auth.user.uid, updatedLocation);
            setCreatingLocation(false);
          }}
          onDone={handleDoneCreatingLocation}
          onCancel={() => setCreatingLocation(false)}
          open={creatingLocation}
          onClose={() => setCreatingLocation(false)}
        />
      )}

      {editingLocation && (
        <>
          <EditLocationModal
            onSnackbarMessage={handleSnackbarMessage}
            action={"edit"}
            selectedLocationId={selectedLocationId}
            handleDelete={handleDelete}
            id={selectedLocationId}
            open={editingLocation}
            onDone={handleDoneEditingLocation}
            name="Test Name"
            onSave={(updatedLocation) => {
              updateLocation(updatingLocationId, updatedLocation);
              setEditingLocation(false);
            }}
            onCancel={() => setEditingLocation(false)}
            onClose={() => setEditingLocation(false)}
          />
        </>
      )}

      <DeleteConfirmation
        location={location}
        deleteWarningOpen={deleteWarningOpen}
        setDeleteWarningOpen={setDeleteWarningOpen}
        rememberChoice={rememberChoice}
        handleConfirmedDelete={handleConfirmedDelete}
        setRememberChoice={setRememberChoice}
      />
      <Snackbar
        open={formAlert.open}
        autoHideDuration={6000}
        onClose={() => setFormAlert({ ...formAlert, open: false })}
      >
        <Alert
          onClose={() => setFormAlert({ ...formAlert, open: false })}
          value={formAlert.type}
          sx={{ width: "100%" }}
        >
          {formAlert.message}
        </Alert>
      </Snackbar>

      <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          // message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={snackbarType}>{snackbarMessage}</Alert>
        </Snackbar>
      
    </>
  );
};

export default LocationTreeItem;
