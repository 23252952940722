import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";

function HeroSection(props) {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isXSSize, setIsXSSize] = useState(window.innerWidth <= 600); // Assuming 600px is the breakpoint for xs

  useEffect(() => {

    const handleResize = () => {
      setIsXSSize(window.innerWidth <= 600);
    };

    const handleScroll = () => {
      if (isXSSize) {
        setScrollPosition(window.scrollY);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isXSSize]);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
   
        <Grid container={true} alignItems="center" spacing={6}>
          {/* <Grid container={true} item={true} direction="column" xs={12} md={4}>
            <Box sx={{ textAlign: { xs: "left", md: "left" } }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
                subtitleSize={6}
              />
        
            </Box>
          </Grid> */}
          <Grid item={true} xs={12} md={true} sx={{overflowX: 'hidden',}}>
          <Box
          component="img"
          src={props.image}
          alt="illustration"
          sx={{
            margin: "0 auto",
            display: "block",
            height: "auto",
            width: isXSSize ? "200%" : "100%", // making it wider than screen width only for xs
            transform: isXSSize ? `translateX(-${scrollPosition}px)` : 'none', // moving image left as user scrolls down only for xs
            transition: 'transform 0.2s ease-out', // for smoother effect
            
          }}
        />
      </Grid>
        </Grid>
  
    </Section>
  );
}

export default HeroSection;
