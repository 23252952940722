import React from "react";
import Meta from "../components/Meta";
import TeamBiosSection2 from "../components/TeamBiosSection2";
import TeamBiosSection3 from "../components/TeamBiosSection3";
import TeamBiosSection4 from "../components/TeamBiosSection4";
import TeamBiosSection from "../components/TeamBiosSection";
import QRCodeGrid from "../components/QRCodeGrid";




function QRPage(props) {
  const useSameQRCode = '"Use Same QR Code"'
  return (
    <>
      <Meta title="QR Code Generator" />

 <QRCodeGrid
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title="Print QR Codes"
        // subtitle={`Generate and print distinct QR codes for each of your locations and items. After printing, cut them out, and tape to items or locations, and then proceed to scan them into your items. These QR codes can serve dual functions, being applicable to locations like the top shelf, as well as items such as a black kettle. If you have multiples of the same item, the same QR code can be utilized. This can be done by simply selecting the ${useSameQRCode} option.`}
        subtitle={<>Create distinct QR codes for each location and item, print and attach them accordingly. These QR codes are versatile and usable for various locations like a top shelf or items such as a black kettle. For identical items, reuse the same QR code by selecting the {useSameQRCode} option. Alternatively, you can <a href="https://www.amazon.com/ToteScan-Pre-Printed-Stick-Labels-Count/dp/B07LF3XPLJ/ref=sr_1_5?crid=2NXBWIWMQXC2C&keywords=qr+codes&qid=1689245400&sprefix=qr+codes%2Caps%2C95&sr=8-5" target="_blank" rel="noreferrer">purchase QR codes</a> and adhere them to your items or locations, which will function identically.   IMPORTANT NOTE: When printing Avery labels, adjust margins in to your print settings to your printers custom margin settings. 
        </>}
     />

    </>
  );
}

export default QRPage;
