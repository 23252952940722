import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" description="100 Items for free"  />

      <PricingSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title="Choose a plan that fits your unique needs."
        subtitle=""
      />
    </>
  );
}

export default PricingPage;
