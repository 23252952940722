import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function HeroSection3(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid item={true} xs={12} md={true}>
            <figure>
              <Box
                component="img"
                src={props.image}
                alt="illustration"
                sx={{
                  margin: "0 auto",
                  maxWidth: "270px",
                  display: "block",
                  height: "auto",
                  width: "100%",
                }}
              />
            </figure>
          </Grid>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box
              sx={{
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
              />
                   <Button
    component={Link}
    to={props.buttonPath}
    variant="contained"
    size="large"
    color={props.buttonColor}
>
    <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6">
            {props.buttonText} 
        </Typography>
        &nbsp; <ArrowForwardIcon />
    </Box>
</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection3;
