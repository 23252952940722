import { useState, useEffect, useRef } from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import LocationTreeView from "../components/LocationTreeView";
import {
  useItem,
  useLocationsByOwner,
  updateLocation,
  useItemsByOwner,
  getItemsByScanCode,
  getLocationsByScanCode,
  useLocation,
} from "../util/db";
import {
  getLatestItem,
  getLatestLocation,
  findLocationPath,
} from "../util/functions";
import EditLocationModal from "../components/EditLocationModal";
import { isNestedChild } from "../util/functions";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import { useAuth } from "./../util/auth";
import { createLocation } from "../util/db";
import { useHistory } from "react-router-dom";
import QRCodeScanner from "../components/QRCodeScanner";
import DashboardItems from "../components/DashboardItems";
import { useParams } from "react-router-dom";
import CustomItemFieldsDrawer from "../components/CustomItemFieldsDrawer";
import {
  Box,
  Container,
  Typography,
  Toolbar,
  Grid,
  Slide,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
  useMediaQuery,
  Drawer,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import BreadcrumbNav from "../components/BreadcrumbNav";
import { fetchLists } from "./../util/functions";
import {
  CatchingPokemonSharp,
  KeyboardReturnRounded,
  QrCodeScanner,
} from "@mui/icons-material";
import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { useTour } from "../util/functions";



function LocationPage(props) {
  const history = useHistory();
  const auth = useAuth();

  const theme = useTheme();
  const { locationId, itemId } = useParams();

  const [startScan, setStartScan] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const handleButtonClick = () => {
    setShowScanner(true);
    setStartScan(true);
  };
  const [lists, setLists] = useState([]);
  useEffect(() => {
    console.log("startScan value: ", startScan);
  }, [startScan]);

  const [open, setOpen] = useState(false);

  // Function to open the Snackbar

  const openSnackbar = () => {
    setOpen(true);
  };
  // Function to close the Snackbar
  const closeSnackbar = (event, reason) => {
    setDecodedText(null);
    if (reason === "clickaway") {
      // setOpen(false);
    }

    setOpen(false);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  console.log("mobile drawer open", mobileDrawerOpen)

  const handleScannerClose = () => {
    setShowScanner(false);
  };

  const [decodedText, setDecodedText] = useState(null);

  const [creatingLocation, setCreatingLocation] = useState(false);
  const { data: itemData, status: itemStatus } = useItem(itemId);
  const {
    data: items,
    isLoading: isLoadingItems,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth.user.uid);
  const {
    data: locations,
    isLoading: isLoadingLocations,
    error: locationsError,
  } = useLocationsByOwner(auth.user.uid);
  const [locationPath, setLocationPath] = useState([]);
  const userId = auth.user.uid;

  const [showScanner, setShowScanner] = useState(false);

  const [loading, setLoading] = useState(true);

  const { data: allLocations = [], status: allLocationsStatus } =
    useLocationsByOwner(auth.user.uid);

  const [lens, setLens] = useState();

  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const handleDoneCreatingLocation = () => {
    setCreatingLocation(false);
    // Do something else if needed
  };

  const [locationName, setLocationName] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(false);
  // const [itemId, setItemId] = useState(null);

  useEffect(() => {
    if (itemId) {
      console.log("it is an item", itemId);
      setSelectedLocationId(null);
    } else if (locationId) {
      console.log("it is a location", locationId);
      // history.push(`/location/${locationId}`);
    }
  }, [itemId, locationId]);

  function handleSelectLocation(l, source = null) {
    if (l == null) {
      return;
    }
    setLens("location");
    console.log("Setting location from source:", source);
    console.log("Before location setSelectedLocation", l);
    setSelectedLocationId(l);
    console.log("After location setSelectedLocation", l);
    console.log("Location l itemId", l, itemId);

    // Use the value directly instead of relying on the state
    if ("location" === "location") {
      console.log("location abcxyz", l);
      history.push(`/location/${l}`);
    }
  }

  console.log("selectedlocationid", selectedLocationId);

  const selectedLocationData = useLocation(selectedLocationId);
  let selectedLocationName =
    selectedLocationData?.data?.name || "Please select a location";

  if (locationId === "all") {
    selectedLocationName = "All locations";
  }




  useEffect(() => {
    // Call the fetchLists function with the required parameters
    fetchLists(userId, itemData, setLoading, setLists);
  }, []);

  // useEffect(() => {
  //   if (locations == null) {
  //     return;
  //   }
  //   console.log("default location local state", defaultLocation);

  //   // Check there is at least one location with a null parent Id
  //   const hasNullParentId = (locations) => {
  //     return locations.some((location) => location.parentLocationId === null);
  //   };

  //   const hasDefaultLocation = locations.some(
  //     (location) => location.defaultLocation
  //   );

  //   console.log("LocationTreeView locations", locations);

  //   if (!hasDefaultLocation && !defaultLocation) {
  //     const newData = {
  //       owner: auth.user.uid,
  //       parentLocationId: null,
  //       name: "Default Location",
  //       defaultLocation: true,
  //     };
  //     setDefaultLocation(true);
  //     createLocation(newData);
  //   }
  // }, [locations, defaultLocation]);


  // useEffect(() => {
  //   const checkAndCreateDefaultLocation = async () => {
  //     if (!locations || locations.length === 0 || defaultLocation) return;
  
  //     const hasDefaultLocation = locations.some(loc => loc.defaultLocation);
  //     if (!hasDefaultLocation) {
  //       const newData = {
  //         owner: auth.user.uid,
  //         parentLocationId: null,
  //         name: "Default Location",
  //         defaultLocation: true,
  //       };
  //       await createLocation(newData); // Make sure this is awaited or handled correctly for asynchronous execution
  //       setDefaultLocation(true);
  //     }
  //   };
  
  //   checkAndCreateDefaultLocation();
  // }, [locations]); // Only re-run this effect if locations array changes
  

  useEffect(() => {
    if (locations == null) {
      return;
    }


    // Check there is at least one location with a null parent Id
    const hasNullParentId = (locations) => {
      return locations.some((location) => location.parentLocationId === null);
    };



    const hasDefaultLocation = locations.some((location) => location.defaultLocation);

    console.log("LocationTreeView locations", locations);
    
    if (!hasDefaultLocation) {
      const newData = {
        owner: auth.user.uid,
        parentLocationId: null,
        name: "Default Location",
        defaultLocation: true,
      };
      createLocation(newData);
    }


  }, [locations]);


  function handleSelectItem(l) {
    if (l == null) {
      return;
    }

    console.log("before location setSelectedLocation", l);
    // setSelectedLocationId(l);
    setSelectedLocationId(null);
    console.log("after location setSelectedLocation", l);
    setLens("item");
  }

  /// Select First Location on Load

  useEffect(() => {
    if (
      firstLoad &&
      allLocationsStatus === "success" &&
      allLocations.length > 0
    ) {
      const sortedLocations = [...allLocations].sort((a, b) => {
        // Check if createdAt exists for both objects before calling toDate
        if (a.createdAt && b.createdAt) {
          return a.createdAt.toDate() - b.createdAt.toDate();
        }
        // If createdAt does not exist for one or both objects, handle this case appropriately
        // For example, you might choose to sort such objects to the end of the array
        else {
          return a.createdAt ? -1 : 1;
        }
      });
      handleSelectLocation(sortedLocations[0].id, "first-load");
      setFirstLoad(false);
    }
  }, [firstLoad, allLocationsStatus, allLocations]);

  /*
  useEffect(() =>{

    if (lens === 'location') {
      history.push(`/location/${selectedLocationId}`);
        }

  }, [lens])
*/
  function handleBreadcrumbLocationSelect() {}

  // This listens for an itemData.
  // Which only gets updated if itemId
  // changes.

  // But ... only want to set location if there isn't one.
  // ? May not be quite it.
  // What about if a location is set.

  // Is fine because location is set off locationId
  // seperately via a history.push
  useEffect(() => {
    if (itemData == null) {
      return;
    }

    console.log("location xkcd itemData", itemData);
    if (selectedLocationId == null) {
      console.log(
        "before location itemData setSelectedLocation",
        itemData.locationId
      );
      setSelectedLocationId(itemData.locationId);
      console.log(
        "after location itemData setSelectedLocation",
        itemData.locationId
      );
    }
  }, [itemData]);

  useEffect(() => {
    console.log("location all", allLocations);
  }, [allLocations]);

  useEffect(() => {
    console.log("itemsstatus", itemsStatus);
    if (locationId != null && itemsStatus === "success") {
      console.log("before setSelectedLocation", locationId);

      if (locationId !== null) {
        setSelectedLocationId(locationId);

        console.log("after setSelectedLocation", locationId);
      }
      setLoading(false);
    }
  }, [locationId, itemsStatus]);

  const breadcrumbRef = useRef();
  const [breadcrumbVisible, setBreadcrumbVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setBreadcrumbVisible(false);
        } else {
          setBreadcrumbVisible(true);
        }
      },
      { threshold: 1.0, rootMargin: "0px 0px 0px 0px" }
    );

    if (breadcrumbRef.current) {
      observer.observe(breadcrumbRef.current);
    }

    return () => {
      if (breadcrumbRef.current) {
        observer.unobserve(breadcrumbRef.current);
      }
    };
  }, []);

  const [updatingItemId, setUpdatingItemId] = useState();

  function handleUpdatingItem(itemId) {
    console.log(
      "location handleUpdatingItem itemId locationId",
      itemId,
      locationId
    );

    setLens("item");
  }

  const handleNavigation = (text) => {
    const regex = /localhost:\d+/;
    const match = text.match(regex);

    if (match || text.includes("itemgenie.com")) {
      const url = new URL(text);
      const pathname = url.pathname;
      history.push(pathname);
      return true;
    }
    return false;
  };

  const [scanCode, setScanCode] = useState();

  useEffect(() => {
    if (
      !isLoadingItems &&
      items &&
      !isLoadingLocations &&
      locations &&
      decodedText
    ) {
      const userItems = items.filter((item) => item.scanCode === decodedText);
      const userLocations = locations.filter(
        (location) => location.scanCode === decodedText
      );

      // Process user items and locations if found
      if (userItems.length > 0 || userLocations.length > 0) {
        const item = getLatestItem(userItems);
        const location = getLatestLocation(userLocations);

        console.log("location handleScanResult item", item);
        console.log("location handleScanResult location", location);

        if (location !== null) {
          history.push(`/location/${location.id}`);
          setDecodedText(null); // Clear decodedText state
          return;
        }

        if (item && item !== null) {
          console.log("item id location xyz", itemId);
          // history.push(`/inventory/`); // might be a little hacky. reset, to then go to item. address issue of component not updating url when one scan as been completed.

          history.push(`/item/${item.id}`);
          setDecodedText(null); // Clear decodedText state
          return;
        }
      }
      // If no user items or user locations are found, open the Snackbar
      else {
        openSnackbar(); // Open the Snackbar
      }
    }
  }, [
    isLoadingItems,
    items,
    isLoadingLocations,
    locations,
    decodedText,
    history,
    itemId,
  ]);

  const handleScanResult = async (decodedText, decodedResult) => {
    console.log("auth location", auth.user.uid);
    console.log(
      "location handleScanResult decodedText",
      decodedText,
      decodedResult
    );

    if (decodedText == null) {
      return;
    }

    if (handleNavigation(decodedText)) {
      return;
    }
    setDecodedText(decodedText);
  };

  const handleMoveLocation = async (sourceId, targetId) => {
    console.log("isChildLocation handleMoveLocation");

    if (isNestedChild(targetId, sourceId, allLocations)) {
      console.log("isChildLocation no");
      return;
    }

    console.log("isChildLocation yes");

    if (sourceId === targetId) {
      return;
    }

    console.log("handleMoveLocation stop");

    //return;

    try {
      await updateLocation(sourceId, { parentLocationId: targetId });
      console.log("Location moved successfully");
    } catch (error) {
      console.error("location.jsError moving location:", error);
    }
  };

  return (
    <>
      <Meta title="Inventory" />

      <Container maxWidth={true} sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "100%",
            // background: "#e8e7e7"
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              alignItems: "center",
              // paddingRight: "16px",
              flexGrow: "1",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 1 }} py={2}>
              {isMobile && !mobileDrawerOpen && (
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  // aria-label="Open Mobile Drawer"
                  onClick={toggleMobileDrawer}
                  startIcon={<ReadMoreIcon />}
                >
                  Locations (Add/Choose/Edit)
                </Button>
              )}
            </Box>

            <div >
              <QRCodeScanner
             
                startScan={startScan}
                fps={100}
                qrbox={450}
                disableFlip={false}
                qrCodeSuccessCallback={(decodedText, decodedResult) =>
                  handleScanResult(decodedText, decodedResult)
                }
                onClose={handleScannerClose}
              />

              {/* )} */}
            </div>
          </Toolbar>

          <Box>
            {isMobile && (
              <Typography variant="subtitle1">
                <b>Location:</b> {selectedLocationName}
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
     
      <Grid container>
        {isMobile && (
          <Drawer
            anchor="left"
            open={mobileDrawerOpen}
            onClose={toggleMobileDrawer}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{ width: "300px", background: "#f7f7f7", paddingTop: "20px" }}
            >
              {allLocationsStatus === "success" && (
                <>
                  <LocationTreeView
                    // updatingItemId={updatingItemId}
                    lens={lens}
                    items={items}
                    locations={allLocations}
                    onMove={handleMoveLocation}
                    onSelect={(f) => {
                      if (f === selectedLocationId) {
                        return;
                      }
                      console.log("xkcd TreeClick");
                      handleSelectLocation(f, "tree-view");
                    }}
                    //preventSelect={preventSelect}
                    inputLocation={selectedLocationId}
                    onLocationPathChange={setLocationPath}
                    setCreatingLocation={setCreatingLocation}
                  />
                </>
              )}
            </Grid>
          </Drawer>
        )}

        {!isMobile && (
          <Grid item md={4}>
            {allLocationsStatus === "success" && (
              <>
                <LocationTreeView
                  // updatingItemId={updatingItemId}
                  lens={lens}
                  items={items}
                  locations={allLocations}
                  onMove={handleMoveLocation}
                  onSelect={(f) => {
                    if (f === selectedLocationId) {
                      return;
                    }
                    console.log("xkcd TreeClick");
                    handleSelectLocation(f, "tree-view");
                  }}
                  //preventSelect={preventSelect}
                  inputLocation={selectedLocationId}
                  onLocationPathChange={setLocationPath}
                  setCreatingLocation={setCreatingLocation}
                />
              </>
            )}
          </Grid>
        )}

        {/* <div ref={breadcrumbRef}></div> */}

        <Grid item xs={12} md={8}>
          {/* {locationId && (  */}
          <DashboardItems
            lists={lists}
            breadcrumbVisible={breadcrumbVisible}
            items={items}
            spice="inventory"
            locations={allLocations}
            locationId={selectedLocationId}
            inputItem={itemId}
            onSelect={handleSelectItem}
            locationPath={locationPath}
            inputLocation={selectedLocationId}
            onItemUpdate={handleUpdatingItem}
          />
          {/* )}
           */}
        </Grid>
      </Grid>

  

      {creatingLocation && (
        <EditLocationModal
          action={"create"}
          selectedLocationId={null} /// MT SET THIS TO NULL TO AVOID LOCATION URL AND SELECT OVERIDING ON NEW CREATE
          onSave={(updatedLocation) => {
            updateLocation(auth.user.uid, updatedLocation);
            setCreatingLocation(false);
          }}
          onDone={handleDoneCreatingLocation}
          onCancel={() => setCreatingLocation(false)}
          open={creatingLocation}
          onClose={() => setCreatingLocation(false)}
        />
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="error">
          The scanned code does not match any items or locations that you own.
        </Alert>
      </Snackbar>
    </>
  );
}

export default requireAuth(LocationPage);
