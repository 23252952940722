import React, { useEffect, useState, useRef, useMemo, useContext } from "react";

// import { DndProvider, useDrop } from "react-dnd";
import { useDrop } from "react-dnd";
import { TreeView } from "@mui/lab";
import LocationTreeItem from "./LocationTreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Link, Typography, Breadcrumbs, Divider, Toolbar } from "@mui/material";
import { useAuth } from "../util/auth";
import { createLocation } from "../util/db";
import LocationBreadcrumbs from "./LocationBreadcrumbs";
import {
  Container,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { usePreview } from "react-dnd-preview";
import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";

import {
  DndProvider,
  TouchTransition,
  MouseTransition,
  Preview,
} from "react-dnd-multi-backend";
import { findLocationPath } from "../util/functions";

export const SelectedLocationContext = React.createContext(null);
// const MultiBackend = MultiBankend;
const classes = {
  selected: {
    bgcolor: "#fdb8693d",

    // color: "white"
  },
};

const RootDropArea = ({ onMove }) => {
  const [{ isOverRoot }, rootDrop] = useDrop(() => ({
    accept: "location",
    drop: (item) => {
      // onMove(item.id, null);
      onMove(item.id, null);
    },
    collect: (monitor) => ({
      isOverRoot: !!monitor.isOver(),
    }),
  }));


  

  return (
    <div
      ref={rootDrop}
      style={{
        width: "400px",
        maxWidth: "400px",
        height: "20px",
        // marginTop:'-10px',
        // marginBottom:'-20px',

        // border: "1px solid black",
        backgroundColor: isOverRoot ? "rgba(0, 0, 0, 0.1)" : "transparent",

        // position:'absolute',
      }}
    />
  );
};

const buildTree = (
  locations,
  parentId = null,
  onMove,
  onSelect,
  selectedLocationId
) => {
  if (!locations) return [];
  const hasChildren = (locationId) =>
    locations.some((loc) => loc.parentLocationId === locationId);

  return locations
    .filter((location) => location.parentLocationId === parentId)

    .sort((a, b) => {
      // Check if both createdAt properties exist
      if (a.createdAt && b.createdAt) {
        // If both exist, sort by createdAt in ascending order
        return a.createdAt < b.createdAt ? -1 : 1;
      } else if (a.createdAt) {
        // If only a has a createdAt property, place it before b
        return -1;
      } else if (b.createdAt) {
        // If only b has a createdAt property, place it before a
        return 1;
      } else {
        // If neither have a createdAt property, maintain their relative order
        return 0;
      }
    })

    .map((location) => {
      const children = buildTree(
        locations,
        location.id,
        onMove,
        onSelect,
        selectedLocationId,
      );

      const isSelected = location.id === selectedLocationId;

      return (
      
          <LocationTreeItem
            key={location.id}
            isSelected={isSelected}
            location={location}
            onMove={onMove}
            onSelect={onSelect}
            selectedLocationId={selectedLocationId}
          >
            {children.length > 0 && children}
          </LocationTreeItem>
         
      );
    });
};

const allLocationsItem = {
  id: 'all',
  name: 'All Locations',
};

const LocationTreeView = ({
  lens,
  locations,
  onMove,
  onSelect,
  inputLocation,
  onLocationPathChange,
  setCreatingLocation,
//  updatingItemId,
}) => {
  // const [expanded, setExpanded] = useState([]);
  const auth = useAuth();
  const [selectedLocationId, setSelectedLocationId] = useState(inputLocation);
  const showAll = selectedLocationId === 'all'; 

  useEffect(() => {
    console.log("LocationTreeView selectedLocationId", selectedLocationId);
  }, [selectedLocationId]);

  //console.log("LocationTreeView selectedLocationId", selectedLocationId);
  const handleMove = (sourceId, targetId) => {
    onMove(sourceId, targetId);
    setExpanded((prevExpanded) => {
      if (!prevExpanded.includes(targetId)) {
        return [...prevExpanded, targetId];
      }
      return prevExpanded;
    });
  };

  const handleSelect = (locationId) => {
    console.log("LocationTreeView handleSelect", locationId);
    setSelectedLocationId(locationId);
    onSelect(locationId);
    

    if (locationId !== allLocationsItem.id) {
      const path = findLocationPath(locationId, locations);
      setLocationPath(path);
    }
  };

  useEffect(() => {
    if (inputLocation == null) {
      return;
    }
    if (locations && locations.length === 0) {
      return;
    }
    handleSelect(inputLocation);
  }, [inputLocation, locations,]);

  // Build the tree with the existing locations
  const tree = buildTree(locations, null, handleMove, handleSelect, selectedLocationId);



 // Prepend "All Locations" item to the tree
 const treeWithAllLocations = [

    <LocationTreeItem
      key={allLocationsItem} 
      isSelected={allLocationsItem.id === selectedLocationId}
      location={allLocationsItem}
      onMove={onMove}
      onSelect={handleSelect}
      selectedLocationId={selectedLocationId}
   
    />
 ,
  ...tree,
];


  useEffect(() => {
    console.log("LocationTreeView inputLocation locations");
    if (inputLocation) {
      const path = findLocationPath(inputLocation, locations);
      console.log("Path:", path); // Log path to see its value
      if (Array.isArray(path)) { // Check if path is an array
        const parentIds = path.map((location) => location.id);
  
        const newExpanded = expanded.concat(
          parentIds.filter((item) => expanded.indexOf(item) < 0)
        );
        console.log("flerp inputLocation locations", inputLocation);
        setExpanded(newExpanded);
      } else {
        console.error('path is not an array');
      }
    }
  }, [locations]);

  

  // useEffect(() => {
  //   if (locations == null) {
  //     return;
  //   }


  //   // Check there is at least one location with a null parent Id
  //   const hasNullParentId = (locations) => {
  //     return locations.some((location) => location.parentLocationId === null);
  //   };



  //   const hasDefaultLocation = locations.some((location) => location.defaultLocation);

  //   console.log("LocationTreeView locations", locations);
    
  //   if (!hasDefaultLocation) {
  //     const newData = {
  //       owner: auth.user.uid,
  //       parentLocationId: null,
  //       name: "Default Location",
  //       defaultLocation: true,
  //     };
  //     createLocation(newData);
  //   }


  // }, [locations]);


  useEffect(() => {
    console.log("LocationTreeView onMove", onMove);
  }, [onMove]);

  useEffect(() => {
    console.log("LocationTreeView onSelect", onSelect);
  }, [onSelect]);

  const [locationPath, setLocationPath] = useState([]);

  useEffect(() => {
    if (onLocationPathChange) {
      onLocationPathChange(locationPath);
    }
  }, [locationPath, onLocationPathChange]);

  const [expanded, setExpanded] = useState(() => {
    if (selectedLocationId) {
      //console.log("LocationTreeView foobarbar")
      const path = findLocationPath(selectedLocationId, locations);
      return path ? path.map((location) => location.id) : [];
    }

    return [];
  });

  useEffect(() => {
    console.log("LocationTreeView expanded", expanded);
  }, [expanded]);

  const GeneratePreview = () => {
    const preview = usePreview();
    if (!preview.display) {
      return null;
    }
    const { itemType, item, style } = preview;
    return (
      <div
        style={{
          ...style,
          // backgroundColor: "rgba(255, 255, 255, 0.8)",
          backgroundColor: "#e379f696",
          borderRadius: "4px",
          padding: "6px",
          width: "250px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
        }}
      >
        {item.name}
      </div>
    );
  };

  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  const CustomHTML5toTouch = {
    backends: [
      {
        id: "html5",
        backend: HTML5Backend,
        transition: MouseTransition,
      },
      ...(!isTouchDevice
        ? [
            {
              id: "touch",
              backend: TouchBackend,
              options: { enableMouseEvents: true },
              preview: true,
              transition: TouchTransition,
            },
          ]
        : []),
    ],
  };

  const theme = useTheme();

  return (
    <>
      <SelectedLocationContext.Provider value={selectedLocationId}>
        <DndProvider options={CustomHTML5toTouch}>
       
          {locations && locations.length === 0 && <>No Locations</>}
          <Container disableGutters maxWidth="lg">
            <Card variant="none" sx={{ background: "#f7f7f7" }}>
              {/* <RootDropArea onMove={onMove} /> */}
              <Toolbar >
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    color="secondary"
                    onClick={() => {
                      setCreatingLocation(true);
                    }}
                    startIcon={<AddHomeWorkOutlinedIcon />}
                  >
                    Create Location
                  </Button>
              </Toolbar>

              <TreeView
              
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                    
                selected={selectedLocationId}
     
                sx={{
                  ".MuiTreeItem-root": {
                    ".Mui-focused:not(.Mui-selected)": classes.focused,
                    ".Mui-selected, .Mui-focused.Mui-selected, .Mui-selected:hover":
                      classes.selected,
                  },
                }}
                onNodeToggle={(event, nodeIds) => {
                  console.log("onNodeToggle");

                  
                  console.log("flerp onNodeToggle");

                  setExpanded((prevExpanded) => nodeIds);
                }}
              >
                {treeWithAllLocations}
              </TreeView>

              <Box sx={{ height: "30px" }} />
            </Card>
          </Container>
          {/* <Divider />  */}
        </DndProvider>
      </SelectedLocationContext.Provider>

      <Divider />
    </>
  );
};

export default LocationTreeView;
