import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditItemModal from "./EditItemModal";
import { useAuth } from "./../util/auth";
import BreadcrumbNav from "./BreadcrumbNav";
import Fade from "@mui/material/Fade";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import {
  useItem,
  updateItem,
  deleteItem,
  useItemsByOwner,
  useItemsByParentLocation,
  useLocation,
  createItem,
  updateUser,
  getItem,
  useFetchUser,
} from "./../util/db";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { scoreItem } from "./../util/item";
import { usePlanChecker, useHasExceededItems } from "./../util/functions";

import {
  Card,
  CardContent,
  Container,
  Grid,
  Hidden,
  Snackbar,
  Collapse,
  Breadcrumbs,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Link } from "./../util/router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFirebaseImage } from "./../util/db";

import { getNestedChildren } from "./../util/functions";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import SquareAvatar from "./SquareAvatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import EditLocationModal from "./EditLocationModal";
import {
  useItemFieldSelections,
  useItemsInHierarchy,
  useItemsByOwnerAndLocation,
  useItemsInChildLocations,
  useItemsByParentAndChildren,
  removeItemFromListAndDeletePublicItem,
} from "./../util/db";
import Debug from "./Debug";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LocationTreeView from "./LocationTreeView";
import ItemActions from "./ItemActions";
import LocationBreadcrumbs from "./LocationBreadcrumbs";
import {
  AppBar,
  Toolbar,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ItemLocationName from "./ItemLocationName";
import { styled } from "@mui/material/styles";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ItemAdvancedDetails from "./ItemAdvancedDetails";
import FormatLineSpacingTwoToneIcon from "@mui/icons-material/FormatLineSpacingTwoTone";
import SimpleDialogDemo from "./SimpleDialog";
import { useTheme } from "@mui/material/styles";
import CustomItemFieldsDrawer from "./CustomItemFieldsDrawer";
import { Input } from "@material-ui/core";
import Search from "./Search";
import ItemActionAddToList from "./ItemActionAddToList";
import Close from "@mui/icons-material/Close";
import { clampDaySectionIfPossible } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

// import PublishItem from "./PublishItem";

// import ItemFieldSelector from "./ItemFieldSelector";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function FirstItemImage({ imageUrl }) {
  const firstImage = useFirebaseImage(imageUrl);
  return firstImage ? <img src={firstImage} alt="First Item Image" /> : null;
}

function DashboardItems(props) {
  const history = useHistory();
  const theme = useTheme();
  const auth = useAuth();

  const { itemId: paramItemId } = useParams();

  const {
    firstLoad,
    locationId,
    items,
    locations,
    onSelect,
    breadcrumbVisible,
    inputItem,
    onItemUpdate,
    inputLocation,
    selectedListId,
    lists,
    spice,
  } = props;
  console.log("lists dashboarditems", lists);
  const [itemFields, updateItemFieldSelections] = useItemFieldSelections();
  const [loading, setLoading] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [locationItems, setLocationItems] = useState([]);
  const { data: locationData } = useLocation(locationId);
  const [alertOpen, setAlertOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  console.log("locationId dashboarditems", locationId);

  console.log("selectedListId dashboarditems", selectedListId);

  const itemsStatus = "success";
  const itemsError = null;

  const itemRefs = useRef(new Map()).current;

  const scrollToItem = (itemId) => {
    const itemRef = itemRefs.get(itemId);
    if (itemRef) {
      itemRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return; // Don't close if clicked outside the snackbar
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const [creatingItem, setCreatingItem] = useState(false);

  const [updatingItemId, setUpdatingItemId] = useState(null);

  useEffect(() => {
    if (paramItemId == null) {
      return;
    }
    setUpdatingItemId(paramItemId);
  }, [paramItemId]);

  const [creatingLocation, setCreatingLocation] = useState(false);

  const [showName, setShowName] = useState(true);
  const [showDescription, setShowDescription] = useState(true);
  const [showPrice, setShowPrice] = useState(true);
  const [showQuantity, setShowQuantity] = useState(true);
  const [showCategory, setShowCategory] = useState(true);
  const [firstItemCreated, setFirstItemCreated] = useState(false);

  console.log("firstItemcreated flag", firstItemCreated);

  const userPlan = usePlanChecker(
    auth?.user?.stripePriceId || null,
    auth?.user?.stripeSubscriptionStatus || null
  );

  const itemCount = auth.user.itemCount;

  
  console.log("itemcount dashboard items xyz", itemCount);
  console.log("userplan xyz", userPlan);
  const hasExceeded = useHasExceededItems(itemCount, userPlan);

  if (hasExceeded) {
    console.log("user plan number of items exceeded");
  }

  let linkPath;
  if (userPlan === "basic") {
    linkPath = "/pricing";
  } else if (userPlan === "advanced") {
    linkPath = "/settings/billing";
  } else {
    linkPath = "#"; // Default path or you can omit
  }

  const handleAddItemClick = () => {
    if (hasExceeded) {
      setAlertOpen(true);
    } else {
      setCreatingItem(true);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const [highlightedItemId, setHighlightedItemId] = useState(null);
  //setHighlightedItemId(inputItem);
  useEffect(() => {
    if (inputItem == null) {
      return;
    }

    setHighlightedItemId(inputItem);

    console.log("DashboardItems inputItem", inputItem);
    //setUpdatingItemId(inputItem)
  }, [inputItem]);

  useEffect(() => {
    onItemUpdate(updatingItemId);
    if (updatingItemId == null) {
      return;
    }
    console.log("DashboardItems updatingItemId");
    //
  }, [updatingItemId]);

  // Add new state for all selected items
  const [selectAll, setSelectAll] = useState(false);

  const item = items?.find((item) => item.id);

  // name={items?.find((item) => item.id === updatingItemId)?.name}
  // Add new state for selected items
  const [selectedItems, setSelectedItems] = useState([]);

  // Add new state for action
  const [action, setAction] = useState("");

  // Add Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("info");
  const itemsAreEmpty = !items || items.length === 0;
  const locationItemsAreEmpty = !locationItems || locationItems.length === 0;
  const [searchTerm, setSearchTerm] = useState("");

  const [expanded, setExpanded] = useState(false);
  const flavour = selectedListId ? "listid" : locationId ? "locations" : null;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  // Define the `onDone` function
  const handleDoneCreatingLocation = () => {
    setCreatingLocation(false);
    // Do something else if needed
  };

  const handleStarItem = (item) => {
    if (canUseStar) {
      updateItem(item.id, { featured: !item.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  // React.useEffect(() => {
  //   setChecked(selectedItems.length > 0);
  // }, [selectedItems]);

  // Event handler for Checkbox
  const handleToggle = (itemId) => {
    const currentIndex = selectedItems.indexOf(itemId);
    const newSelectedItems = [...selectedItems];

    if (currentIndex === -1) {
      newSelectedItems.push(itemId);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  // const handleDeleteItem = async (itemId, user) => {
  //   await deleteItem(itemId);

  //   try {

  //     if (auth.user && !auth.user.firstItemDeleted) {
  //       // await updateUser(uid, { firstItemDeleted: true });
  //      await updateUser(auth.user.uid, { defaultItemDeleted: true });
  //     }
  //   } catch (error) {
  //     console.error("Failed to update user:", error);
  //     handleSnackbarMessage({
  //       message: "Failed to update user data",
  //       type: "error",
  //     });
  //     return;
  //   }

  //   handleSnackbarMessage({
  //     message: "Item Deleted Successfully",
  //     type: "success",
  //   });
  // };

  // Ensure you have imported the necessary functions:
  // import { deleteItem, updateUser, getItem } from './yourServiceFiles';

  const handleDeleteItem = async (itemId, user) => {
    try {
      console.log("Attempting to delete item:", itemId);

      // Fetch item data using the getItem function
      const itemDoc = await getItem(itemId);
      const itemData = itemDoc ? itemDoc : {};

      // Check if the item is a default item
      if (itemData.defaultItem) {
        // Update user only if this is their first item being deleted
        if (auth.user && !auth.user.firstItemDeleted) {
          await updateUser(auth.user.uid, { firstItemDeleted: true });
          console.log("Updated user for first item deletion");
        }
      }

      // Proceed to delete the item
      await deleteItem(itemId);
      console.log("Item deleted successfully:", itemId);

      handleSnackbarMessage({
        message: "Item Deleted Successfully",
        type: "success",
      });
    } catch (error) {
      console.error("Failed to delete item or update user:", error);
      handleSnackbarMessage({
        message: "Failed to perform operation",
        type: "error",
      });
    }
  };

  const handleSnackbarMessage = (data) => {
    setSnackbarMessage(data.message);
    setSnackbarType(data.type || "info");
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleRemoveItem = async (itemId, listIdToRemove) => {
    try {
      // Fetch the current item data
      const itemData = await getItem(itemId); // Assuming getItem fetches the full item data
  
      if (itemData && Array.isArray(itemData.listId)) {
        // Remove the specified listId from the array
        const updatedListIds = itemData.listId.filter(id => id !== listIdToRemove);
  
        // Update the item with the new listId array
        const updateSuccess = await updateItem(itemId, { listId: updatedListIds });
        if (updateSuccess) {
          console.log(`List ID ${listIdToRemove} removed from item with ID: ${itemId}`);
          // Optionally: Refresh local UI state or re-fetch items to reflect the changes
        } else {
          console.error(`Failed to update item with ID: ${itemId}`);
        }
      } else {
        console.error(`Item with ID: ${itemId} does not exist or has no listId array`);
      }
    } catch (error) {
      console.error(`Failed to process item with ID: ${itemId}`, error);
    }
  };

  const handleMoveSelectedItems = (location) => {
    selectedItems.forEach((itemId) => {
      updateItem(itemId, { locationId: location });
    });
    setSelectedItems([]);
    setSelectAll(false);
  };

  scrollToItem(inputItem);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const [searchItems, setSearchItems] = useState([]);
  const [matches, setMatches] = useState([]);

  function handleChangeSearch(keywords, matches) {
    setSearchTerm(keywords);
    if (items == null) {
      return;
    }

    if (locations == null) {
      return;
    }
    if (typeof keywords !== "string") {
      return;
    }
    if (keywords.trim() === "") {
      return;
    }

    console.log("DashboardItems keywords", keywords);

    const itemLocations = [...items, ...locations];
    const matchedItemsLocations = itemLocations.filter(
      (matchedItemLocation) => {
        // Make a haystack of only the values of the array.
        const haystack = Object.values(matchedItemLocation);
        const result = Object.keys(haystack).reduce(function (r, k) {
          return r.concat(haystack[k]);
        }, []);

        const flattenedHaystack = JSON.stringify(haystack);

        return true;
      }
    );

    console.log("DashboardItems matches handlechangesearch", matches);
  }

  useEffect(() => {
    if (selectedItems == null) {
      return;
    }

    if (selectedItems.length == 0) {
      setSelectAll(false);
    }
  }, [selectedItems]);

  useEffect(() => {
    console.log("Effect Running", {
      firstItemCreated,
      locations: locations?.length,
      items: items?.length,
    });

    const firstItemDeleted = auth.user.firstItemDeleted;

    console.log("firstitemdeleted", firstItemDeleted);

    if (
      !firstItemDeleted &&
      !firstItemCreated &&
      locations.length > 0 &&
      items.length >= 0
    ) {
      const defaultLocation = locations.find((loc) => loc.defaultLocation);

      if (defaultLocation) {
        const itemsInDefaultLocation = items.filter(
          (item) => item.locationId === defaultLocation.id
        );

        if (itemsInDefaultLocation.length === 0) {
          // There are no items in the default location, so add the first item
          const firstItem = {
            name: "First Item",
            locationId: defaultLocation.id,
            owner: auth.user.uid, // Assume auth.user.uid is stable or managed elsewhere if it might change
            defaultItem: true,
          };

          createItem(firstItem)
            .then((itemRef) => {
              console.log("First item created successfully:", itemRef);
              setFirstItemCreated(true); // Set the flag to true after successful creation
            })
            .catch((error) => {
              console.error("Error creating first item:", error);
            });
        }
      }
    }
  }, [locations, items, auth.user.uid, firstItemCreated]);


  useEffect(() => {
    console.log(
      "DashboardItems items locations locationId",
      items,
      locations,
      locationId
    );

    // Check if locationId is "all"
    if (locationId === "all") {
      // If locationId is "all", set all items as the filteredItems
      setFilteredItems(items);
      setLocationItems(items);
      console.log("All items are set as filteredItems");
    } else {
      // If locationId is not "all", filter items based on locationId
      const childLocations = getNestedChildren(locations, locationId);
      const locationIds = childLocations.map(location => location.id);

      const filteredLocationItems = items?.filter((item) => locationIds.includes(item.locationId));

      const filteredItems = selectedListId
        ? filteredLocationItems.filter((item) => item.listId && item.listId.includes(selectedListId))
        : filteredLocationItems;

      setLocationItems(filteredItems);
      console.log("filtered location items", filteredItems);
    }
  }, [items, locations, locationId, selectedListId]);



  useEffect(() => {
    if (!items || !locationItems) {
      console.log("Items or location items are not available yet.");
      return;
    }

    setLoading(true);
    let itemsToFilter = locationId === "all" ? [...items] : [...locationItems]; // Ensure a copy is made if mutation could be an issue

    if (itemsToFilter.length > 0) {
      const sortedFilteredItems = itemsToFilter.sort((a, b) => {
        if (sortOrder === "asc") {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
      setFilteredItems(sortedFilteredItems);
    } else {
      console.log("No items to sort.");
      setFilteredItems([]);
    }
    setLoading(false);
  }, [locationItems, selectedListId, items, sortOrder]);

  //Search for the match and setFilteredItems
  useEffect(() => {
    setLoading(true);
    console.log("searchTerm", searchTerm);
    let itemsToSearch = locationId === "all" ? items : locationItems;
    // Calculate the listFiltered and locationFiltered outside the conditions
    // const listFiltered = selectedListId
    //   ? locationItems.filter((item) => item.listId === selectedListId)
    //   : locationItems;

    const listFiltered = selectedListId
      ? locationItems.filter(
          (item) => item.listId && item.listId.includes(selectedListId)
        )
      : locationItems;

    console.log("selectedListId xxx", selectedListId);

    console.log("list filtered", listFiltered);


    const locationFiltered = locationId
      ? listFiltered?.filter((item) => item.locationId === locationId)
      : listFiltered;

  


    if (searchTerm === "") {
      setFilteredItems(itemsToSearch);
      setLoading(false);
      return;
    }

    if (searchTerm === null && matches.length === 0) {
      // If searchTerm is empty, show the default list, else show no items
      // setFilteredItems(searchTerm === "" ? locationFiltered : []);
      setFilteredItems(locationFiltered);
      setLoading(false);
      return;
    }

    if (matches.length === 0) {
      // If searchTerm is empty, show the default list, else show no items
      // setFilteredItems(searchTerm === "" ? locationFiltered : []);
      setFilteredItems([]);
      setLoading(false);
      return;
    }

    console.log("Selected List Id xyz", selectedListId);

    // Check if there are any matches from the search.
    if (matches && matches.length > 0) {
      // If there are matches, filter those matches further based on selectedListId and locationId.
      const listMatches = selectedListId
        ? matches.filter((item) => item.listId === selectedListId)
        : matches;

      const locationMatches =
        locationId !== "all"
          ? listMatches.filter((item) => item.locationId === locationId)
          : listMatches;

      setFilteredItems(locationMatches);
   
    }

    setLoading(false);
  }, [matches, selectedListId, locationId, searchTerm]);

  const actionbarRef = useRef();
  const [actionbarVisible, setActionBarVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setActionBarVisible(false);
        } else {
          setActionBarVisible(true);
        }
      },
      { threshold: 1.0, rootMargin: "60px 0px 0px 0px" }
    );

    if (actionbarRef.current) {
      observer.observe(actionbarRef.current);
    }

    return () => {
      if (actionbarRef.current) {
        observer.unobserve(actionbarRef.current);
      }
    };
  }, []);

  return (
    <>
      <Debug>
        locationId:-- {locationId}
        <br />
        auth.user.uid:--
        {auth.user.uid}
      </Debug>

      {/* {matches &&
        matches.map((match) => {
          return (
            <>
           {match.name}
              <br />
            </>
          );
        })} */}

      {itemsError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {itemsError.message}
        </Alert>
      )}

      {/*/////////////// Item Toolbar/Actions ////////////////*/}

      <Container disableGutters maxWidth="lg">
        <div ref={actionbarRef}></div>
        <Box
          sx={{
            [theme.breakpoints.down("md")]: {
              position: "sticky",
              top: "0px",
              zIndex: 9,
              // transition: actionbarVisible ? "0.25s" : "none",
              borderBottom: "1px solid #e8e8e8",
              backgroundColor: "#ffffff",
            },
          }}
        >
          {/* <Box> */}
          <Box
            py={1}
            sx={{
              alignItems: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              flexGrow: "1",
              justifyContent: "flex-end",
            }}
          >
            {/* <Search onChangeSearch={(t) => handleChangeSearch(t)} items={items} matches={matches} /> */}

            {spice === "inventory" && (
              <>
                <Search
                  onChangeSearch={handleChangeSearch}
                  items={items}
                  setMatches={setMatches}
                  flavour={flavour}
                  locationId={locationId}
                />
              </>
            )}

            <Toolbar disableGutters>
              <Tooltip title="Select All">
                <Checkbox
                  edge="start"
                  checked={selectAll}
                  onChange={(event) => {
                    setSelectAll(event.target.checked);
                    if (event.target.checked) {
                      setSelectedItems(locationItems.map((item) => item.id));
                    } else {
                      setSelectedItems([]);
                    }
                  }}
                />
              </Tooltip>

              <IconButton
                onClick={handleClick}
                style={{
                  backgroundColor: isExpanded ? "grey" : "white",
                  color: isExpanded ? "white" : "inherit",
                  marginLeft: "15px",
                }}
              >
                {isExpanded ? (
                  <FormatLineSpacingTwoToneIcon
                    className="MuiSvgIcon-root"
                    style={{ animationName: "rotation" }}
                  />
                ) : (
                  <FormatLineSpacingTwoToneIcon
                    className="MuiSvgIcon-root"
                    style={{ animationName: "rotation" }}
                  />
                )}
              </IconButton>

              <IconButton
                onClick={toggleSortOrder}
                color="primary"
                aria-label="sort items"
              >
                <SortByAlphaIcon />
              </IconButton>

              <Box sx={{ flexGrow: 1, zIndex: 99 }}>
                {selectedItems.length > 0 && (
                  <>
                    <ItemActions
                      onSnackbarMessage={handleSnackbarMessage}
                      items={items}
                      inputLocation={inputLocation}
                      locations={locations}
                      //onSelect={onSelect}
                      onSelect={() => {}}
                      selectedItems={selectedItems}
                      onItemsMoved={setSelectedItems}
                    />

                    <ItemActionAddToList
                      lists={lists}
                      onSnackbarMessage={handleSnackbarMessage}
                      items={items}
                      inputLocation={inputLocation}
                      locations={locations}
                      //onSelect={onSelect}
                      onSelect={() => {}}
                      selectedItems={selectedItems}
                      onItemsMoved={setSelectedItems}
                    />
                  </>
                )}
              </Box>

              {selectedItems.length === 0 && <CustomItemFieldsDrawer />}

              <Hidden mdUp>
                <Fab
                  variant="extended"
                  size="large"
                  color="secondary"
                  aria-label="add"
                  style={{ position: "fixed", bottom: "16px", right: "16px" }}
                  onClick={handleAddItemClick}
                >
                  <PostAddOutlinedIcon />
                  Add Item
                </Fab>
              </Hidden>

              <Hidden mdDown>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  // sx={{backgroundColor: theme.palette.custom.red}}
                  onClick={handleAddItemClick}
                  startIcon={<PostAddOutlinedIcon />}
                  sx={{ marginLeft: "15px" }}
                >
                  Add Item
                </Button>
              </Hidden>
            </Toolbar>
          </Box>
        </Box>
        <Divider />

        {/*/////////////// Item List ////////////////*/}

        {filteredItems && filteredItems.length > 0 && (
          <>
            {filteredItems.map((item, index) => {
              const featuredImage =
                item.images?.find((image) => image.featured) ||
                item.images?.[0];
                const shouldShowRemoveButton = item.listId && item.listId.includes(selectedListId);
              return (
                <List disablePadding={true} key={item.id}>
                  <div key={item.id} ref={(el) => itemRefs.set(item.id, el)}>
                    <Debug>{item.id}</Debug>

                    <ListItem
                      divider={index !== locationItems.length - 1}
                      sx={{
                        flexDirection: "row",
                        backgroundColor:
                          item.id === highlightedItemId
                            ? theme.palette.custom.purple
                            : "",
                        ...(item.featured && {
                          backgroundColor: theme.palette.custom.purple,
                        }),
                      }}
                    >
                      <Checkbox
                        edge="start"
                        checked={selectedItems.indexOf(item.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": `checkbox-list-label-${index}`,
                        }}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setTimeout(
                              () =>
                                setSelectedItems([...selectedItems, item.id]),
                              200
                            );
                          } else {
                            setTimeout(
                              () =>
                                setSelectedItems(
                                  selectedItems.filter(
                                    (itemId) => itemId !== item.id
                                  )
                                ),
                              200
                            );
                          }
                        }}
                      />

                      <ListItemAvatar
                        onClick={() => setUpdatingItemId(item.id)}
                      >
                        <SquareAvatar
                          alt="item image"
                          size={50}
                          src={featuredImage?.thumbnail}
                        />
                      </ListItemAvatar>

                      <ListItemText
                        primary={item.name}
                        onClick={() => setUpdatingItemId(item.id)}
                        secondary={
                          <ItemLocationName locationId={item.locationId} />
                        }
                        primaryTypographyProps={{
                          noWrap: true,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        sx={{
                          cursor: "pointer",
                          paddingLeft: "15px",
                          paddingRight: { xs: "default", sm: "30px" },
                        }}
                      />

                      {/* <PublishItem item={item} /> */}

                      <Debug>
                        <ListItemText>{item.id}</ListItemText>
                      </Debug>

                      {/* <ListItemText>{item.location}</ListItemText> */}

                      <ListItemSecondaryAction>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            edge="end"
                            aria-label="update"
                            onClick={() => setUpdatingItemId(item.id)}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>

                          <Box sx={{ display: { xs: "none", sm: "inherit" } }}>
                            {spice === "inventory" && (
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDeleteItem(item.id)}
                                size="large"
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}

                            {/* {spice === "list" && (
                              <Tooltip title="Remove Item from List">
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => handleRemoveItem(item.id)}
                                  size="large"
                                >
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            )} */}

{shouldShowRemoveButton && (
            <Tooltip title={`Remove Item from List`}>
              <IconButton
                edge="end"
                aria-label="remove from list"
                onClick={() => handleRemoveItem(item.id, selectedListId)}
                size="large"
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          )}


                          </Box>
                        </Box>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <Collapse in={isExpanded} timeout={500}>
                      <div>
                        {isExpanded && (
                          <ItemAdvancedDetails
                            item={item}
                            visible={isExpanded}
                          />
                        )}
                      </div>
                    </Collapse>
                  </div>
                </List>
              );
            })}
          </>
        )}
        {/* </> )} */}

        <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
          <DialogTitle>{"Item Limit Exceeded"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have exceeded your item limit. Upgrade to add more items.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertOpen(false)} color="primary">
              Dismiss
            </Button>
            {/* Optionally, add a button to navigate to the upgrade page */}
            <Box mr={2}>
              <Button
                component={Link}
                to={linkPath}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                Upgrade
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        {creatingItem && (
          <EditItemModal
            userId={auth.user.uid}
            onSnackbarMessage={handleSnackbarMessage}
            onDone={() => {
              setCreatingItem(false);
              //history.push(`/location/${locationId}`);
            }}
            showName={showName}
            currentLocationId={locationId}
            items={items}
            locations={locations}
            onSelect={onSelect}
            showDescription={showDescription}
            showPrice={showPrice}
            showQuantity={showQuantity}
            showCategory={showCategory}
            // onSave={(updatedItem) => {
            //   updateItem(auth.user.uid, updatedItem);
            //   setCreatingItem(false);
            // }}
            selectedListId={selectedListId}
            // onCancel={() => {
            //   setCreatingItem(false);

            // }}
          />
        )}

        {updatingItemId && (
          <EditItemModal
            userId={auth.user.uid}
            onSnackbarMessage={handleSnackbarMessage}
            id={updatingItemId}
            items={items}
            selectedListId={selectedListId}
            currentLocationId={locationId}
            flavour="editing"
            locations={locations}
            onSelect={onSelect}
            onDone={() => {
              onItemUpdate(updatingItemId);

              // history.push(`/location/${item.locationId}`); /// this is to reset the location in the url.
              setUpdatingItemId(null);
              // setItemId(null);
            }}
            name={items?.find((item) => item.id === updatingItemId)?.name}
            description={
              items?.find((item) => item.id === updatingItemId)?.description
            }
            price={items?.find((item) => item.id === updatingItemId)?.price}
            quantity={
              items?.find((item) => item.id === updatingItemId)?.quantity
            }
            category={
              items?.find((item) => item.id === updatingItemId)?.category
            }
            // onSave={(updatedItem) => {
            //   updateItem(updatingItemId, updatedItem);
            //   setUpdatingItemId(null);
            // }}
            // onCancel={() => setUpdatingItemId(null)}
          />
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Alert onClose={handleClose} severity={snackbarType}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}

export default DashboardItems;
