import React from "react";
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
// import QrCodePrintCopy from "./QrCodePrintCopy";
import { useItem } from "../util/db";

const baseURL = "https://wheresmything.com/item/";

function ucwords(string) {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function ItemAdvancedDetails({ item }) {

  const ignoreFields = ["owner", "locationId", "images", "createdAt", "id", "auctionId", "updatedAt", "name"];

  const filteredData = Object.entries(item)
    .filter(([key]) => !ignoreFields.includes(key))
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  console.log("filteredData", filteredData);

  return (
    
    <Card variant="none" style={{background:'#f7f7f7'}}>
      <CardContent>
        <Grid container>
          <List dense={true}>
            {Object.entries(filteredData).map(([field, value]) => (
              <ListItem key={field}>
                <ListItemText
                  primary={ucwords(field)}
                  secondary={
                    typeof value === "object" ? null : value
                  }
                />
              </ListItem>
            ))}
          </List>

        </Grid>
      </CardContent>
    </Card>
  );
}

export default ItemAdvancedDetails;
