import { useEffect, useState, useRef } from 'react';
import { getListsByOwner } from "./db";
import { useLocation } from "../util/db";


export const findLocationPath = (locationId, locations, path = []) => {
  // Ensure locations is loaded
  if (!locations) {
    return null;
  }

  const location = locations.find(loc => loc.id === locationId);

  if (!location) {
    return null;
  }

  const newPath = [...path, location];

  if (location.parentLocationId === null) {
    // Return the path array in reverse order
    return newPath.reverse();
  }

  return findLocationPath(location.parentLocationId, locations, newPath);
};
  

export const fetchListsAndSetSelectedList = async (userId, itemData, setLists, setSelectedListId, setLoading) => {
  try {
    const data = await getListsByOwner(userId);
    console.log("Fetched Data lists:", data); // Log the fetched data
    setLists(data);

 
    const selectedListIdFromFirebase = itemData.listId; // Fetch from Firebase
    
    setSelectedListId(selectedListIdFromFirebase); 
  } catch (error) {
    console.error("Error fetching lists:", error); // Log the error
  } finally {
    setLoading(false);
  }
};


export const fetchLists = async (userId, itemData, setLoading, setLists) => {
  try {
    const data = await getListsByOwner(userId);
    console.log("Fetched Data lists:", data);
    setLists(data);
  } catch (error) {
    console.error("Error fetching lists:", error);
  } finally {
    setLoading(false);
  }
};

  
  // export const calculateTotalPrice = (items) => {
  //   if (items && items.length === 0) {
  //     return 0.0;
  //   }
  
  //   return items.reduce((total, item) => {
  //     if (item && item.price == null) {
  //       return total;
  //     }
  
  //     return total + parseFloat(item.price || 0);
  //   }, 0);
  // };
  
  export const calculateTotalPrice = (items) => {
    if (!items || !Array.isArray(items) || items.length === 0) {
      return 0.0;
    }
  
    return items.reduce((total, item) => {
      if (
        !item ||
        typeof item.totalValue === 'undefined' ||
        item.totalValue === null ||
        item.totalValue < 0
      ) {
        return total;
      }
  
      const parsedPrice = parseFloat(item.totalValue);
  
      if (isNaN(parsedPrice)) {
        return total;
      }
  
      return total + parsedPrice;
    }, 0);
  };
  
  export const countTotalItems = (items) => {
    if (!items || !Array.isArray(items)) {
      return 0;
    }
  
    return items.length;
  };
  
  export function getLatestItem(items) {

    if (items.length === 0) {
      return null;
    }

    return items.reduce((latestItem, currentItem) => {
        // Convert Firebase Timestamp to milliseconds for comparison
        const currentItemTimestamp = currentItem.createdAt.seconds * 1000 + currentItem.createdAt.nanoseconds / 1000000;
        const latestItemTimestamp = latestItem.createdAt.seconds * 1000 + latestItem.createdAt.nanoseconds / 1000000;

        // If currentItem is more recent, return it, else return the latestItem
        return currentItemTimestamp > latestItemTimestamp ? currentItem : latestItem;
    });
}

export function getLatestLocation(locations) {

  if (locations.length === 0) {
    return null;
  }

  return locations.reduce((latestLocation, currentLocation) => {
      // Convert Firebase Timestamp to milliseconds for comparison
      const currentLocationTimestamp = currentLocation.createdAt.seconds * 1000 + currentLocation.createdAt.nanoseconds / 1000000;
      const latestLocationTimestamp = latestLocation.createdAt.seconds * 1000 + latestLocation.createdAt.nanoseconds / 1000000;

      // If currentItem is more recent, return it, else return the latestItem
      return currentLocationTimestamp > latestLocationTimestamp ? currentLocation : latestLocation;
  });
}

export function isNestedChild(targetLocationId, locationId, locations) {
  // If the target location id is null, return false
  if (!targetLocationId) {
    return false;
  }

  const location = locations.find((loc) => loc.id === targetLocationId);

  // If the target location is not found in the locations array, return false
  if (!location) {
    return false;
  }

  // If the target location's parentLocationId is the locationId, return true
  if (location.parentLocationId === locationId) {
    return true;
  }

  // If the target location has no parent, it cannot be a child of the location, so return false
  if (!location.parentLocationId) {
    return false;
  }

  // Recursively check if the target location's parent is a child of the location
  return isNestedChild(location.parentLocationId, locationId, locations);
}


export function getNestedChildrenVariant(locations, parentId) {
    const stack = [null];
    const result = [];
    const map = locations.reduce((acc, loc) => {
      acc[loc.id] = { ...loc, children: [] };
      return acc;
    }, {});
    Object.values(map).forEach((loc) => {
      if (loc.parentLocationId) {
        map[loc.parentLocationId].children.push(loc);
      } else {
        stack.push(loc);
      }
    });
    while (stack.length > 1) {
      const currentLocation = stack.pop();
      if (
        currentLocation.id === parentId ||
        currentLocation.parentLocationId === parentId
      ) {
        result.push(currentLocation);
      }
      currentLocation.children.forEach((child) => stack.push(child));
    }
    return result;
  }


  export function getNestedChildren(locations, parentId, visited = new Set()) {
    if (visited.has(parentId)) {
      console.warn(
        `Circular reference detected: ${parentId} is already visited.`
      );
      return [];
    }

    visited.add(parentId);

    const parent = locations?.find((location) => location.id === parentId);
    const children = locations?.filter(
      (location) => location.parentLocationId === parentId
    );

    const nestedChildren = children?.flatMap((child) =>
      getNestedChildren(locations, child.id, new Set(visited))
    );

    const allLocations = parent
      ? [parent, ...children, ...nestedChildren]
      : [...children, ...nestedChildren];

    return allLocations.reduce((uniqueLocations, location) => {
      if (
        !uniqueLocations.some(
          (uniqueLocation) => uniqueLocation.id === location.id
        )
      ) {
        uniqueLocations.push(location);
      }
      return uniqueLocations;
    }, []);
  }





  export function useClipboard() {
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((error) => {
          console.error('Error copying to clipboard:', error);
        });
    };
  
    return {
      copied,
      copyToClipboard,
    };
  }


  export function usePlanChecker(stripePriceId, stripeSubscriptionStatus) {
    const [userPlan, setUserPlan] = useState(null); // 'basic', 'advanced', 'genie', or null
  
    useEffect(() => {
      // Check the subscription status first
      if (stripeSubscriptionStatus !== 'active') {
        setUserPlan('basic'); // Not an active subscriber
        return;
      }
  
      if (!stripePriceId) {
        setUserPlan('basic'); // User is on the basic plan if no stripePriceId is present
      } else if (stripePriceId === process.env.REACT_APP_STRIPE_PRICE_ADVANCED) {
        setUserPlan('advanced');
      } else if (stripePriceId === process.env.REACT_APP_STRIPE_PRICE_GENIE) {
        setUserPlan('genie');
      } else {
        setUserPlan(null); // For safety in case of unexpected stripePriceIds
      }
    }, [stripePriceId, stripeSubscriptionStatus]);
  
    return userPlan;
  }

  export function useHasExceededItems(itemCount, userPlan) {
    const [hasExceeded, setHasExceeded] = useState(false);
  
    useEffect(() => {
      // Here, define your restrictions. For example:
      const planRestrictions = {
        basic: 100,
        advanced: 1000,
        genie: 10000,
      };
  
      if ((itemCount + 1) > (planRestrictions[userPlan] || 0)) {
        setHasExceeded(true);
      } else {
        setHasExceeded(false);
      }
    }, [itemCount, userPlan]);
  
    return hasExceeded;
  }
  
  export function useHasExceededImages(imageCount, userPlan) {
    const [hasExceededImages, setHasExceededImages] = useState(false);
  
    useEffect(() => {
      // Define the image restrictions for each plan
      const planImageRestrictions = {
        basic: 1,
        advanced: 5,
        genie: 10,
      };
  
      // Check if the number of images exceeds the allowed limit for the user's plan
      if ((imageCount + 1) > (planImageRestrictions[userPlan] || 0)) {
        setHasExceededImages(true);
      } else {
        setHasExceededImages(false);
      }
    }, [imageCount, userPlan]);
  
    return hasExceededImages;
}


export function getImageUploadLimitMessage(userPlan) {
  const planImageRestrictions = {
    basic: 1,
    advanced: 5,
    genie: 10,
  };

  const limit = planImageRestrictions[userPlan] || 0;
  let message = `You can only upload ${limit} image${limit !== 1 ? 's' : ''} on the ${userPlan} plan.`;

  // Add the upgrade prompt for basic and advanced plans
  if (userPlan === "basic" || userPlan === "advanced") {
    message += " Upgrade to add more images.";
  }

  return message;
}


