import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import qrpage from "./../images/qrpage.png";
import customfield from "./../images/customfield.png"
import spreadsheet2 from "./../images/spreadsheet2.png"
import value from "./../images/value.png"
import locations from "./../images/locations.png"
import itemgeniescreen from "./../images/itemgeniescreen.gif"
import animatedlist from "./../images/animatedlist.jpg"
function FeaturesSection3(props) {


  
  const items = [
    {
      title: "Organize it Your Way",
      description:
        "Organize your items by place, container, box, or any way that works for you. Add as many locations within locations as you want. You have the freedom to create nested locations endlessly. So if you have a box containing a bag, with an envelope inside that bag, our platform lets you catalog it precisely that way.",
      image: locations,
    },
    {
      title: "Complete Flexibility",
      description:
        "You probably have little details that are unique to your items. You can easily add as many custom fields as you want to your items, so you can keep track of the details that are important to your needs.",
      image: customfield,
    },
    {
      title: "It's Your Data",
      description:
        "No strings attached here. Right from the start, we equip you with a ready-to-use online editable spreadsheet, so all your data can be downloaded at your convenience.",
      image: spreadsheet2,
    },
    {
      title: "Create Public Lists",
      description:
        "Take items from your world and share them with the world. Create a list and simply add your existing items, or new items to the list. Select the fields you wish to feature on your list. Click publish. And share the unique URL with anyone or everyone. Great for sharing your items with 3rd parties, for anything from online yard sales, to insurance claims.",
      image: animatedlist,
    },
    {
      title: "QR Labels",
      description:
        "Print QR Labels to easily identify the contents of any container, box, or storage unit. When you stumble upon that elusive box in the basement, just scan it to instantly know what's inside. If you already have QR Codes or Barcodes you can use those as well.",
      image: qrpage,
    },
    {
      title: "Reorganize Simply",
      description:
        "Just like you would move a box to a different place in real life, you can move your locations to other locations. Don't worry though, unlike a real move, all our boxes and fully taped up, and all the items will stay in the box and move with it as well.",
      image: itemgeniescreen,
    },
    {
      title: "Total Value",
      description:
        "For insurance purposes as well as just general awareness the total value is available as well as the date the item was originally created.",
      image: value,
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          sx={{ textAlign: "center" }}
        />
        <Container maxWidth="md" disableGutters={true} sx={{ mt: 7 }}>
          {items.map((item, index) => (
            <Grid
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
              sx={{
                // Reverse every other row
                "&:nth-of-type(even)": {
                  flexDirection: "row-reverse",
                },
                // Spacing between rows
                "&:not(:last-child)": {
                  mb: 3,
                },
              }}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  sx={{
                    textAlign: {
                      xs: "left",
                      md: "left",
                    },
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Box
                  component="figure"
                  sx={{
                    // maxWidth: "300px",
                    margin: "30px auto",
                  }}
                >
                  <Box
                    component="img"
                    src={item.image}
                    alt={item.title}
                    sx={{
                      height: "auto",
                      maxWidth: "100%",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection3;
