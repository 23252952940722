import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import Chip from "@mui/material/Chip";
import { styled, keyframes } from "@mui/system";

// Define the shimmer animation
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

// Style the Chip with shimmer effect
const ShimmerChip = styled(Chip)({
  background: 'linear-gradient(90deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.1) 75%)',
  color: 'white', // You can adjust this
  backgroundSize: '1000px 100%',
  animation: `${shimmer} 4s infinite`,
  backgroundColor: '#3f51b5', // Adjust this color as per your requirements
});

function MostPopularBadge() {
    return <ShimmerChip label="Most Popular" sx={{backgroundColor:'#e91e63'}}/>;
}

function PricingSection(props) {
  const auth = useAuth();

  const items = [
    {
      "id": "basic",
      "name": "Basic (free)",
      "price": "0",
      "sub": "Best for Personal Use",
      "perks": [
     
          "100 Items",
          "Unlimited Locations",
          "Unlimited Custom Fields",
          "Public List Publishing (with ads)",
          "QR and Label Generation",
          "Download Spreadsheet / Data",
          "1 High-Resolution Image per Item",
      ],
  },
  {
      "id": "advanced",
      "name": "Advanced",
      "price": "12",
      "sub": "Best for Home Inventory and Small Businesses",
      "perks": [
    
          "1000 items",
          "Unlimited Locations",
          "Unlimited Custom Fields",
          "Public List Publishing (without ads)",
          "QR and Label Generation",
          "5 High-Resolution Images per Item",
          "Cancel at Anytime and Download Spreadsheet / Data",
          "Priority Support"
      ],
  },
  {
      "id": "genie",
      "name": "Genie",
      "price": "29",
      "sub": "Best for Medium Sized Businesses and Communities",
      "perks": [
       
          "10000 items",
          "Unlimited Locations",
          "Unlimited Custom Fields",
          "Public List Publishing (without ads)",
          "QR and Label Generation",
          "10 High-Resolution Images per Item",
          "Cancel at Anytime and Download Spreadsheet / Data",
          "Ad Creation/Manager (coming soon)",
          "Priority Support"
      ],
  },

  ];

  

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          sx={{ textAlign: "center" }}
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  border: item.id === "advanced" ? "3px solid #e91e63" : "1px solid #e0e0e0"
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    p: 3,
                  
                  }}
                >
                       
                  <Typography variant="h5" component="h2" gutterBottom>
                  <Box sx={{ mt: 1, display: "flex", justifyContent:"space-between" }}>
                   <b>{item.name}</b> 
                    {item.id === "advanced" && <MostPopularBadge  />} 
                    </Box>
                  </Typography>

<Box sx={{minHeight:'50px'}}>
<Typography>
                  {item.sub}
                  </Typography>
                  </Box>
                  <Box sx={{ mt: 1, display: "flex", alignItems: "baseline" }}>
                    <Typography variant="h4">${item.price} </Typography>
                    <Typography variant="h5" color="textSecondary">
                     /mo
                    </Typography>
                  </Box>

                  {item.description && (
                    <Typography
                      component="p"
                      color="textSecondary"
                      sx={{ mt: 2 }}
                    >
                      {item.description}
                    </Typography>
                  )}

                  {item.perks && (
                    <List aria-label="perks" sx={{ mt: 1 }}>
                      {item.perks.map((perk, index) => (
                        <ListItem
                          disableGutters={true}
                          key={index}
                          sx={{ py: "2px" }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "34px",
                              color: "success.main",
                            }}
                          >
                            <CheckIcon />
                          </ListItemIcon>
                          <ListItemText>{perk}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  )}

                  <Box sx={{ mt: "auto", pt: 3 }}>
                  
                  {item.id === "basic" ? (
<>
<Button
variant="outlined"
component={Link}
to={
  auth.user
    ? `/inventory`
    : `/auth/signup`
}

color="primary"
size="large"
fullWidth={true}
>


Continue (no credit card required)


</Button>


</>

                  ) :
                   
                   (
                 
                   
                   <Button
                      component={Link}
                      to={
                        auth.user
                          ? `/purchase/${item.id}`
                          : `/auth/signup?next=/purchase/${item.id}`
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={true}
                    >
                      
                      Get Started
                    </Button>
)} 

                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="subtitle2" color="textSecondary">
<p> <sup>*</sup>Unlimited is subject to ItemGenie's fair use policy. See terms and conditions.</p>
</Typography>
      </Container>
    </Section>
  );
}

export default PricingSection;
