import React from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Section from "./Section";
import { Link } from "./../util/router";

function Announcement(props) {
  const {
    onClose,
    bgColor,
    textColor,
    text,
    buttonColor,
    buttonPath,
    buttonText,
    ...otherProps
  } = props;

  return (
    <Section bgColor={bgColor} size="auto" {...otherProps}>
      <Alert
        icon={false}
        onClose={onClose}
        sx={{
          // Let parent <Section> handle colors
          backgroundColor: "transparent",
          color: "inherit",
          // Customize message element
          "& .MuiAlert-message": {
            width: "100%",
            textAlign: "center",
          },
        }}
      >
        {text}
        <Button
          component={Link}
          to={buttonPath}
          variant="contained"
          color={buttonColor}
          size="medium"
          sx={{ ml: 2 }}
        >
          {buttonText}
        </Button>
      </Alert>
    </Section>
  );
}

export default Announcement;
