import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useFetchUser, useItemsByOwner, useUserCurrency } from "../util/db";
import { useAuth } from "../util/auth";
import { calculateTotalPrice, countTotalItems, formatPriceCurrency } from "../util/functions";
import { requireAuth } from "../util/auth";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  largeNumber: {
    fontSize: '2rem',
    fontWeight: 800,
    textAlign: 'left',
    // color:'white'
  },
  title: {
    fontSize: '1.25rem',
    textAlign: 'left',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    fontWeight:600,
    color:'grey'
  }
}));


function ContentCardsSection(props) {
  const auth = useAuth();
  const classes = useStyles();
  const { data: items, isLoading: itemsLoading, error: itemsError } = useItemsByOwner(auth.user.uid);
  const { user, loading: userLoading, error: userError } = useFetchUser(auth.user.uid);
  // const formattedPrice = formatPriceCurrency(item.price, currencySymbol);
  const totalPrice = calculateTotalPrice(items);
 
  const currencySymbol = useUserCurrency();

  if (itemsLoading || userLoading) {
    return <div></div>;  // Simplified loading logic
  }

  if (itemsError || userError) {
    return <div>Error fetching data. {itemsError?.message || userError?.message}</div>;  // Handling errors from both user and items fetch
  }

  const content = [
    {
      image: "https://source.unsplash.com/aHrxrT1q2h0/800x600",
      title: "Total Value of Items",
      body: <> {currencySymbol}{totalPrice.toFixed(2)}</>,
      // bgColor: "#ecc0b4"
      // url: "/post/golden-gate",
    },
    {
      image: "https://source.unsplash.com/BkmdKnuAZtw/800x600",
      title: "Total Number of Items",
      // body: <>{totalItems.toFixed(0)}</>,
      body: <>{user?.itemCount}</>,
      // bgColor: "#3fcb90"
      // url: "/post/beach",
    },
    {
      image: "https://source.unsplash.com/HXJkqHexaak/800x600",
      title: "(coming soon)",
      body: "Custom Dashboard Tiles",
      // bgColor: "#9b77d5"
      // url: "/post/road",
    },
    // {
    //   image: "https://source.unsplash.com/eOcyhe5-9sQ/800x600",
    //   title: "Faucibus turpis in",
    //   body: "Non blandit",
    //   bgColor: "#ffb061"
    //   // url: "/post/ballons",
    // },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={6}
          sx={{ textAlign: "left" }}
        />
        <Grid container={true} justifyContent="center" spacing={0}>
          {content.map((content, index) => (
            <Grid item={true} xs={12} key={index}>
              
              <Card elevation={0} sx={{ backgroundColor: content.bgColor, borderBottom:'1px solid grey', borderRadius:'0px' }} >
      <CardContent>
            <Typography className={classes.largeNumber}>
      {content.body}
        </Typography>
   
        <Typography variant="h6"className={classes.title}>
         {content.title}
       </Typography>
      </CardContent>
    </Card>



              
              
              
              
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default requireAuth(ContentCardsSection);
