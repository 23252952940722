import React from "react";
import Meta from "./../components/Meta";
import AuthSection2 from "./../components/AuthSection2";
// import AuthSection3 from "./../components/AuthSection3";
// import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";

function AuthPage(props) {
  const router = useRouter();
  console.log("router query next", router.query.next)

  return (
    <>
      <Meta title="Auth" />
      <AuthSection2
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={["google"]}
        // afterAuthPath={router.query.next}
        afterAuthPath={router.query.next || "/pricing"}
      />
      {/* <AuthSection3
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={["google", "facebook", "twitter"]}
        afterAuthPath={router.query.next || "/items"}
      />
      <AuthSection
        bgColor="primary"
        size="medium"
        bgImage="https://source.unsplash.com/RnCPiXixooY"
        bgImageOpacity={0.8}
        type={router.query.type}
        providers={["google", "facebook", "twitter"]}
        afterAuthPath={router.query.next || "/items"}
      /> */}
    </>
  );
}

export default AuthPage;