import React from 'react';
import {
  useItemsByOwner,
} from "../util/db";
import { countTotalItems } from '../util/functions';
import { useAuth } from '../util/auth';
import { requireAuth } from "../util/auth";



function TotalQuantityItems({ props }) {
  const auth = useAuth();

  // const { data: items, status: itemsStatus } = useItemsByOwner(auth.user.uid);
  // const { data, isLoading, isError } = useItemsByOwner(auth.user.uid);
  const { data: items, isLoading, error } = useItemsByOwner(auth.user.uid);


  console.log("auth uid", auth.user.id)
 
  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error fetching items. {error.message}</div>;
  }

  const totalItems = countTotalItems(items);

  return (
    <div>
      <h2>Total Number of Items</h2>
      <p>{totalItems.toFixed(0)}</p>
    </div>
  );
}

export default requireAuth(TotalQuantityItems);
