import { Box, Link, Typography, Breadcrumbs } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumbItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    // textOverflow: "ellipsis",
    width: "100px", // Change this value to fit your design
    // display: "inline-block",
  },

  lastbreadcrumbItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "calc(90vw)",
    // Change this value to fit your design
    // display: "inline-block",
  },
}));

const LocationBreadcrumbs = ({
  locationPath,
  handleSelect,
  selectedLocationId,
}) => {
  const classes = useStyles();

  return (
    <Box style={{width:'100%', height:'50px'}} >
      <Box >
        {selectedLocationId == null && (
          <>
           <Typography variant='subtitle2' gutterBottom={false} color="textSecondary" component="span">
           Selected:{" "}<br/>
          </Typography>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              component="span"
            >
              All Items
            </Typography>
          </>
        )}

        {selectedLocationId && (
          <Breadcrumbs maxItems={3} aria-label="breadcrumb">
           {locationPath && Array.isArray(locationPath) && locationPath.map((location, index) => {
                const isLastBreadcrumb = index === locationPath.length - 1;
                return (
                  <Box key={location.id}>
                     {index === 0 && (
          <Typography variant='subtitle2' color="textSecondary" component="span">
            Selected Location:{" "}
          </Typography>
        )}
                    {isLastBreadcrumb ? (
                      <div className={classes.lastbreadcrumbItem}>
                        <Typography variant="subtitle2" color="textPrimary" component="span">
                          {location.name}
                        </Typography>
                      </div>
                    ) : (
                      <Link
                        color="inherit"
                        underline="hover"
                        onClick={() => handleSelect(location.id)}
                      >
                        {location.name}
                      </Link>
                    )}
                  </Box>
                );
              })}
          </Breadcrumbs>
        )}
      </Box>
    </Box>
  );
};

export default LocationBreadcrumbs;
