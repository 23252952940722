import React, { useState } from "react";
import { useRouter } from "../util/router";
import { useList, usePublicItemsByListId } from "./../util/db";

import {
  List,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Container,
  Divider,
  Box,
  Button,
} from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ImageCarousel from "../components/ImageCarousel";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

function capitalizeWords(string) {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function booleanToText(value) {
  console.log("Value passed to booleanToText:", value);
  if (!value || value === "false") return "No";
  if (value === "true") return "Yes";
  return value;
}

function PublicListPage(props) {
  const router = useRouter();

  // const listId = router.params.listId;
  const listnameListid = router.params.listId || "default-not-found-id";
  const splitData = listnameListid.split("-");
  const listId = splitData[splitData.length - 1];

  console.log("listname id", listId);

  const { listData, listStatus } = useList(listId);

  const { items, status: itemsStatus } = usePublicItemsByListId(listId);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);

  const handleOpen = (images) => {
    setCurrentImages(images);
    setCarouselOpen(true);
  };

  const handleClose = () => {
    setCarouselOpen(false);
    setCurrentImages([]);
  };
  console.log("list items", items);

  console.log("list data", listStatus);

  // Avoid flashing a not found/error message before data is fully loaded
  if (
    listStatus === "idle" ||
    listStatus === "loading" ||
    itemsStatus === "loading"
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Handle potential error or not found cases
  if (listStatus === "error" || !listData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <Typography variant="h4">List Not Found </Typography>
      </Box>
    );
  }

  if (listData && !listData.published) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh" // 100% of the viewport height
      >
        <Typography variant="h4">List Not Published</Typography>
      </Box>
    );
  }

  // If there are no items to display
  if (items.length === 0 && itemsStatus === "success") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <Typography variant="h4">No Items Found in This List</Typography>
      </Box>
    );
  }

  return (
    <div>
      <Container>
        <Box py={2}>
          <Typography variant="h5">{listData?.name}</Typography>
          <Typography variant="h6" gutterBottom>
            {listData?.description}
          </Typography>
          <Typography variant="subititle1">
            {listData?.location && (
              <> Contact: Location: {listData?.location}</>
            )}
          </Typography>
          <Typography variant="subtitle1">
            {listData?.contactDetails && (
              <> Contact: {listData?.contactDetails}</>
            )}
          </Typography>
          <br />
          <Divider />
        </Box>

        {itemsStatus === "success" && (
          <List>
            {items &&
              items.map((item) => {
                const featuredImage =
                  item.images?.find((image) => image.featured) ||
                  item.images?.[0];

                return (
                  <React.Fragment key={item.id}>
               
                    <ListItem key={item.id}>
                      <Box style={{ textAlign: "center", marginRight: 16 }}>
                        <Box
                          sx={{
                            position: "relative",
                            cursor:
                              item?.images?.length > 0 ? "pointer" : "default",
                          }}
                          onClick={
                            item?.images?.length > 0
                              ? () => handleOpen(item.images)
                              : null
                          }
                        >
                          <Avatar
                            src={featuredImage?.thumbnail}
                            style={{
                              width: 75,
                              height: 75,
                              borderRadius: 0,
                              marginBottom: 8,
                            }}
                          >
                            {!item?.images?.[0] && <ImageOutlinedIcon />}
                          </Avatar>

                          {item?.images?.length > 1 && (
                            <Button
                              variant="text"
                              onClick={() => handleOpen(item?.images)}
                              sx={{
                                paddingBottom: "0px",

                                position: "absolute",
                                width: "100%",
                                bottom: 0, // Position at the bottom of the Avatar
                                // left: '50%', // Center horizontally
                                transform: "translateX(-50%)", // Center horizontally
                              }}
                            >
                              <MoreHorizOutlinedIcon
                                fontSize="large"
                                sx={{ background: "white", opacity: "0.9" }}
                              />
                            </Button>
                          )}
                        </Box>
                      </Box>

                      <ListItemText
                        primary={item?.name}
                        secondary={listData?.selectedFields
                          .filter(
                            (field) => field !== "images" && field !== "name"
                          )
                          .map((field) => (
                            <Typography key={field} variant="body2">
                              {capitalizeWords(field)}:{" "}
                              {item[field] === "true" ||
                              item[field] === "false" ||
                              !item[field]
                                ? booleanToText(item[field])
                                : item[field]}
                            </Typography>
                          ))}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
          </List>
        )}
        <ImageCarousel
          images={currentImages}
          open={carouselOpen}
          onClose={handleClose}
        />
      </Container>
    </div>
  );
}

export default PublicListPage;
