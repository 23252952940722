import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function HeroSection5(props) {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isXSSize, setIsXSSize] = useState(window.innerWidth <= 600); // Assuming 600px is the breakpoint for xs

  useEffect(() => {

    const handleResize = () => {
      setIsXSSize(window.innerWidth <= 600);
    };

    const handleScroll = () => {
      if (isXSSize) {
        setScrollPosition(window.scrollY);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isXSSize]);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
   <Container>
        <Grid container={true} alignItems="center" spacing={6}>
      
          <Grid container={true} item={true} direction="column" xs={12}>
            <Box sx={{ textAlign: { xs: "center"} }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
                subtitleSize={6}
              />
       
            </Box>
          
          </Grid>
         
         
        </Grid>
        </Container>
    </Section>
  );
}

export default HeroSection5;
