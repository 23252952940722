import React from "react";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import { Link } from "./../util/router";

function AuthFooter(props) {

  const appendAfterAuthPath = (path) => {
    if (props.afterAuthPath) {
      return `${path}?next=${props.afterAuthPath}`;
    }
    return path;
  };

  return (
    <Box
      sx={{
        fontSize: "0.9rem",
        textAlign: "center",
        mt: 3,
        px: 2,
      }}
    >
      {props.type === "signup" && (
        <>
          

         <b>{props.signinText}

          <MuiLink component={Link} to={appendAfterAuthPath(props.signinPath)} sx={{ ml: 1 }}>
            {props.signinAction}
          </MuiLink>
          </b> 


          {props.showAgreement && (
            <Box sx={{ mt: 2 }}>
              By signing up, you are agreeing to our{" "}
              <MuiLink component={Link} to={props.termsPath}>
                Terms of Service
              </MuiLink>{" "}
              and{" "}
              <MuiLink component={Link} to={props.privacyPolicyPath}>
                Privacy Policy
              </MuiLink>
              .
            </Box>
          )}
        </>
      )}

     {props.type === "signin" && (
        <>
          <MuiLink component={Link} to={appendAfterAuthPath(props.signupPath)}>
            {props.signupAction}
          </MuiLink>

          {props.forgotPassAction && (
            <>
              <MuiLink
                component={Link}
                to={props.forgotPassPath}
                sx={{ ml: 2 }}
              >
                {props.forgotPassAction}
              </MuiLink>
            </>
          )}
        </>
      )}

      {props.type === "forgotpass" && (
        <>
      {props.signinText}
          <MuiLink component={Link} to={appendAfterAuthPath(props.signinPath)} sx={{ ml: 1 }}>
            {props.signinAction}
          </MuiLink>
        </>
      )}
    </Box>
  );
}

export default AuthFooter;
