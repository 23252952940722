import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import discord from "./../images/discord.svg"

function HeroSection4(props) {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isXSSize, setIsXSSize] = useState(window.innerWidth <= 600); // Assuming 600px is the breakpoint for xs

  useEffect(() => {

    const handleResize = () => {
      setIsXSSize(window.innerWidth <= 600);
    };

    const handleScroll = () => {
      if (isXSSize) {
        setScrollPosition(window.scrollY);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isXSSize]);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
   <Container>
        <Grid container={true} alignItems="center" spacing={6}>
      
          <Grid container={true} item={true} direction="column" xs={12} md={4}>
            <Box sx={{ textAlign: { xs: "left", md: "left" } }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
                subtitleSize={6}
              />
         <Button
      component="a"
      href="https://discord.gg/CgU3AWFmtb"
      target="_blank"
      rel="noopener noreferrer"
      variant="contained"
      size="medium"
      color={props.buttonColor}
    >
    <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="subtitle1">
            {props.buttonText} 
        </Typography>
        &nbsp; &nbsp;  <img src={discord} height={25} />
    </Box>
</Button>
            </Box>
          
          </Grid>
         
          <Grid item={true} xs={12} md={true} sx={{overflowX: 'hidden',}}>
          <Box
          component="img"
          src={props.image}
          alt="illustration"
          sx={{
            margin: "0 auto",
            display: "block",
            height: "auto",
            width: "200%"
            
          }}
        />
      </Grid>
        </Grid>
        </Container>
    </Section>
  );
}

export default HeroSection4;
