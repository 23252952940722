import React from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function HeroSection2(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container sx={{ textAlign: "center" }}>
        <SectionHeader title={<span> <b> {props.title} </b> </span>} subtitle={props.subtitle} size={3} subtitleSize={6} />
        <Button
    component={Link}
    to={props.buttonPath}
    variant="contained"
    size="large"
    color={props.buttonColor}
>
    <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6">
            {props.buttonText} 
        </Typography>
        &nbsp; <ArrowForwardIcon />
    </Box>
</Button>
        <Box py={2}>
        <Typography color="textSecondary">No Credit Card Required</Typography>
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection2;
