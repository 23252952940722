import React, { useState, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import Select from "react-select/creatable";

import {
  useLocationsByOwner,
  useLocation,
  updateLocation,
  createLocation,
} from "./../util/db";
import { makeStyles } from "@mui/styles";
// import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LocationRow from "./LocationRow";
import {
  FormControl,
  InputLabel,
  IconButton,
  FormHelperText,
  Typography
} from "@mui/material";
import Debug from "./Debug";
import QRCode from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";

function SelectLocation(props) {
  const {
    allLocations,
    handleParentLocationChange,
    parentLocationId,
    selectedLocationId,
    currentLocationId,
    locationId,
  } = props;

  const customOptionStyles = {
    option: (provided, state) => ({
      ...provided,

      // paddingLeft:
      //   state.data.level > 1 ? 20 + "px" : state.data.level * 40 + "px",
      paddingLeft:
        state.data.level === 1 ? 20 + "px" : (state.data.level - 1) * 40 + "px",
      fontWeight: state.data.isParent ? "500" : "300",
      zIndex: 99,
    }),

    control: (base) => ({
      ...base,
      height: 56,
      minHeight: 56,
    }),

    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };


  
  const generateMenuItems = (
    parentId = null,
    parentBreadcrumb = "",
    level = 0
  ) => {
    console.log("allLocations: ", allLocations); // Log all locations
    console.log("parentId: ", parentId); // Log current parentId

    const parentLocations = allLocations.filter(
      (location) => location.parentLocationId === parentId
    );

    console.log("parentLocations: ", parentLocations); // Log filtered locations

    let menuItems = [];

     // Add an option for no parent location
     if (parentId === null) {
      menuItems.push({
        value: null,
        label: "None",
        breadcrumb: "None",
        level: 0,
        isParent: true,
      });
    }

    parentLocations.forEach((location) => {
      const breadcrumb = parentBreadcrumb
        ? `${parentBreadcrumb} > ${location.name}`
        : location.name;
      const menuItem = {
        value: location.id,
        label: breadcrumb,
        breadcrumb: breadcrumb,
        level: level,
        isParent: parentId === null,
      };
      menuItems.push(menuItem);

      const children = generateMenuItems(location.id, breadcrumb, level + 1);
      menuItems = menuItems.concat(children);
    });

    console.log("menuItems: ", menuItems); // Log generated menu items

    return menuItems;
  };

  const findOptionByLocationId = (locationId) => {
    return generateMenuItems().find((option) => option.value === locationId) || null;
  };

  if (!allLocations) {
    return null; // or some loading spinner, or a message, etc.
  }

  return (
    <>
    <Debug>
      selectedlocationid -- {selectedLocationId} <br />
      parentlocationid -- {parentLocationId} <br />
</Debug>

      
        <Typography sx={{fontSize:'12px'}}><label id="selectLabel" className="selectLabel">Parent Location    </label></Typography>
  
      <Select
   menuPosition="fixed"

        key={parentLocationId}
        // isClearable
        options={generateMenuItems()}
        styles={customOptionStyles}
        onChange={handleParentLocationChange}
        // maxMenuHeight={300}
        placeholder="None"
        defaultValue={findOptionByLocationId(parentLocationId)}
        // value={findOptionByLocationId(locationId || currentLocationId)}
        // defaultValue={findOptionByLocationId(locationId || currentLocationId)}
        
        aria-labelledby="selectLabel"
      />




    </>
  );
}

export default SelectLocation;
