import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  DialogActions,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { createList, useList, updateList } from "./../util/db";
import { getFunctions, httpsCallable } from "firebase/functions";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";
import Debug from "./Debug";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function EditListModal(props) {
  const { onDone, handleDelete, selectedListId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const { register, handleSubmit, errors } = useForm();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [listId, setListId] = useState(null);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedListId == null) {
      setListId(uuid());
    } else {
      setListId(selectedListId);
    }
  }, [selectedListId]);

  const { listData, listStatus } = useList(selectedListId);

  // Function to handle creating a new list
  const handleCreate = (data) => {
    setPending(true);
    setFormAlert(null);

    // Remove parentLocationId from data
    delete data.parentLocationId;

    const newData = { ...data };

    const listData = {
      ...newData,
      owner: auth.user.uid,
      status: "active",
    };

    createList(listData, auth)
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  // Function to handle updating an existing list
  const handleSave = (data) => {
    setPending(true);
    setFormAlert(null);

    // Remove parentLocationId from data
    delete data.parentLocationId;

    const newData = { ...data };

    const listData = {
      ...newData,
      owner: auth.user.uid,
      status: "active",
    };

    updateList(selectedListId, listData)
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  const onSubmit = (data) => {
    if (selectedListId) {
      // If selectedListId exists, it means we are updating an existing list
      handleSave(data);
    } else {
      // If selectedListId does not exist, it means we are creating a new list
      handleCreate(data);
    }
  };

  return (
    <>
      {listStatus === "success" && (
        <Dialog
          open={props.open}
          onClose={props.onClose}
          fullScreen={fullScreen}
        >
          <Debug>
            selectedListID: {selectedListId}
            <br />
            listData.name: {listData?.name}
          </Debug>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {selectedListId ? <>Update</> : <>Create</>} List
            <IconButton onClick={props.onDone}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

    
            {formAlert && formAlert.message && (
              <Box mb={4}>
                <Alert severity={formAlert.type}>{formAlert.message}</Alert>
              </Box>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.content}>
              <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="List Name (required)"
                    name="name"
                    defaultValue={listData && listData.name}
                    error={errors.name ? true : false}
                    helperText={errors.name && errors.name.message}
                    fullWidth
                    autoFocus
                    inputRef={register({
                      required: "Please enter a list name",
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Description (optional)"
                    name="description"
                    defaultValue={listData && listData.description}
                    error={errors.description ? true : false}
                    helperText={
                      errors.description && errors.description.message
                    }
                    fullWidth
                    autoFocus
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Details (optional)"
                    name="contactDetails"
                    defaultValue={listData && listData.contactDetails}
                    error={errors.contactDetails ? true : false}
                    helperText={
                      errors.contactDetails && errors.contactDetails.message
                    }
                    fullWidth
                    autoFocus
                    inputRef={register}
                    placeholder="Contact Details"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Location (optional)"
                    name="location"
                    defaultValue={listData && listData.location}
                    error={errors.location ? true : false}
                    helperText={errors.location && errors.location.message}
                    fullWidth
                    inputRef={register}
                    placeholder="Location"
                  />
                </Grid>
              </Grid>
              </DialogContent>

              <DialogActions
                style={{
                  position: "absolute",
                  bottom: "0",
                  background: "#ffffff",
                  width: "100%",
                  padding: "20px",
                  marginTop: "10px",
                  zIndex: "99",
                  borderTop: "2px solid grey",
                }}
              >
                {props.id && (
                  <Button size="small" color="secondary" onClick={handleDelete}>
                    Delete List
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={pending}
                  style={{ marginLeft: "1rem" }}
                >
                  {!selectedListId && <span>Create List</span>}
                  {!pending && selectedListId && <span>Save</span>}
                  {pending && <CircularProgress size={28} />}
                </Button>
              </DialogActions>
            </form>
        
        </Dialog>
      )}
    </>
  );
}

export default EditListModal;
