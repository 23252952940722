import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useRouter } from "./../util/router.js";
import { styled, alpha } from "@mui/material/styles";
import Typography from "@material-ui/core/Typography";
//import icon_lb from "./../images/icon_lb.png";
import { makeStyles } from "@material-ui/core/styles";
import { CSSTransition } from "react-transition-group";
//import SearchIcon from "@material-ui/icons/Search";
//import ClearIcon from "@material-ui/icons/Clear";
import { useLocation } from "../util/db.js";
import { throttle } from "lodash";
import InputBase from "@mui/material/InputBase";
import Fuse from "fuse.js";
import {
  Box,
  Container,
  Collapse,
  Button,
  Divider,
  Input,
  IconButton,
  InputBaseProps,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

// const icon_lb = null;
// const SearchIcon = null;
// const ClearIcon = null;

const SearchBar = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  // [theme.breakpoints.up("sm")]: {
  //   // marginLeft: theme.spacing(3),
  //   width: "auto",
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Search(props) {
  const router = useRouter();
  // const trigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 70,
  // });
  const {
    onClose,
    bgColor,
    textColor,
    text,
    linkPath,
    linkText,
    countItems,
    items,
    flavour,
    locationId,
    ...otherProps
  } = props;
  const path = router.pathname;
  const [isTop, setIsTop] = useState(false);
  const textInput = React.useRef(null);
  const searchRef = useRef(null);
  const [matches, setMatches] = useState([]);
  const searchEnabled = false; // Algolia / Elastic search. Pay to play. Disable for launch.
  console.log("fuse search matches", matches);
  const [keywords, setKeywords] = useState(null);
  
  const { data: locationData, status } = useLocation(props.locationId);
  const isLoading = status === 'loading';
  // const placeholderText = flavour === 'auctionid' ? 'Search this auction...' : flavour === 'locations' ? 'Search this location...' : 'Search'
  const placeholderText = flavour === 'auctionid'
  ? 'Search this auction...'
  : flavour === 'locations'
    ? (!locationData?.name || isLoading
      ? 'Search All Locations...'
      : `Search ${locationData?.name}...`)
    : 'Search';
 
  
  const handleClearClick = (e) => {
    // textInput.current.value = "";
    // setMatches([]); // Reset matches when the clear button is clicked
    setKeywords("");
    props.setMatches([]); // Clear matches if no matches found
    // props.onChangeSearch([]); // Pass an empty array to the parent component

  };



  useEffect(() => {
    // Do something after keywords or matches has changed
    console.log("keywords has changed to:", keywords);
    console.log("matches has changed to:", matches);
  }, [keywords, matches]);

  useEffect(() => {
    console.log("Search keywords", keywords);
    if (props.onChangeSearch) {
      props.onChangeSearch(keywords);
    }
  }, [keywords]);

  let history = useHistory();

  useEffect(() => {
    console.log("Search component start");
  }, []);

  const fuseOptions = {
    keys: ["name", "description"], // Replace these with the actual keys you're searching
    includeScore: true,
    threshold: 0.4,
    shouldSort: true,
  };

  let fuse = new Fuse(items, fuseOptions); // Replace 'items' with your actual data
  


  function handleSearch(event) {
    const keywords = event.target.value;
    setKeywords(keywords); // Set keywords state here
  
    if (items == null || keywords === "") {
      props.setMatches([]); // Clear matches if keywords are empty
      props.onChangeSearch(keywords, []); // Pass an empty array to the parent component
      return;
    }
  
    let result = fuse.search(keywords);
    console.log("fuse search result", result);
  
    if (result.length === 0) {
      props.setMatches([]); // Clear matches if no matches found
      props.onChangeSearch(keywords, []); // Pass an empty array to the parent component
      console.log("fuse no matches");
      return;
    }

  
    let matchedItems = result.map((r) => r.item);
    props.setMatches(matchedItems);
    console.log("search matches", matches);
    props.onChangeSearch(keywords, matchedItems); 
  }

  console.log('Is Loading xyz:', isLoading);
console.log('Location Data xyz:', locationData);
console.log('Placeholder Text xyz:', placeholderText);
  
  return (
    <>

      <TextField
        fullWidth
        variant="outlined"
        id="search"
        // placeholder={placeholderText}
        placeholder={isLoading ? "" : placeholderText}
        value={keywords || ""}
        onChange={handleSearch} 
        inputRef={textInput}

        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!(keywords === "" || keywords == null) && (
                <Box sx={{display:'flex', paddingRight:'10px', cursor:'pointer'}} aria-label="cancel" onClick={handleClearClick}>
                  <CancelIcon />
                </Box>
              )}
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />





    </>
  );
}
