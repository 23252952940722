import { useState, useRef, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { Box, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import { ClickAwayListener } from "@mui/material";
import { updateItem } from "../util/db";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
const ItemActionAddToList = (props) => {
  const { items, lists, onSelect, selectedItems, onItemsMoved, onDone } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [formAlert, setFormAlert] = useState(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };


  console.log("lists itemactionaddtolist", lists)


  const customOptionStyles = {
    option: (provided, state) => ({
      ...provided,
      // paddingLeft:
      //   state.data.level > 1 ? 20 + "px" : state.data.level * 20 + "px",
        paddingLeft: "16px",
        fontWeight: state.data.isParent ? "500" : "300",
      // backgroundColor: state.data.isParent ? "#dedbdb" : "#ffffff",
    }),
  };

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      width: "300px", // Set the desired width here
      borderBottom:'0px',
      
    }),
    menu: (provided) => ({
      ...provided,
      width: "300px",
      marginTop: '0px',
    }),
  };


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListSelect = (selectedOption) => {
    if (selectedOption) {
      const newListId = selectedOption.value;
  
      // Move the selected items to the chosen list
      selectedItems.forEach(async (itemId) => {
        const item = items.find((item) => item.id === itemId);
        if (item) {
          // Check if the item already has a listId array and append to it if not already included
          const updatedListIds = Array.isArray(item.listId) ? [...item.listId] : [];
          
          if (!updatedListIds.includes(newListId)) {
            updatedListIds.push(newListId);
            // Update the item with the new list of IDs
            await updateItem(itemId, { listId: updatedListIds });
          }
        }
      });
  
      // Clear the selected items after moving
      onItemsMoved([]);
     
      // Call the onSelect function for any additional actions
      onSelect(newListId);
  
      // Close the Select
      setOpen(false);
  
      props.onSnackbarMessage({ message: "Item(s) Added to List" });
    }
  };

  const generateMenuItems = () => {
    return lists.map((list) => ({
      value: list.id,
      label: list.name,
    }));
  };

  return (
    <>
      <Tooltip title="Add Selected to List">
        <IconButton size="large" variant="outlined" ref={anchorRef} onClick={handleToggle}>
          <PlaylistAddIcon />
        </IconButton>
      </Tooltip>

      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <Select
                  defaultMenuIsOpen
                  isClearable
                  placeholder="Add to List..."
                  options={generateMenuItems()}
                  styles={{ ...customOptionStyles, ...customSelectStyles }}
                  onChange={handleListSelect}
                />
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>

      {formAlert && formAlert.message && (
        <Box mb={4}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
    </>
  );
};

export default ItemActionAddToList;
