import React, { useState, useEffect, useRef } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { firebaseApp } from "./../util/firebase";

import {
  Box,
  Alert,
  Grid,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  Snackbar,
  useTheme,
  InputAdornment,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { Link } from "./../util/router";
import {
  useItem,
  updateItem,
  createItem,
  deleteItem,
  useCustomItemFieldsByUser,
  useUserCurrency,
  shortId,
  handleScan,
  deleteImageFromStorage,
} from "./../util/db";

import { makeStyles } from "@mui/styles";
import PhotoUploader from "./PhotoUploader";
import { useDropzone } from "react-dropzone";
import { storage } from "../util/firebase";
import { ref } from "firebase/storage";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { Select as MuiSelect } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "react-select/creatable";
import LocationTreeView from "./LocationTreeView";
import Debug from "./Debug";
import useMediaQuery from "@mui/material/useMediaQuery";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  usePlanChecker,
  useHasExceededImages,
  getImageUploadLimitMessage,
} from "../util/functions";

import {
  useLocationsByOwner,
  useLocation,
  getListsByOwner,
} from "./../util/db";
import {
  AddPhotoAlternateOutlined,
  CatchingPokemonSharp,
} from "@mui/icons-material";

import { v4 as uuidv4 } from "uuid";
import QRCodeAdd from "./QRCodeAdd";
import { useHistory } from "react-router-dom";
import { fetchListsAndSetSelectedList } from "../util/functions";
import ListsByOwner from "./ListsByOwner";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function EditItemModal(props) {
  const history = useHistory();
  const theme = useTheme();
  const auth = useAuth();
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore(firebaseApp);
  const [thumbnails, setThumbnails] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    onDone,
    showPrice,
    locations,
    onSelect,
    items,
    currentLocationId,
    userId,
    id,
    editing,
    // selectedListId
  } = props;
  const [locationId, setLocationId] = useState("");

  // const [selectedListId, setSelectedListId] = useState(null);
  const [selectedListId, setSelectedListId] = useState(
    props.selectedListId || null
  );
  const [selectedOption, setSelectedOption] = useState(null);

  const userPlan = usePlanChecker(
    auth?.user?.stripePriceId || null,
    auth?.user?.stripeSubscriptionStatus || null
  );
  const { data: itemData, status: itemStatus, refetch } = useItem(props.id);
  const uploadLimitMessage = getImageUploadLimitMessage(userPlan);

  // const handleListChange = (event) => {
  //   console.log("handlelistchange", event);
  //   setSelectedListId(event.target.value);
  // };
  const handleListChange = (event) => {
    const selectedValue = event.target.value || ""; // Use an empty string as the default value if undefined
    console.log("handlelistchange", selectedValue);
    setSelectedListId(selectedValue);
  };

  const [price, setPrice] = useState(itemData?.price || 0);
  const [quantity, setQuantity] = useState(itemData?.Quantity || 1); // Default quantity to 1 if not provided
  const [totalValue, setTotalValue] = useState(price * quantity);



  useEffect(() => {
    if (itemData) {
      setPrice(itemData.price || 0);
      setQuantity(itemData.Quantity || 1);
    }
  }, [itemData]);

  useEffect(() => {
    setTotalValue(price * quantity);
  }, [price, quantity]);

  useEffect(() => {
    if (itemStatus === 'success') {  // Only refetch if the previous fetch was successful
      refetch();
    }
  }, [onDone, refetch]);

  const { data: userLocations } = useLocationsByOwner(auth.user.uid);
  const fallbackLocation = locations.find(
    (location) => location.defaultLocation
  );
  const fallbackValue = fallbackLocation
    ? { value: fallbackLocation.id, label: fallbackLocation.name }
    : null;
  let linkPath;
  if (userPlan === "basic") {
    linkPath = "/pricing";
  } else if (userPlan === "advanced") {
    linkPath = "/settings/billing";
  } else {
    linkPath = "#"; // Default path or you can omit
  }

  const currencySymbol = auth.user.currency
    ? getSymbolFromCurrency(auth.user.currency)
    : null;

  const classes = useStyles();
  const { onUpload } = props;

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  // const showLocationSelector = props.id || !currentLocationId;

  const { register, handleSubmit, errors, control } = useForm();

  // const { data: itemData, status: itemStatus, refetch } = useItem(props.id);

  const { data: allLocations = [], status: allLocationsStatus } =
    useLocationsByOwner(auth.user.uid);

  const { data: customFields } = useCustomItemFieldsByUser(auth.user.uid);

  const [itemId, setItemId] = useState();
  const [scanCode, setScanCode] = useState();

  useEffect(() => {
    if (itemId == null) {
      return;
    }
    //history.push(`/item/${itemId}`);
  }, [itemId]);

  const [images, setImages] = useState([]);

  console.log("item data", itemData);
  const [imagePath, setImagePath] = useState(itemData?.image || "");

  const initialImageCount = itemData?.images?.length || 0;
  const [localImageCount, setLocalImageCount] = useState(initialImageCount);

  const hasExceededImages = useHasExceededImages(localImageCount, userPlan);
  if (hasExceededImages) {
    console.log("user plan number of images exceeded");
  }

  console.log("local image count", localImageCount);



  const [initialLocationId, setInitialLocationId] = useState();

  useEffect(() => {
    setInitialLocationId(currentLocationId);
  }, []);

  useEffect(() => {
    if (itemData == null) {
      return;
    }
  }, [itemData]);

  const cropAndResizeImage = async (file, width, height) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = width;
        canvas.height = height;

        // Calculate cropping dimensions to center the image
        let offsetX = 0;
        let offsetY = 0;

        if (img.width > img.height) {
          offsetX = (img.width - img.height) / 2;
        } else {
          offsetY = (img.height - img.width) / 2;
        }

        ctx.drawImage(
          img,
          offsetX,
          offsetY,
          img.width - 2 * offsetX,
          img.height - 2 * offsetY,
          0,
          0,
          width,
          height
        );

        canvas.toBlob((blob) => {
          resolve(new File([blob], file.name, { type: file.type }));
        }, file.type);
      };
    });
  };

  const onDrop = async (acceptedFiles) => {
    if (hasExceededImages) {
      setAlertOpen(true);
      return;
    }

    for (const file of acceptedFiles) {
      const tempItemId = props.id || Date.now();

      if (!file.type.startsWith("image/")) {
        console.error("File is not an image:", file.type);
        props.onSnackbarMessage({
          message: "Uploaded file is not an image!",
          type: "error",
        });
        continue;
      }

      const resizedImage = await resizeImage(file, 600, 600);
      const thumbnailImage = await cropAndResizeImage(file, 75, 75);

      if (navigator.onLine) {
        const uniqueImageName = `items/${uuidv4()}.jpg`;
        const uniqueThumbnailName = `items/thumbnails/${uuidv4()}.jpg`;

        const imageRef = ref(storage, uniqueImageName);
        await uploadBytes(imageRef, resizedImage);
        const imageUrl = await getDownloadURL(imageRef);

        const thumbnailRef = ref(storage, uniqueThumbnailName);
        await uploadBytes(thumbnailRef, thumbnailImage);
        const thumbnailUrl = await getDownloadURL(thumbnailRef);

        setImages((prevImages) => [
          ...prevImages,
          { main: imageUrl, thumbnail: thumbnailUrl },
        ]);

        setLocalImageCount((prevCount) => prevCount + 1);
      } else {
        const uniqueImageName = `offline-main-image-${tempItemId}-${uuidv4()}`;
        const uniqueThumbnailName = `offline-thumbnail-image-${tempItemId}-${uuidv4()}`;

        // Process and store the main image
        const mainReader = new FileReader();
        mainReader.onloadend = () => {
          const base64MainImage = mainReader.result;
          localStorage.setItem(uniqueImageName, base64MainImage);

          // Process and store the thumbnail image
          const thumbReader = new FileReader();
          thumbReader.onloadend = () => {
            const base64ThumbImage = thumbReader.result;
            localStorage.setItem(uniqueThumbnailName, base64ThumbImage);

            setImages((prevImages) => [
              ...prevImages,
              { main: base64MainImage, thumbnail: base64ThumbImage },
            ]);
          };
          thumbReader.readAsDataURL(thumbnailImage);
        };
        mainReader.readAsDataURL(resizedImage);
      }
    }
  };

  const deleteImage = async (imageObj) => {
    // Helper function to extract the correct path from a Firebase Storage URL
    const extractPathFromURL = (imgURL) => {
      const baseURL =
        "https://firebasestorage.googleapis.com/v0/b/kitty-app-381420.appspot.com/o/";
      const strippedURL = imgURL.replace(baseURL, "").split("?")[0]; // remove base URL and query parameters
      return decodeURIComponent(strippedURL);
    };

    // Remove the image from local state
    setImages((prevImages) => prevImages.filter((img) => img !== imageObj));
    setLocalImageCount((prevCount) => prevCount - 1);
    const deleteFromStorage = async (imgURL) => {
      const imagePath = extractPathFromURL(imgURL);
      console.log("Constructed path for deletion:", imagePath);
      // Remove the image from Firebase Storage
      await deleteImageFromStorage(imagePath);
    };

    // Delete both thumbnail and main images
    await deleteFromStorage(imageObj.thumbnail);
    await deleteFromStorage(imageObj.main);
  };

  useEffect(() => {
    console.log("useEffect lists is running"); // Add this line for debugging

    const fetchLists = async () => {
      try {
        const data = await getListsByOwner(userId);
        console.log("Fetched Data lists:", data); // You can also log the fetched data
        setLists(data);
      } catch (error) {
        console.error("Error fetching lists:", error); // Log the error
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLists();
  }, []);

  // fetch and set selected list
  useEffect(() => {
    if (itemData && itemData.listId) {
      fetchListsAndSetSelectedList(
        userId,
        itemData,
        setLists,
        setSelectedListId,
        setLoading
      );
    }
  }, [itemData]);

  // Set initial images array from itemData
  useEffect(() => {
    if (itemData && itemData.images) {
      setImages(itemData.images);
    }
  }, []);

  // Set initial images array from itemData
  useEffect(() => {
    if (itemData && itemData.images) {
      setImages(itemData.images);
    }
  }, [itemData && itemData.images]);

  useEffect(() => {
    console.log("currentlocationid edititemmodal", currentLocationId);
  }, [currentLocationId]);

  useEffect(() => {
    console.log("starting edititemmodal");
  }, []);

  useEffect(() => {
    console.log("locationid edititemmodal", locationId);
  }, [locationId]);

  const [featuredImageIndex, setFeaturedImageIndex] = useState(null);
  const initialMount = useRef(true);
  useEffect(() => {
    // If there's an image marked as featured, use its index.
    const featuredIndex = images.findIndex((image) => image.featured);
    if (featuredIndex !== -1) {
      setFeaturedImageIndex(featuredIndex);
    } else if (featuredImageIndex === null && images.length > 0) {
      // If no image is marked as featured and featuredImageIndex hasn't been set, default to 0.
      setFeaturedImageIndex(0);
    }
  }, [images]);

  useEffect(() => {
    if (selectedOption) {
      setLocationId(selectedOption.value);
    } else if (itemData?.locationId) {
      setLocationId(itemData.locationId);
    } else if (currentLocationId) {
      setLocationId(currentLocationId);
    } else if (!itemData?.locationId) {
      setLocationId(fallbackValue?.value || "");
    }
  }, [selectedOption, currentLocationId, itemData, fallbackValue]);

  // get Dropzone props
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  if (props.id && itemStatus !== "success") {
    return null;
  }

  const generateMenuItems = (
    parentId = null,
    parentBreadcrumb = "",
    level = 0
  ) => {
    const parentLocations = allLocations.filter(
      (location) => location.parentLocationId === parentId
    );

    let menuItems = [];

    parentLocations.forEach((location) => {
      const breadcrumb = parentBreadcrumb
        ? `${parentBreadcrumb} > ${location.name}`
        : location.name;
      const menuItem = {
        value: location.id,
        label: breadcrumb,
        breadcrumb: breadcrumb,
        level: level,
        isParent: parentId === null,
      };
      menuItems.push(menuItem);

      const children = generateMenuItems(location.id, breadcrumb, level + 1);
      menuItems = menuItems.concat(children);
    });

    return menuItems;
  };

  const handleParentLocationChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const findOptionByLocationId = (locationId) => {
    return generateMenuItems().find((option) => option.value === locationId);
  };

  const customOptionStyles = {
    option: (provided, state) => ({
      ...provided,

      paddingLeft:
        state.data.level === 1 ? 20 + "px" : (state.data.level - 1) * 40 + "px",
      fontWeight: state.data.isParent ? "500" : "300",
      zIndex: 99,
    }),

    control: (base) => ({
      ...base,
      height: 56,
      minHeight: 56,
    }),

    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let { width, height } = img;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              })
            );
          },
          "image/jpeg",
          0.5
        ); // Set the quality of the image between 0 and 1. 0.9 means 90% quality
      };
      img.onerror = reject;
    });
  };

  if (props.id && itemStatus !== "success") {
    return null;
  }

  const onSubmit = (data) => {
    try {
      console.log("Form data before processing:", data);
      setPending(true);

      // Set image path to the images array
      data.images = images.length > 0 ? images : [];
      console.log("data image", data.images);

      // Modify the images array to include the 'featured' field
      data.images = data.images.map((image, index) => ({
        ...image,
        featured: index === featuredImageIndex,
      }));

      // Set locationId to data object
      data.locationId = locationId || currentLocationId || ""; // Use the default value if locationId is not set

      // Set listId if available
      if (selectedListId) {
        data.listId = [selectedListId];
      }




      
      // Add custom fields to the data object
      customFields.forEach((field) => {
        if (data[field.id] === undefined || data[field.id] === null) {
          data[field.id] = "";
        }
      });

      // Set scanCode if available
      if (typeof scanCode !== "undefined") {
        data["scanCode"] = scanCode;
      }

     
      console.log("itemId edititemmodal:", itemId);
      console.log("auth.user.uid edit item modal:", auth.user.uid);



      const query = props.id
        ? updateItem(props.id, data)
        : createItem({ owner: auth.user.uid, ...data });
        console.log("createitem owner itemdata:", auth.user.uid);
      if (navigator.onLine) {
        // If online, use the normal behavior
        query
          .then(() => {
            console.log("Image Query successful");
            console.log("Form data after processing:", data);
            props.onDone();

            props.onSnackbarMessage({
              message: props.id
                ? "Item Updated Successfully"
                : "Item Created Successfully",
              type: "success",
            });
          })
          .catch((error) => {
            console.log("Catch block executed");
            // Show error alert message
            setFormAlert({
              type: "error",
              message: error.message,
            });
          })
          .finally(() => {
            console.log("Finally block executed");
            // Hide pending indicator regardless of success or failure
            setPending(false);
          });
      } else {
        // If offline, let the user know the data will be synced later
        console.log("Offline. Data will be synced when online.");
        setPending(false);

        props.onSnackbarMessage({
          message:
            "Offline. Data has been saved locally and will be synced when online.",
          type: "success",
        });
        props.onDone();
      }
    } catch (error) {
      console.error("Error block in onSubmit:", error);
      // You can also show an error message to the user here
      setPending(false);
    }
  };

  function handleDeleteItem() {
    deleteItem(props.id);
    props.onSnackbarMessage({
      message: "Item Deleted Successfully",
      type: "success",
    });
    props.onDone();

    // Show success snackbar using callback prop instead
  }

  const onNewScanResult = async (decodedText, decodedResult) => {
    // handle decoded results here
    //await handleScan(db, decodedText);
    console.log(
      "EditItemModal decodedText decodedResult",
      decodedText,
      decodedResult
    );

    setScanCode(decodedText);
    // props.onSnackbarMessage("Saw unique identifier " + decodedText);
  };

  // console.log("lists data", lists);

  return (
    <>
      <Dialog open={true} onClose={props.onDone} fullScreen={fullScreen}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {props.id && <>Update</>}
          {!props.id && <>Create</>}
          {` `}Item
          <Debug>
            {" "}
            item ID {itemData?.id}
            <br />
            currentLocationId {currentLocationId && currentLocationId}
            <br />
            currentLocationId {initialLocationId && initialLocationId}
            <br />
            itemData.locationId {itemData?.locationId}
            <br />
            selectedListId {selectedListId}
            <br />
            {/* isOwner {isOwner && (<>The Owner</>)} */}
            {/* <br/>
            itemData.owner {itemData.owner} */}
          </Debug>
          <IconButton onClick={props.onDone}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {formAlert && formAlert.message && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}
        <Box pt={0.5} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.content}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Debug>
                  Link to ItemId
                  {itemId}
                  <br />
                  {scanCode}
                  <br />
                  Passed to item modal {itemData?.scanCode}
                  <br />
                </Debug>

                <QRCodeAdd
                  inputScanCode={itemData?.scanCode}
                  qrCodeSuccessCallback={(decodedText, decodedResult) =>
                    onNewScanResult(decodedText, decodedResult)
                  }
                />

                <>
                  <Typography sx={{ fontSize: "12px" }}>
                    {" "}
                    <label id="selectLabel" className="selectLabel">
                      Location
                    </label>{" "}
                  </Typography>

                  <Select
                    // isClearable
                    options={generateMenuItems()}
                    styles={customOptionStyles}
                    onChange={handleParentLocationChange}
                    value={
                      findOptionByLocationId(
                        locationId || currentLocationId
                      ) || { fallbackValue }
                    }
                    defaultValue={findOptionByLocationId(
                      locationId || currentLocationId
                    )}
                    aria-labelledby="selectLabel"
                  />
                </>
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Name"
                  name="name"
                  defaultValue={itemData && itemData?.name}
                  error={errors.name ? true : false}
                  helperText={errors.name && errors?.name.message}
                  fullWidth={true}
                  inputRef={register({ required: "Name is required" })}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Description"
                  name="description"
                  defaultValue={itemData && itemData?.description}
                  error={errors.description ? true : false}
                  helperText={errors.description && errors?.description.message}
                  fullWidth={true}
                  inputRef={register}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="number"
                  label="Price"
                  name="price"
                  defaultValue={itemData && itemData?.price}
                  error={errors.price ? true : false}
                  helperText={errors.price && errors?.price.message}
                  fullWidth={true}
                  inputRef={register}
                  onChange={(e) => setPrice(Number(e.target.value))}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="number"
                  label="Quantity"
                  name="Quantity"
                  defaultValue={itemData && itemData?.Quantity || 1}
                  error={errors.Quantity ? true : false}
                  helperText={errors.Quantity && errors?.Quanitity.message}
                  fullWidth={true}
                  inputRef={register}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="number"
                  label="Total Value (Price x Quantity)"
                  name="totalValue"
                  
                  value={totalValue || 0} // Show 0 as default if totalValue is falsy
                  inputRef={register}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                />
              </Grid>

              <Debug>
                current location id : {currentLocationId} <br />
                location id: {locationId}
              </Debug>

              {customFields?.map((field) => (
                <Grid item={true} xs={12} key={field.id}>
                  {field.type === "boolean" ? (
                    <>
                      <Typography sx={{ fontSize: "12px" }}>
                        <label id="selectLabel" className="selectLabel">
                          {field.id}{" "}
                        </label>
                      </Typography>
                      <Controller
                        name={field.id}
                        control={control}
                        defaultValue={
                          itemData && field && field.id && itemData[field.id]
                            ? itemData[field.id]
                            : ""
                        }
                        as={
                          <MuiSelect fullWidth>
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                          </MuiSelect>
                        }
                      />
                    </>
                  ) : (
                    <TextField
                      fullWidth
                      name={field.id}
                      type={field.type === "number" ? "number" : "text"}
                      label={field.id}
                      variant="outlined"
                      defaultValue={
                        itemData && field && field.id && itemData[field.id]
                          ? itemData[field.id]
                          : ""
                      }
                      inputRef={register}
                      InputProps={{
                        startAdornment: field.currency ? (
                          <InputAdornment position="start">
                            {currencySymbol}
                          </InputAdornment>
                        ) : null,
                      }}
                    />
                  )}
                </Grid>
              ))}

              {/* add Dropzone to component */}
              <Grid item={true} xs={12}>
                <div
                  {...getRootProps()}
                  style={{
                    textAlign: "center",
                    border: "1px dashed black",
                    padding: "10px",
                  }}
                >
                  <input {...getInputProps()} />
                  <Box
                    p={5}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <IconButton color="primary">
                      <AddPhotoAlternateOutlined fontSize="large" />
                    </IconButton>
                    <Typography color="textSecondary" variant="body1">
                      Upload / Drag Images
                    </Typography>
                  </Box>
                </div>

                <Box>
                  <ul
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    {images.map((image, index) => (
                      <Box onDelete={deleteImage}>
                        <li
                          style={{
                            margin: 4,
                            position: "relative",
                            width: "thumbnailWidth",
                            height: "thumbnailHeight",
                            overflow: "hidden",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <img
                              src={image.thumbnail}
                              alt={`Item Image ${index}`}
                              style={{
                                width: 75,
                                height: 75,
                                objectFit: "cover",
                                borderRadius: 4,
                                cursor: "move",
                                border:
                                  index === featuredImageIndex
                                    ? `5px solid #e91e63`
                                    : "none",
                              }}
                              onClick={() => setFeaturedImageIndex(index)}
                            />
                            <IconButton
                              size="small"
                              style={{
                                position: "absolute",
                                top: 1,
                                right: 1,
                                backgroundColor: "#ffffff",
                                opacity: "0.8",
                              }}
                              onClick={() => {
                                deleteImage(image);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </li>
                      </Box>
                    ))}
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions
            style={{
              position: "sticky",
              bottom: "0",
              background: "#ffffff",
              width: "100%",
              padding: "20px",
              marginTop: "10px",
              zIndex: "99",
              borderTop: "2px solid grey",
            }}
          >
            <Box>
              {!(props.id == null) && (
                <Button
                  edge="end"
                  aria-label="delete"
                  onMouseDown={(e) => {
                    e.preventDefault(); // Prevent default mousedown behaviors
                    handleDeleteItem();
                  }}
                  onTouchStart={(e) => {
                    e.preventDefault(); // Prevent default touch behaviors
                    handleDeleteItem();
                  }}
                  size="large"
                  color="secondary"
                >
                  Delete Item
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
                style={{ marginLeft: "1rem" }}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent default mousedown behaviors
                  handleSubmit(onSubmit)();
                }}
                onTouchStart={(e) => {
                  e.preventDefault(); // Prevent default touch behaviors
                  handleSubmit(onSubmit)();
                }}
              >
                {!props.id && <span>Create Item</span>}
                {!pending && props.id && <span>Save</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Box>
          </DialogActions>
          {/* </Grid> */}
        </form>
      </Dialog>

      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <DialogTitle>{"Image Limit Exceeded"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{uploadLimitMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary">
            Dismiss
          </Button>
          {/* Optionally, add a button to navigate to the upgrade page */}
          <Box mr={2}>
            <Button
              component={Link}
              to={linkPath}
              variant="contained"
              color="secondary"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              Upgrade
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditItemModal;
