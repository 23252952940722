import React from "react";
import Avatar from "@mui/material/Avatar";

const SquareAvatar = ({ src, alt, size }) => {
  return (
    <Avatar
      src={src}
      alt={alt}
      sx={{
        width: size,
        height: size,
        borderRadius: "4px", // set the borderRadius to 4px to create a square image
      }}
    />
  );
};

export default SquareAvatar;
