import React, { useMemo, useEffect } from "react";
import { DataGrid, GridToolbarExport, GridToolbar  } from "@mui/x-data-grid";
import { useItemsByOwner, updateItem, useLocationsByOwner, useLocations, createItem } from "../util/db";
import { useAuth } from "../util/auth";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { findLocationPath } from "../util/functions";
import ItemLocation from "./ItemLocationName";
import { v4 as uuidv4 } from 'uuid';
import { Button, Container, Box } from '@mui/material';
import Stack from '@mui/material/Stack';

function ItemsTable() {
  const auth = useAuth();
  const { data: items, status: itemsStatus } = useItemsByOwner(auth.user.uid);
  const { data: locationsData, status: locationsStatus } = useLocations(auth.user.uid);
  console.log("locations itemspreadsheet", locationsData);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [rows, setRows] = React.useState(() => [
    // createRandomRow(),
  ]);

  function downloadJSON(data, filename) {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

function ExportJsonButton() {
  return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         
          <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                  downloadJSON(combinedRows, "data-export.json"); // use your data array
              }}
          >
              Download Data (JSON)
          </Button>
      </div>
  );
}



  console.log("Random row created:", rows);

  const handleAddRow = async () => {
    try {
        // Define the new item with the default values
        const newItem = {
            id: uuidv4(),
            owner: auth.user.uid,
            name: "",
        };

        // Save the new item to the database
        await createItem(newItem);

        // Display a success message
        setSnackbar({
            children: "Item saved successfully",
            severity: "success",
        });
    } catch (error) {
        // Display an error message
        setSnackbar({
            children: error.message,
            severity: "error",
        });
    }
};


const combinedRows = React.useMemo(() => {
  if (!items || !Array.isArray(items) || !locationsData) {
    return [...rows];
  }
  return [
    ...items.map((item) => {
      // Find the locationName based on the locationId
      const location = locationsData.find(location => location.id === item.locationId);
      const locationName = location ? location.name : '';
      return { id: item.id, locationName, ...item };
    }),
    ...rows
  ];
}, [items, rows, locationsData]);

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const originalItem = items.find((item) => item.id === newRow.id);
      const updatedItem = { ...originalItem, ...newRow };

      // Remove undefined values from the updatedItem object
      Object.keys(updatedItem).forEach((key) => {
        if (updatedItem[key] === undefined) {
          delete updatedItem[key];
        }
      });

      try {
        await updateItem(newRow.id, updatedItem);
        // setSnackbar({
        //   children: "Item updated successfully",
        //   severity: "success",
        // });
        return updatedItem;
      } catch (error) {
        throw error;
      }
    },
    [items]
  );


  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  let columns = [];


  if (itemsStatus === "success" && items.length > 0 && locationsStatus === "success") {
   

// Keep track of unique fields across all items
const uniqueFields = new Set();

// Loop through each item and add its fields to the uniqueFields set
items.forEach(item => {
  Object.keys(item).forEach(field => uniqueFields.add(field));
});


// Ensure that 'locationName' is in uniqueFields
uniqueFields.add('locationName');


// Convert the uniqueFields set to an array
const desiredColumnFields = Array.from(uniqueFields);


     // Create the columns based on the items data
     columns = desiredColumnFields.map((field) => {
      console.log('Items data:', items[0]);
      const columnConfig = {
        field,
        headerName: field,
        width: 180,
        editable: true,
        headerClassName: 'capitalize',
      };
    
      if (field === 'createdAt') {
        return {
          field: 'createdAt',
          headerName: 'Created At',
          width: 180,
          editable: false,
          valueGetter: (params) => {
            const timestamp = params.row.createdAt;
            if (timestamp) {
              const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp * 1000);
              return date.toLocaleString();
            }
            return '';
          },
        };
      }
    
      if (field === 'images') {
        return {
          field: 'images',
          headerName: 'Image',
          width: 180,
          valueGetter: (params) => {
            const imagesArray = params.row.images;
            
            // Check if imagesArray is valid and has at least one image object
            if (Array.isArray(imagesArray) && imagesArray.length > 0 && imagesArray[0].thumbnail) {
              // Return the URL of the main image
              return imagesArray[0].main;
            }
            return '';
          },
          renderCell: (params) => {
            const imageUrl = params.value;
      
            if (imageUrl) {
              return (
                <img
                  src={imageUrl}
                  alt="Item"
                  style={{ width: '100%', maxHeight: '50px', objectFit: 'contain', padding: '5px' }}
                />
              );
            } else {
              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  No Image
                </div>
              );
            }
          },
        };
      }
      
   
      if (field === 'updatedAt') {
        return {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 180,
            editable: false,
            valueGetter: (params) => {
                const timestamp = params.row.updatedAt;
                if (timestamp) {
                    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp * 1000);
                    return date.toLocaleString();
                }
                return '';
            },
        };
    }
      
      if (field === 'locationName') {
        return {
            field: 'locationName',
            headerName: 'Location Name',
            width: 180,
            filterable: true, 
            editable: false,
            renderCell: (params) => {
                const locationId = params.row.locationId;
                const locationPath = findLocationPath(locationId, locationsData);
                
                if (!locationsData || locationsStatus === 'loading') {
                    return <div>Loading...</div>;
                }
    
                return (
                    <select
                        value={locationId || ""} // set initial value to current locationId, or empty string if not set
                        onChange={async (e) => {
                            const newLocationId = e.target.value;
                            
                            // Don't update if "Choose a location" is selected
                            if (!newLocationId) {
                                return;
                            }
    
                            // Update the locationId in the row data
                            const updatedRow = { ...params.row, locationId: newLocationId };
    
                            // Call function to save the changes to the database
                            await processRowUpdate(updatedRow);
                        }}
                    >
                        {/* Option for choosing a location */}
                        <option value="">
                            Choose a location
                        </option>
                        
                        {/* Existing locations */}
                        {locationsData.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.name}
                            </option>
                        ))}
                    </select>
                );
            }
        };
    }



      return columnConfig;
    })
    
    
    
  // Define the desired column order
  const columnOrder = ["images", "name", "description", "locationName", "price"];

  // Sort the columns based on the desired order
  columns.sort((a, b) => {
    const aIndex = columnOrder.indexOf(a.field);
    const bIndex = columnOrder.indexOf(b.field);

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    if (aIndex !== -1) {
      return -1;
    }

    if (bIndex !== -1) {
      return 1;
    }

    return a.field.localeCompare(b.field);
  });
    // Move the Image column to be the first column
    const imageColumnIndex = columns.findIndex((col) => col.field === "images");
    if (imageColumnIndex > 0) {
      const [imageColumn] = columns.splice(imageColumnIndex, 1);
      columns.unshift(imageColumn);
    }




    // const rows = items.map((item) => ({
    //   id: item.id,
    //   ...item,
    // }));


    console.log("Items foobar:", items);
    console.log("Rows in state foobar:", rows);
    console.log("Combined Rows foobar:", combinedRows);




    return (
 
      <div style={{ height: "100%", width: "100%",  }}>
<Container>
  
  <Box py={1} display="flex" justifyContent="flex-end"> 
  <Box pr={1}>
  <ExportJsonButton /> 
  </Box>
 <Button variant="contained" size="medium" onClick={handleAddRow}>
          Add Row
        </Button>
        </Box>
   
        </Container>

        <div style={{ height: "100%", width: "100%" }}>
   
      {/* ... rest of your component ... */}
    </div>
      
        <DataGrid
          sx={{border:'none', paddingLeft:'20px'}}
          // rows={rows}
          rows={combinedRows}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          components={{
            Toolbar: GridToolbar,
          }}
          
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns
                id: false,
                locationId: false,
                owner: false,

              },
            },
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>

    );
  }

  return <div><br/><Container><b>No item or location data available.</b></Container></div>;
}

export default ItemsTable;



