import { useState, useEffect, useRef } from "react";

import {
    Box,
    Paper,

    Typography,
  } from "@mui/material";
  import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';



function HowToLists(props) {

return (

<Box px={3}>
<Typography variant="h4" gutterBottom>
  Getting Started with Lists
</Typography>
<Typography variant="subtitle1" sx={{ mt: 2 }}>
  Welcome to our List Management feature! Easily share your inventory with customizable lists that you can share publicly or keep private. Here’s how you can get started:
</Typography>

<Paper elevation={3} sx={{ marginTop: 3, padding: 2 }}>
  <Typography variant="h6" gutterBottom>
    Create Your List
  </Typography>
  <Typography variant="body1">
    <strong>Public Lists:</strong> Click on the Create List Button to get started. Each list you create has a unique URL, making it easy to share with your audience. Simply create a list, add your products, and publish. You can either add your products by selecting them from Inventory and adding them to the desired list with <PlaylistAddIcon /> button, or by directly adding them in lists with Add Item. 
  </Typography>
</Paper>

<Paper elevation={3} sx={{ marginTop: 3, padding: 2 }}>
  <Typography variant="h6" gutterBottom>
    Customize Your List
  </Typography>
  <Typography variant="body1">
    <strong>Selective Fields:</strong> You have the control to select which product fields to display to your audience. Choose which item fields (e.g. price, title, desciption etc) to share for each item in your list.
  </Typography>
</Paper>

 <Paper elevation={3} sx={{ marginTop: 3, padding: 2 }}>
  <Typography variant="h6" gutterBottom>
    Example Use Cases
  </Typography>
  <Typography variant="body1">
    <strong>Online Shop:</strong> Manage your online store's inventory by creating public lists of products that customers can view. Update product details, prices, and availability in real time.
  </Typography>
  <Typography variant="body1" sx={{ mt: 1 }}>
    <strong>Selling Home Items:</strong> Declutter your home by listing unused items. Keep the list private until you're ready to publish and share with local buyer groups.
  </Typography>
  <Typography variant="body1" sx={{ mt: 1 }}>
    <strong>Event Planning:</strong> Organize event supplies and rentals by maintaining a list of available items for event planners and committees to access and reserve.
  </Typography>
  <Typography variant="body1" sx={{ mt: 1 }}>
    <strong>Collectibles Display:</strong> Showcase your collection of art, antiques, or memorabilia in a curated public list, sharing detailed descriptions and stories behind each item.
  </Typography>
</Paper>

<Paper elevation={3} sx={{ marginTop: 3, padding: 2 }}>
  <Typography variant="h6" gutterBottom>
    Privacy Control
  </Typography>
  <Typography variant="body1">
    <strong>Private Lists:</strong> If you prefer to keep a list private, simply don’t publish it. 
  </Typography>
</Paper>

<Typography variant="body1" sx={{ mt: 3 }}>
Click on the Create List Button to get started
</Typography>
</Box>
)
}

export default HowToLists;