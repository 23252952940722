import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import getSymbolFromCurrency from "currency-symbol-map";

const currencyCodes = [
  "USD",
  "EUR",
  "GBP",
  "AED",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "CZK",
  "DKK",
  "HKD",
  "HUF",
  "INR",
  "JPY",
  "MXN",
  "NOK",
  "NZD",
  "PLN",
  "RUB",
  "SEK",
  "SGD",
  "TRY",
  "ZAR",
];

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  // const [currency, setCurrency] = useState(auth.user.currency || "$");

  const [currency, setCurrency] = useState(auth.user.currency || "USD");


  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
  
    // const currencySymbol = getSymbolFromCurrency(currency);
  
    
    return auth
    .updateProfile({ ...data, currency: currency })
    .then(() => {
      // Set success status
      props.onStatus({
        type: "success",
        message: "Your profile has been updated",
      });
    })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };
  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            type="text"
            label="Name"
            name="name"
            placeholder="Name"
            defaultValue={auth.user.name}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your name",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            type="email"
            label="Email"
            name="email"
            placeholder="user@example.com"
            defaultValue={auth.user.email}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your email",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="currency-select-label">Currency</InputLabel>
            <Select
              labelId="currency-select-label"
              id="currency-select"
              name="currency"
              value={currency}
           
              onChange={handleCurrencyChange}
              label="Currency"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 150, // Set the maxHeight to your desired value
                  },
                },
              }}
            >
              {/* {currencyCodes.map((code) => (
                <MenuItem key={code} value={code}>
                  {getSymbolFromCurrency(code)}
                </MenuItem>
              ))} */}

{currencyCodes.map((code) => (
  <MenuItem key={code} value={code}>
    {`${getSymbolFromCurrency(code)} (${code})`}
  </MenuItem>
))}


              {/* Add other currency options as needed */}
            </Select>
          </FormControl>
        </Grid>

        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>Save</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SettingsGeneral;
