import React, { useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Section from "./Section";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useTheme } from "@mui/styles";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { ListItemButton, Typography } from "@mui/material";
import CustomItemFields from "./CustomItemFields";
import CustomItemFieldsDrawer from "./CustomItemFieldsDrawer";
import { usePlanChecker } from "../util/functions";

function Navbar(props) {
  const theme = useTheme();
  const auth = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const userPlan = usePlanChecker(
    auth?.user?.stripePriceId || null,
    auth?.user?.stripeSubscriptionStatus || null
  );

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && theme.name === "dark"
      ? props.logoInverted
      : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  let linkPath;
  if (userPlan === "basic") {
    linkPath = "/pricing";
  } else if (userPlan === "advanced") {
    linkPath = "/settings/billing";
  } else {
    linkPath = "#"; // Default path or you can omit
  }

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        {/* <Container disableGutters={true}> */}
        <Toolbar>
          <Link to="/">
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{ height: "38px" }}
            />
          </Link>
          <Typography
            component={Link}
            to="/"
            variant="h6"
            sx={{
              paddingLeft: "5px",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <b>Item</b>Genie
          </Typography>

          <IconButton
            onClick={() => setDrawerOpen(true)}
            color="inherit"
            size="large"
            sx={{ ml: "auto", display: { sm: "none", xs: "flex" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ ml: "auto", display: { sm: "block", xs: "none" } }}>
            

            <>
              <Box display="flex">
                <>

            {!auth.user && ( 
                    <>
                      <Box mr={2}>
                        <Button color="inherit" component={Link} to="/pricing">
                        <Typography color="textSecondary" > Pricing</Typography> 
                        </Button>
                      </Box>
                    </>
              )}



                  {!auth.user && (
              <Button  component={Link} to="/auth/signin" color="primary" sx={{marginRight:'10px'}}>
             <Typography>   Sign In </Typography>
              </Button>
            )}

                {!auth.user && (
              <Button variant="outlined" component={Link} to="/auth/signup" color="secondary">
             <Typography>    Get Started </Typography>
              </Button>
            )}


                  {auth.user && userPlan && (
                    <Box mr={2}>
                      <Button
                        variant="outlined"
                        size="normal"
                        component={Link}
                        to={linkPath}
                      >
                        {userPlan === "basic" && (
                          <>
                            <Typography variant="subtitle2">
                              <b>Basic Plan</b>&nbsp;
                              <u
                                style={{ color: theme.palette.secondary.main }}
                              >
                                Upgrade
                              </u>
                            </Typography>
                          </>
                        )}
                        {userPlan === "advanced" && (
                          <>
                            <Typography variant="subtitle2">
                              <b>Advanced Plan</b>&nbsp;
                              <u
                                style={{ color: theme.palette.secondary.main }}
                              >
                                Upgrade
                              </u>
                            </Typography>
                          </>
                        )}
                        {userPlan === "genie" && <b>Genie plan</b>}
                        {/* {userPlan === null && <b>Basic plan</b>} */}
                      </Button>
                    </Box>
                  )}

                  {auth.user && (
                    <>
                      <Button
                        color="inherit"
                        aria-label="Account"
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleOpenMenu(event, "account-menu");
                        }}
                      >
                        <Typography > Account Settings </Typography>
                        <ExpandMoreIcon />
                      </Button>

                      <Menu
                        id="account-menu"
                        open={
                          menuState && menuState.id === "account-menu"
                            ? true
                            : false
                        }
                        anchorEl={menuState && menuState.anchor}
                        onClick={handleCloseMenu}
                        onClose={handleCloseMenu}
                        keepMounted={true}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        {/* <MenuItem component={Link} to="/dashboard">
                      Dashboard
                    </MenuItem> */}



                    

                        <MenuItem component={Link} to="/settings/general">
                          Settings
                        </MenuItem>

                        <MenuItem color="inherit" component={Link} to="/pricing">
                        Pricing
                        </MenuItem>


                        <MenuItem color="inherit" component={Link} to="/contact">
                      Contact Us
                        </MenuItem>
                    
                        <Divider />
                        <MenuItem
                          onClick={(event) => {
                            auth.signout();
                          }}
                        >
                          Signout
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                  {/* <CustomItemFieldsDrawer /> */}
                </>
              </Box>
            </>

            {/* <IconButton
                color="inherit"
                onClick={theme.toggle}
                style={{ opacity: 0.6 }}
                size="large"
              >
                {theme.name === "dark" && <NightsStayIcon />}

                {theme.name !== "dark" && <WbSunnyIcon />}
              </IconButton> */}
          </Box>
        </Toolbar>
        {/* </Container> */}
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List onClick={() => setDrawerOpen(false)} sx={{ width: "250px" }}>
          {!auth.user && (
            <>
      

             <ListItemButton component={Link} to="/auth/signup" button="true">
             <ListItemText>Sign Up / Sign In</ListItemText>
           </ListItemButton>



                        <ListItemButton component={Link} button="true" to="/pricing">
                        <ListItemText > Pricing</ListItemText> 
                        </ListItemButton>
                 


           </>

          )}

          {auth.user && (
            <>


<ListItem>
<Button
                        variant="outlined"
                        size="normal"
                        component={Link}
                        to={linkPath}
                      >
                        {userPlan === "basic" && (
                          <>
                            <Typography variant="subtitle2">
                              <b>Basic Plan</b>&nbsp;
                              <u
                                style={{ color: theme.palette.secondary.main }}
                              >
                                Upgrade
                              </u>
                            </Typography>
                          </>
                        )}
                        {userPlan === "advanced" && (
                          <>
                            <Typography variant="subtitle2">
                              <b>Advanced Plan</b>&nbsp;
                              <u
                                style={{ color: theme.palette.secondary.main }}
                              >
                                Upgrade
                              </u>
                            </Typography>
                          </>
                        )}
                        {userPlan === "genie" && <b>Genie plan</b>}
                        {/* {userPlan === null && <b>Basic plan</b>} */}
                      </Button>
                      </ListItem>

              <ListItem component={Link} to="/pricing" button={true}>
              
              
              
                <ListItemText >Pricing</ListItemText>
              </ListItem>
              <ListItem component={Link} to="/settings/general" button={true}>
                <ListItemText>Settings</ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button={true}
                onClick={(event) => {
                  auth.signout();
                }}
              >
                <ListItemText>Sign out</ListItemText>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
