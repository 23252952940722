import { useState, useEffect, useRef } from "react";
import Alert from "@mui/material/Alert";
import { Box, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import {
  useItem,
  updateItem,
  deleteItem,
  deleteItems,
  updateUser,
} from "./../util/db";
import { makeStyles } from "@mui/styles";
// import Select from "@mui/material/Select";
import Select from "react-select";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import { ClickAwayListener } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import { useLocationsByOwner, useLocation } from "./../util/db";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { useHistory } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { firebaseApp } from "../util/firebase";

const db = getFirestore(firebaseApp);

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function ItemActions(props) {
  const {
    items,
    locations,
    onSelect,
    selectedItems,
    onItemsMoved,
    onDone,
    inputLocation,
  } = props;
  const [locationId, setLocationId] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const history = useHistory();
  const toggleSelect = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const selectRef = useRef(null);

  // const onInputChange = (options, { action }) => {
  //   if (action === "menu-close") {
  //     // setMenuIsOpen(false);
  //   }
  // };

  const classes = useStyles();
  const { onUpload } = props;
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { register, handleSubmit, errors } = useForm();
  const { data: allLocations = [], status: allLocationsStatus } =
    useLocationsByOwner(auth.user.uid);

  // const [images, setImages] = useState([]);
  const { data: itemData, status: itemStatus } = useItem(props.id);

  ////

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // Set initial images array from itemData
  // useEffect(() => {
  //   if (itemData?.images) {
  //     setImages(itemData.images);
  //   }
  // }, [itemData]);

  // Set initial locationId from itemData
  useEffect(() => {
    if (itemData?.locationId) {
      setLocationId(itemData.locationId);
    }
  }, [itemData]);

  const handleMenuItemClick = (location) => {
    setLocationId(location.id);
    setAnchorEl(null);
  };

  // const handleDeleteSelectedItems = () => {
  //   selectedItems.forEach((itemId) => {
  //     deleteItem(itemId);
  //   });

  const handleDeleteSelectedItems = async () => {
    if (selectedItems.length > 0) {
      try {
        // Fetch details for all selected items in parallel using getItem function defined in db.js
        const itemsDetails = await Promise.all(
          selectedItems.map((itemId) =>
            getItem(itemId).then((doc) => doc.data())
          )
        );

        // Check if any selected item is marked as a default item
        const hasDefaultItem = itemsDetails.some((item) => item.defaultItem);

        // Conditionally update user document if there's a default item and it's their first time deleting a default item
        if (hasDefaultItem && auth.user && !auth.user.firstItemDeleted) {
          await updateUser(auth.user.uid, { firstItemDeleted: true });
          console.log("Updated user for first item deletion");
        }

        // Use deleteItems function to delete all selected items
        await deleteItems(selectedItems);
        console.log("All selected items have been successfully deleted.");

        // Clear the selected items from state and provide UI feedback
        onItemsMoved([]);
        props.onSnackbarMessage({
          message: "Item(s) Deleted",
          type: "success",
        });
      } catch (error) {
        console.error("Error deleting selected items:", error);
        props.onSnackbarMessage({
          message: "Failed to delete items",
          type: "error",
        });
      }
    } else {
      console.log("No items selected for deletion.");
      props.onSnackbarMessage({
        message: "No items selected",
        type: "warning",
      });
    }
  };

  // Example of using getItem function for multiple items
  async function getItem(itemId) {
    return getDoc(doc(db, "items", itemId));
  }

  const { data: locationData, status: locationStatus } =
    useLocation(locationId);

  // const [parentLocationId, setParentLocationId] = useState("");

  useEffect(() => {
    if (locationData) {
      console.log("locationData has a value:", locationData);
    } else {
      console.log("locationData is null or undefined");
    }
  }, [locationData]);

  // If we are updating an existing location
  // don't show modal until location data is fetched.
  if (props.id && locationStatus !== "success") {
    return null;
  }

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && itemStatus !== "success") {
    return null;
  }

  const generateMenuItems = (
    parentId = null,
    parentBreadcrumb = "",
    level = 0
  ) => {
    const parentLocations = allLocations.filter(
      (location) => location.parentLocationId === parentId
    );

    let menuItems = [];

    parentLocations.forEach((location) => {
      const breadcrumb = parentBreadcrumb
        ? `${parentBreadcrumb} > ${location.name}`
        : location.name;
      const menuItem = {
        value: location.id,
        label: breadcrumb,
        breadcrumb: breadcrumb,
        level: level,
        isParent: parentId === null,
      };
      menuItems.push(menuItem);

      const children = generateMenuItems(location.id, breadcrumb, level + 1);
      menuItems = menuItems.concat(children);
    });
    // Sort menu items by level
    // menuItems.sort((a, b) => a.level - b.level);
    return menuItems;
  };

  console.log("All Locations:", allLocations);
  console.log("Generated Menu Items:", generateMenuItems());

  const customGroupStyles = {
    padding: "10px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  };

  const customOptionStyles = {
    option: (provided, state) => ({
      ...provided,
      // paddingLeft:
      //   state.data.level > 1 ? 20 + "px" : state.data.level * 20 + "px",
      paddingLeft: "16px",
      fontWeight: state.data.isParent ? "500" : "300",
      // backgroundColor: state.data.isParent ? "#dedbdb" : "#ffffff",
    }),
  };

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      width: "300px", // Set the desired width here
      borderBottom: "0px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "300px",
      marginTop: "0px",
    }),
  };
  /*
const deselectItems = () =>{

  setSelectedItems([]);

}
*/

  const handleLocationSelect = (selectedOption) => {
    if (selectedOption) {
      const locationId = selectedOption.value;

      // Move the selected items to the chosen location
      selectedItems.forEach((itemId) => {
        const item = items.find((item) => item.id === itemId);
        if (item) {
          updateItem(itemId, { locationId: locationId });
        }
      });

      // Clear the selected items after moving
      onItemsMoved([]);

      // Call the onSelect function for any additional actions
      onSelect(locationId);

      // Close the Select

      setOpen(false);
      console.log("ItemActions inputLocation", inputLocation);
      //deselectItems
      history.push(`/location/${inputLocation}`);
      props.onSnackbarMessage({ message: "Item(s) Moved", type: "success" });
    }
  };

  return (
    <>
      <Tooltip title="Delete Selected">
        <IconButton
          size="large"
          variant="outlined"
          onClick={handleDeleteSelectedItems}
          style={{ marginLeft: "8px" }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Move Selected">
        <IconButton
          size="large"
          variant="outlined"
          ref={anchorRef}
          onClick={handleToggle}
        >
          <DriveFileMoveIcon />
        </IconButton>
      </Tooltip>

      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          // placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <Select
                  defaultMenuIsOpen
                  isClearable
                  ref={selectRef}
                  placeholder="Move to..."
                  options={generateMenuItems()}
                  styles={{ ...customOptionStyles, ...customSelectStyles }}
                  onChange={handleLocationSelect}
                  menuPortalTarget={document.body} // render menu options in a portal attached to body
                  menuPosition={"fixed"} // use fixed position for the menu
                  // onInputChange={onInputChange}
                />
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>

      {formAlert && formAlert.message && (
        <Box mb={4}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      {/* {locationData} */}
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item={true} xs={12}>
          <Select
            isClearable
            ref={selectRef}
            options={generateMenuItems()}
            styles={customOptionStyles}
            onChange={handleParentLocationChange}
          />
        </Grid>
      </form> */}
    </>
  );
}

export default ItemActions;
