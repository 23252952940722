import {
  useQuery,
  useQueries,
  hashQueryKey,
  QueryClient,
  useQueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import { v4 as uuidv4 } from "uuid";
import { nanoid, customAlphabet } from "nanoid";

import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
  deleteField,
  increment,
  runTransaction,
} from "firebase/firestore";
import { getDatabase, ref as databaseRef, push } from "firebase/database";
import { database } from "./firebase"; // Adjust the import path according to your file structure
import { getAuth } from "firebase/auth";

import { firebaseApp } from "./firebase";
import { useState, useEffect, useCallback, useMemo } from "react";
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuid } from "uuid";
import { useAuth } from "./auth";
import { useMutation } from "react-query";
import {
  initializeFirestore,
  persistentLocalCache,
  enableIndexedDbPersistence,
} from "firebase/firestore";

//import { getDatabase } from "firebase/database";

// Gabe Code
// import { enableIndexedDbPersistence } from 'firebase/firestore';
// Initialize Firestore
// const db = getFirestore(firebaseApp);

// New Offline Persistance Code MT
const db = getFirestore(firebaseApp);

// const db = getFirestore(firebaseApp, {localCache: persistentLocalCache()});

// enableIndexedDbPersistence(db)
//     .catch(err => {
//         if (err.code === 'failed-precondition') {
//             // Multiple tabs open, persistence can only be enabled
//             // in one tab at a time.
//             console.log("Multiple tabs open, persistence can only be enabled in one tab at a time.");
//         } else if (err.code === 'unimplemented') {
//             // The current browser does not support all of the
//             // features required to enable persistence.
//             console.log("The current browser does not support all of the features required to enable persistence.");
//         }
//     });
// const db = getFirestore(firebaseApp, { localCache: persistentLocalCache() });

// console.log("Offline persistence enabled:", db._persistenceKey === 'local');

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// export function useUser(uid) {
//   const { status, ...rest } = useQuery(
//     ["user", { uid }],
//     createQuery(() => doc(db, "users", uid)),
//     { enabled: !!uid }
//   );

//   return {
//     ...rest,       // Spread the rest of the properties from `useQuery`
//     userStatus: status // Rename `status` to `userStatus`
//   };
// }

export function useUserByUsername(usernameURL) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("idle"); // can be 'idle', 'loading', 'success', 'error'
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!usernameURL) return;

    setStatus("loading");
    // Convert the provided username to lowercase and use it for querying
    const usernameURLLowercase = usernameURL.toLowerCase();
    getDocs(
      query(
        collection(db, "users"),
        where("nameLowercase", "==", usernameURLLowercase)
      )
    )
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setData({ id: userDoc.id, ...userDoc.data() });
          setStatus("success");
        } else {
          throw new Error("User not found");
        }
      })
      .catch((err) => {
        setError(err);
        setStatus("error");
      });
  }, [usernameURL]);

  return { data, status, error };
}

// Create a new user
export function createUser(uid, data) {
  const userData = {
    ...data,
  };

  return setDoc(doc(db, "users", uid), userData, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  const updatedData = {
    ...data,
  };

  return updateDoc(doc(db, "users", uid), updatedData);
}

// Fetch a Users Data
// export function useFetchUser(uid) {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchUser = async () => {
//       const userDoc = await getDoc(doc(db, "users", uid));
//       if (userDoc.exists()) {
//         setUser(userDoc.data());
//       } else {
//         console.error("No user found with uid:", uid);
//       }
//       setLoading(false);
//     };

//     if (uid) {
//       fetchUser();
//     }
//   }, [uid]);

//   return { user, loading };
// }

export function useFetchUser(uid) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);  // Adding error state

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", uid));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          throw new Error(`No user found with uid: ${uid}`);  // Throw an error if no user found
        }
      } catch (e) {
        setError(e);  // Catch and set any errors that occur during fetching
        console.error("Error fetching user:", e.message);
      }
      setLoading(false);
    };

    if (uid) {
      fetchUser();
    }
  }, [uid]);

  return { user, loading, error };  // Returning the error state for use in components
}

// Subscribe to user's customFields data
export function useCustomItemFieldsByUser(uid) {
  return useQuery(
    ["userCustomFields", { uid }],
    createQuery(() =>
      query(
        collection(db, "users", uid, "customItemFields")
        //orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!uid }
  );
}

// // Update an custom item field
// export function updateCustomItemField(uid, id, data) {

//   return setDoc(doc(db, "users", uid, "customItemFields", id), data), {merge:true};
// }

// Update or create a custom item field
export async function updateCustomItemField(uid, id, data) {
  const customItemFieldRef = doc(db, "users", uid, "customItemFields", id);

  // Use the 'merge: true' option with setDoc
  return setDoc(customItemFieldRef, data, { merge: true });
}

// Delete a custom item field
export async function deleteCustomItemField(uid, id) {
  const customItemFieldRef = doc(db, "users", uid, "customItemFields", id);

  // Delete the document using deleteDoc
  return deleteDoc(customItemFieldRef);
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
// export function useItem(id) {
//   return useQuery(
//     ["item", { id }],
//     createQuery(() => doc(db, "items", id)),
//     { enabled: !!id }
//   );
// }

export function useItem(id) {
  return useQuery(
    ["item", { id }],
    async () => {
      
      const itemDoc = await getDoc(doc(db, "items", id));
      const itemData = itemDoc.exists() ? itemDoc.data() : null;
      return { ...itemData, id };
    },
    { enabled: !!id }
  );
}



// export function useItem(id) {
//   return useQuery(
//     ["item", { id }],
//     async () => {
//       if (!id) {
//         // Return a default value or null, but after the async function has started
//         // This maintains the hook rules and prevents the query from doing unnecessary work
//         return null;
//       }

//       const itemDoc = await getDoc(doc(db, "items", id));
//       const itemData = itemDoc.exists() ? itemDoc.data() : null;
//       return { ...itemData, id };
//     },
//     { 
//       enabled: !!id  // This ensures the query is only run when id is truthy
//     }
//   );
// }


// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Fetch item data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getItem(id) {
  return getDoc(doc(db, "items", id)).then(format);
}

export async function getItemByScanCode(scanCode) {
  const itemsRef = collection(db, "items");
  const q = query(itemsRef, where("scanCode", "==", scanCode));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log("No matching item found.");
    return null;
  } else {
    const item = querySnapshot.docs[0];
    return format(item);
  }
}

export async function getItemsByScanCode(scanCode) {
  const itemsRef = collection(db, "items");
  const q = query(itemsRef, where("scanCode", "==", scanCode));
  const querySnapshot = await getDocs(q);

  const items = [];
  querySnapshot.forEach((doc) => {
    items.push(doc.data());
  });

  return items;
}

export async function getLocationsByScanCode(scanCode) {
  const locationsRef = collection(db, "locations");
  const q = query(locationsRef, where("scanCode", "==", scanCode));
  const querySnapshot = await getDocs(q);

  const locations = [];
  querySnapshot.forEach((doc) => {
    locations.push({ id: doc.id, ...doc.data() });
  });

  return locations;
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

export const useUserCurrency = () => {
  const auth = useAuth();
  const currencySymbol = auth.user.currency || "$"; // Default to "$" if no currency is set
  return currencySymbol;
};

export async function createItem(itemData) {
  console.log("Creating item with owner:", itemData.owner);

  const itemId = itemData.id ? itemData.id : shortId();
  const userRef = doc(db, "users", itemData.owner);
  const itemRef = doc(db, "items", itemId);

  try {
    // Ensure listId is stored as an array, even if it's a single value or undefined
    const listIdArray = Array.isArray(itemData.listId) ? itemData.listId : itemData.listId ? [itemData.listId] : [];
    const dataToSave = { ...itemData, listId: listIdArray, createdAt: serverTimestamp() };

    // Set the item in the items collection
    await setDoc(itemRef, dataToSave);
    console.log("Item created with ID:", itemId);

    // Increment the itemCount for the user in the users collection
    await updateDoc(userRef, {
      itemCount: increment(1), // v9's increment function
    });

    return itemRef;  // Optionally return the document reference
  } catch (error) {
    console.error("Error in createItem function:", error);
    throw new Error('Failed to create item.');  // Throw error to be handled by caller
  }
}

export async function updateItem(itemId, itemData) {
  const itemRef = doc(db, "items", itemId);

  try {
    // Flatten the listId array if it contains nested arrays
    if (itemData.listId && Array.isArray(itemData.listId)) {
      itemData.listId = itemData.listId.flat();
    }

    // Update the item in the items collection
    await updateDoc(itemRef, { ...itemData, updatedAt: serverTimestamp() });

    // Check if the item exists in the publicItems collection
    const publicItemsQueryRef = query(
      collection(db, "publicItems"),
      where("originalItemId", "==", itemId)
    );
    const publicItemsQuerySnapshot = await getDocs(publicItemsQueryRef);

    if (!publicItemsQuerySnapshot.empty) {
      // If it exists, update the item in the publicItems collection as well
      const publicItemDoc = publicItemsQuerySnapshot.docs[0];
      const publicItemRef = doc(db, "publicItems", publicItemDoc.id);
      await updateDoc(publicItemRef, { ...itemData });
    }

    return true;
  } catch (error) {
    console.error("Error updating item:", error);
    return false;
  }
}


// Example of deleting an item
export async function deleteItem(itemId) {
  const auth = getAuth();
  const uid = auth.currentUser?.uid;
  console.log("delete item uid", uid);
  if (!uid) {
    console.error("No user is currently signed in.");
    return;
  }

  const itemRef = doc(db, "items", itemId);
  await deleteDoc(itemRef);
  const userRef = doc(db, "users", uid);
  await updateDoc(userRef, {
    itemCount: increment(-1),
  });
}

export async function deleteItems(itemIds) {
  const auth = getAuth();
  const uid = auth.currentUser?.uid;
  if (!uid) {
    console.error("No user is currently signed in.");
    return;
  }

  const db = getFirestore();

  try {
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, "users", uid);

      // Process each item deletion within the transaction
      itemIds.forEach(itemId => {
        const itemRef = doc(db, "items", itemId);
        transaction.delete(itemRef);
      });

      // Atomically decrement the itemCount by the number of items deleted
      transaction.update(userRef, { itemCount: increment(-itemIds.length) });
    });

    console.log("Items deleted and itemCount updated successfully");
  } catch (error) {
    console.error("Failed to delete items and update itemCount:", error);
  }
}


/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs, we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

/// Firebase Storage

const storage = getStorage(firebaseApp);

export const useFirebaseImage = (imagePath) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (imagePath) {
      const getImageUrl = async () => {
        try {
          console.log("Fetching download URL for image:", imagePath);
          const imageRef = ref(storage, imagePath);
          const url = await getDownloadURL(imageRef);
          setImageUrl(url);
        } catch (error) {
          console.error("Error getting download URL image:", error);
        }
      };

      getImageUrl();
    } else {
      setImageUrl(null);
    }
  }, [imagePath, storage]);

  return imageUrl;
};

export { storage };

export const deleteImageFromStorage = async (imagePath) => {
  const storageRef = getStorage(firebaseApp);
  const imageRef = ref(storageRef, imagePath);

  try {
    // Check if the image exists
    await getDownloadURL(imageRef);

    // If the above line succeeds, proceed with deletion
    await deleteObject(imageRef);
    console.log("Image deleted successfully from storage.");
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      console.log("Image not found in storage. Skipping deletion.");
    } else {
      console.error("Error deleting image from storage:", error);
      throw error;
    }
  }
};

export const useFirstItemImage = (itemId) => {
  const [firstImage, setFirstImage] = useState(null);

  useEffect(() => {
    const fetchFirstImage = async () => {
      if (itemId) {
        const itemRef = doc(db, "items", itemId);
        const itemSnap = await getDoc(itemRef);

        if (itemSnap.exists()) {
          const itemData = itemSnap.data();
          if (itemData.images && itemData.images.length > 0) {
            setFirstImage(itemData.images[0]);
          }
        }
      }
    };

    fetchFirstImage();
  }, [itemId]);

  return firstImage;
};

export function useItemsByOwnerRealTime(owner) {
  const queryClient = useQueryClient();
  const queryKey = ["items", { owner }];
  const queryHash = hashQueryKey(queryKey);

  useEffect(() => {
    if (owner) {
      const itemsRef = query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      );

      const unsubscribe = onSnapshot(
        itemsRef,
        (snapshot) => {
          const items = snapshot.docs.map(format);
          queryClient.setQueryData(queryKey, items);
        },
        (error) => {
          console.error("Error listening to items:", error);
        }
      );

      unsubs[queryHash] && unsubs[queryHash]();
      unsubs[queryHash] = unsubscribe;

      return () => {
        if (unsubs[queryHash]) {
          unsubs[queryHash]();
          delete unsubs[queryHash];
        }
      };
    }
  }, [owner, queryClient, queryKey]);

  return useQuery(queryKey, () => [], { enabled: false });
}

export function shortId() {
  // Set the custom character set for the short IDs
  //  shortid.characters('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
  //  const shortID = shortid.generate();

  return uuid();

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const idLength = 10;
  const nanoid = customAlphabet(alphabet, idLength);

  const shortID = nanoid();
  return shortID;
}

export function useLocation(locationId) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!locationId) {
      setStatus("success");
      return;
    }

    setStatus("loading");

    const unsubscribe = onSnapshot(
      doc(db, "locations", locationId),
      (doc) => {
        setData({ id: doc.id, ...doc.data() });
        setStatus("success");
      },
      (error) => {
        setError(error);
        setStatus("error");
      }
    );

    return () => {
      unsubscribe();
    };
  }, [locationId]);

  return { data, status, error };
}

export function useItemsByOwnerAndLocation(owner, locationId) {
  return useQuery(
    ["items", owner, locationId],
    async () => {
      const snapshot = await getDocs(
        query(
          collection(db, "items"),
          where("owner", "==", owner),
          where("locationId", "==", locationId)
        )
      );

      return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    },
    {
      enabled: !!owner && locationId !== null,
    }
  );
}

export async function createLocation(locationData) {
  //const locationId = uuid();
  const locationId = locationData.id ? locationData.id : uuid();
  const locationRef = doc(db, "locations", locationId);

  await setDoc(locationRef, { ...locationData, createdAt: serverTimestamp() });
  //await setDoc(locationRef, locationData);

  return locationRef;
}

export async function updateLocation(locationId, locationData) {
  console.log(
    "db updateLocation locationId locationData",
    locationId,
    locationData
  );
  const locationRef = doc(db, "locations", locationId);
  console.log("db updateLocation", locationRef, locationData);
  try {
    await updateDoc(locationRef, {
      ...locationData,
      updatedAt: serverTimestamp(),
    });

    //await updateDoc(locationRef, {parentLocationId:"universe"});
  } catch (error) {
    console.error("db updateLocate Error moving location:", error);
  }
  return locationRef;
}

// Fetch all locations by owner (hook)
export function useLocationsByOwner(ownerID) {
  const locations = useQuery(
    ["locations", "user", { ownerID }],
    createQuery(() =>
      query(
        collection(db, "locations"),
        where("owner", "==", ownerID)
        // orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!ownerID }
  );

  return locations;
}

export function useLocations(ownerID) {
  return useQuery(
    ["locations", "user", { ownerID }],
    async () => {
      const snapshot = await getDocs(
        query(collection(db, "locations"), where("owner", "==", ownerID))
      );

      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    { enabled: !!ownerID }
  );
}

async function fetchItemsByParentAndChildren(parentId, ownerId) {
  const parentLocationRef = doc(db, "locations", parentId);
  const parentLocationSnap = await getDoc(parentLocationRef);

  if (!parentLocationSnap.exists()) {
    return [];
  }

  const childLocations = await getDocs(
    query(
      collection(db, "locations"),
      where("parentLocationId", "==", parentId),
      where("owner", "==", ownerId)
    )
  );

  const childItemsPromises = childLocations.docs.map(async (childLocation) => {
    const items = await fetchItemsByParentAndChildren(
      childLocation.id,
      ownerId
    );
    return items;
  });

  const nestedChildItems = await Promise.all(childItemsPromises);

  const parentItemsSnap = await getDocs(
    query(
      collection(db, "items"),
      where("locationId", "==", parentId),
      where("owner", "==", ownerId)
    )
  );

  const parentItems = parentItemsSnap.docs.map((itemDoc) => ({
    ...itemDoc.data(),
    id: itemDoc.id,
  }));

  const allItems = [...parentItems, ...nestedChildItems.flat()];

  return allItems;
}

export function useItemsByParentAndChildren(parentId, ownerId) {
  return useQuery(
    ["itemsByParentAndChildren", { parentId }],
    () => fetchItemsByParentAndChildren(parentId, ownerId),
    { enabled: !!parentId }
  );
}

//hook to set users selected item fields

export function useItemFieldSelections() {
  const auth = useAuth();
  const [itemFields, setItemFields] = useState({
    name: true,
    description: true,
    price: true,
    quantity: true,
    category: true,
  });

  useEffect(() => {
    const fetchItemFieldSelections = async () => {
      try {
        const userDocRef = doc(db, "users", auth.user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.itemFieldSelections) {
            setItemFields(userData.itemFieldSelections);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching item field selections: ", error);
      }
    };

    if (auth.user) {
      fetchItemFieldSelections();
    }
  }, [auth.user]);

  const updateItemFieldSelections = async (updatedFields) => {
    try {
      await db
        .collection("users")
        .doc(auth.user.uid)
        .update({ itemFieldSelections: updatedFields });

      setItemFields(updatedFields);
    } catch (error) {
      console.error("Error updating item field selections: ", error);
    }
  };

  return [itemFields, updateItemFieldSelections];
}

///////////

export function useDeleteLocation() {
  const deleteLocation = useCallback(async (locationId) => {
    try {
      const locationRef = doc(db, "locations", locationId);
      await deleteDoc(locationRef);
      console.log("Location deleted successfully:", locationId);
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  }, []);

  return deleteLocation;
}

async function deleteLocationWithChildren(
  locationId,
  ownerId,
  batch = writeBatch(db)
) {
  const locationRef = doc(db, "locations", locationId);

  // Fetch child locations
  const childLocationsSnap = await getDocs(
    query(
      collection(db, "locations"),
      where("parentLocationId", "==", locationId),
      where("owner", "==", ownerId)
    )
  );

  // Recursively delete child locations and their items
  for (const childLocationDoc of childLocationsSnap.docs) {
    await deleteLocationWithChildren(childLocationDoc.id, ownerId, batch);
  }

  // Fetch items associated with the location
  const itemsSnap = await getDocs(
    query(
      collection(db, "items"),
      where("locationId", "==", locationId),
      where("owner", "==", ownerId)
    )
  );

  // Delete items in the location
  itemsSnap.docs.forEach((itemDoc) => {
    batch.delete(doc(db, "items", itemDoc.id));
  });

  // Delete the location itself
  batch.delete(locationRef);
}

export function useDeleteLocationWithChildren() {
  const deleteLocationWithChildrenFn = useCallback(
    async (locationId, ownerId) => {
      try {
        const batch = writeBatch(db);
        await deleteLocationWithChildren(locationId, ownerId, batch);
        await batch.commit(); // Commit the batch delete here
        console.log(
          "Location and its children deleted successfully:",
          locationId
        );
      } catch (error) {
        console.error("Error deleting location and its children:", error);
      }
    },
    []
  );

  return deleteLocationWithChildrenFn;
}

export async function handleScan(db, qrCodeMessage) {
  try {
    const docRef = doc(db, "items", qrCodeMessage);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      // This QR code does not exist in our database.
      // You could add it here if you want.
      await setDoc(docRef, {
        /* your data */
      });
    } else {
      // This QR code already exists in our database.
      // Handle this situation as needed.
    }
  } catch (error) {
    console.error("Error in handleScan:", error);
  }
}

export const createList = async (listData, auth) => {
  try {
    const listsRef = collection(db, "lists");
    const defaultSelectedFields = ["name", "images"];

    // Step 1: Create the document
    const docRef = await addDoc(listsRef, {
      ...listData,
      selectedFields: defaultSelectedFields,
      owner: auth.user.uid,
      status: "active",
    });

    console.log("List document written with ID: ", docRef.id);

    // Step 2: Update the document to include its ID as a field
    await updateDoc(docRef, {
      id: docRef.id,
    });

    console.log("List document updated with its own ID as a field.");
  } catch (error) {
    console.error("Error adding list document: ", error);
  }
};

export const updateList = async (listId, updatedData) => {
  try {
    if (!listId) {
      console.error("listId is null or undefined.");
      return;
    }

    if (typeof listId !== "string" || listId.trim() === "") {
      console.error("listId is not a valid string.");
      return;
    }

    const listRef = doc(db, "lists", listId);

    await updateDoc(listRef, updatedData);

    console.log("List document updated successfully.");
  } catch (error) {
    console.error("Error updating list document: ", error);
    throw error;
  }
};

export const getListsByOwner = async (ownerId) => {
  try {
    const listsRef = collection(db, "lists");
    const q = query(listsRef, where("owner", "==", ownerId));
    const querySnapshot = await getDocs(q);

    let lists = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      lists.push(doc.data());
    });

    return lists;
  } catch (error) {
    console.error("Error getting lists by owner: ", error);
  }
};

export const fetchListByListnameAndId = async (listnameListid) => {
  try {
    // Splitting to get the listname and listId
    const splitData = listnameListid.split("-");
    const listname = splitData.slice(0, -1).join("-");
    const shortenedListId = splitData[splitData.length - 1];

    const listsRef = collection(db, "lists");

    // Query to match the listname and ensure the ID ends with the shortened ID
    const q = query(listsRef, where("name", "==", listname));

    const querySnapshot = await getDocs(q);

    let matchingList = null;
    querySnapshot.forEach((doc) => {
      if (doc.id.endsWith(shortenedListId)) {
        matchingList = { ...doc.data(), id: doc.id };
      }
    });

    return matchingList;
  } catch (error) {
    console.error("Error getting list by name and ID: ", error);
  }
};

export function useList(listId) {
  const [listData, setListData] = useState(null);
  const [listStatus, setListStatus] = useState("idle");
  const [listError, setListError] = useState(null);

  useEffect(() => {
    if (!listId) {
      setListStatus("success");
      return;
    }

    setListStatus("loading");

    const listRef = doc(db, "lists", listId);

    // Use onSnapshot to listen for real-time updates
    const unsubscribe = onSnapshot(
      listRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setListData(docSnapshot.data());
          setListStatus("success");
        } else {
          console.log("List does not exist");
          setListData(null);
          setListStatus("not-found");
        }
      },
      (error) => {
        console.error("Error getting list by ID: ", error);
        setListData(null);
        setListStatus("error");
        setListError(error);
      }
    );

    // Clean up the listener when the component is unmounted
    return () => {
      unsubscribe();
    };
  }, [listId]);

  return { listData, listStatus, listError };
}

// export function usePublishItem() {

//   const publishItem = async (itemId, selectedFields, listId, allFields) => {
//     try {
//       console.log("Fetching the item from 'items' collection...");
//       const itemDocRef = doc(db, "items", itemId);
//       const itemSnap = await getDoc(itemDocRef);

//       if (!itemSnap.exists()) {
//         console.error("Item does not exist in 'items' collection.");
//         throw new Error("Item does not exist");
//       }

//       const itemData = itemSnap.data();
//       const fieldsToRemove = allFields.filter(
//         (field) =>
//           !selectedFields.includes(field) &&
//           field !== "listId" &&
//           field !== "owner"
//       );
//       console.log("Fields to be removed:", fieldsToRemove);
//       console.log("Fetched item data:", itemData);
//       console.log(
//         "Fields to filter for 'publicItems' collection:",
//         selectedFields
//       );

//       // Filter the item's fields based on the user's selection
//       const publicData = selectedFields.reduce((acc, field) => {
//         if (itemData[field] !== undefined) {
//           acc[field] = itemData[field];
//         }
//         return acc;
//       }, {});

//       // Ensure the 'owner' is included in publicData.
//       if (itemData.owner) {
//         publicData.owner = itemData.owner;
//       }



//       publicData.originalItemId = itemId;
//       if (listId) {
//         publicData.listId = listId;
//       }

 

//       const queryRef = query(
//         collection(db, "publicItems"),
//         where("originalItemId", "==", itemId)
//       );
//       const querySnapshot = await getDocs(queryRef);

//       if (!querySnapshot.empty) {
        
//         const existingDocId = querySnapshot.docs[0].id;
//         const existingDocRef = doc(db, "publicItems", existingDocId);

//         const updateData = { ...publicData };
        
//         fieldsToRemove.forEach((field) => {
//           updateData[field] = deleteField();
//         });
//         await updateDoc(existingDocRef, updateData);
//         console.log("update data", updateData)
//       } else {
//         console.log(
//           "Attempting to add filtered item data to 'publicItems' collection..."
//         );
//         const uuid = uuidv4();
//         const publicItemRef = doc(db, "publicItems", uuid);
//         await setDoc(publicItemRef, publicData);
//       }

//       console.log("Document successfully set in 'publicItems'.");
//       return true;
//     } catch (err) {
//       console.error("Error occurred during the Firestore operation:", err.message);
//       return false;
//     }
//   };

//   return { publishItem };
// }

export async function removeItemFromListAndDeletePublicItem(itemId) {
  try {
    // 1. Update the document in the items collection to remove the listId
    const itemRef = doc(db, "items", itemId);
    await updateDoc(itemRef, {
      listId: deleteField(), // Remove the listId field
    });

    // 2. Remove the document from the publicItems collection using the originalItemId field
    const queryRef = query(
      collection(db, "publicItems"),
      where("originalItemId", "==", itemId)
    );
    const querySnapshot = await getDocs(queryRef);

    console.log("Query Results for originalItemId:", querySnapshot.docs);

    if (!querySnapshot.empty) {
      const publicItemId = querySnapshot.docs[0].id; // Get the ID of the document to be deleted
      const publicItemRef = doc(db, "publicItems", publicItemId);
      await deleteDoc(publicItemRef);
      console.log(
        `Document with ID: ${publicItemId} deleted from publicItems.`
      );
    } else {
      console.log(
        `No document found in publicItems with originalItemId: ${itemId}`
      );
    }

    return true;
  } catch (error) {
    console.error("Error processing item removal: ", error);
    return false;
  }
}

// export function usePublicItemsByListId(listId) {
//   const [items, setItems] = useState([]);
//   const [status, setStatus] = useState("idle");

//   useEffect(() => {
//     if (!listId) {
//       console.log("no list id")
//       return; // Exit if no listId is provided
//     }

//     setStatus("loading");

//     // Create a query against the items collection where listId array contains the specified listId
//     const itemsQuery = query(
//       collection(db, "items"),
//       where("listId", "array-contains", listId)
//     );

//     getDocs(itemsQuery)
//       .then(async (itemsSnapshot) => {
//         // Extract item IDs from the items collection
//         const itemIds = itemsSnapshot.docs.map(doc => doc.id);
//         const publicItems = [];

//         // Create queries for each item to find corresponding publicItems by originalItemId
//         for (const itemId of itemIds) {
//           const publicItemsQuery = query(
//             collection(db, "publicItems"),
//             where("originalItemId", "==", itemId)
//           );

//           const publicItemsSnapshot = await getDocs(publicItemsQuery);
//           publicItemsSnapshot.forEach(doc => {
//             publicItems.push({
//               id: doc.id,
//               ...doc.data(),
//             });
//           });
//         }

//         setItems(publicItems);
//         setStatus("success");
//       })
//       .catch((error) => {
//         console.error("Error fetching items and corresponding public items:", error);
//         setStatus("error");
//       });
//   }, [listId]);

//   return { items, status };
// }

export function usePublicItemsByListId(listId) {
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    if (!listId) {
      console.log("No list ID provided");
      return; // Exit if no listId is provided
    }

    setStatus('loading');

    // Create a query against the publicItems collection where listId array contains the specified listId
    const publicItemsQuery = query(
      collection(db, "publicItems"),
      where("listId", "array-contains", listId)
    );

    getDocs(publicItemsQuery)
      .then(querySnapshot => {
        const itemsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setItems(itemsData);
        setStatus('success');
      })
      .catch(error => {
        console.error("Error fetching public items:", error);
        setStatus('error');
      });

  }, [listId]);  // Ensure useEffect is re-run if listId changes

  return { items, status };
}