import React, { useEffect, useState } from "react";
import {
  useUser,
  useCustomItemFieldsByUser,
  updateCustomItemField,
  deleteCustomItemField,
} from "./../util/db";
import { useAuth } from "../util/auth";
import {
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  Typography,
  CardHeader,
  ListSubheader,
  TextField,
  IconButton,
  Divider,
  Alert
} from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

function CustomItemFields() {
  const auth = useAuth();

  const defaultItemFields = [
    // { id:  'Name', type:"string" },
    // { id:  'Description', type:"string" },
    { id: "Purchase Price", type: "number", plan: "free", currency: true },
    // { id: "Quantity", type: "number", plan: "free" },
    { id: "Serial Number", type: "string", plan: "free" },
    { id: "Model Number", type: "string", plan: "free" },
    { id: "Manufacturer", type: "string", plan: "free" },
    { id: "Purchase Date", type: "boolean", plan: "free" },
    { id: "Sold", type: "boolean", plan: "free" },
    { id: "Sold Price", type: "string", plan: "free", currency: true },
    { id: "Sold Date", type: "string", plan: "free" },
    { id: "Buyer Name", type: "string", plan: "free" },
  ];

  const [selectedAttributes, setSelectedAttributes] = useState({});

  const [customFieldName, setCustomFieldName] = useState("");
  const [customFieldType, setCustomFieldType] = useState("string");

  const handleCancelClick = () => {
    setCustomFieldName("");
  };

  const handleAttributeChange = (attributeName, isChecked, meta) => {
    console.log("attributeName:", attributeName, "isChecked:", isChecked);
    setSelectedAttributes({
      ...selectedAttributes,
      [attributeName]: isChecked,
    });

    if (isChecked === false) {
      console.log("is checked false");
      deleteCustomItemField(auth.user.uid, attributeName);
      return;
    }

    updateCustomItemField(auth.user.uid, attributeName, meta);
  };

  const handleCustomFieldCreate = () => {
    if (customFieldName.trim() === "") {
      alert("Field name cannot be empty.");
      return;
    }

    setSelectedAttributes({
      ...selectedAttributes,
      [customFieldName]: true,
    });

    updateCustomItemField(auth.user.uid, customFieldName, {
      type: customFieldType,
    });
    setCustomFieldName("");
    setCustomFieldType("string");
  };

  const { data: user } = useUser(auth.user.uid);
  const { data: customFields } = useCustomItemFieldsByUser(auth.user.uid);

  // useEffect(() => {
  //   console.log("customFields", customFields);
  // }, [customFields]);

  useEffect(() => {
    console.log("customFields", customFields);
    
    if (customFields) {
      const newSelectedAttributes = {};
      
      customFields.forEach((field) => {
        newSelectedAttributes[field.id] = true;
      });
      
      setSelectedAttributes(newSelectedAttributes);
    }
  }, [customFields]);

  return (
    <>


  {/* Custom Created Fields */}
  <List dense={true} subheader={<ListSubheader>Selected Fields</ListSubheader>}>
    {customFields?.length === 0 ? (
      <ListItem>
       <Alert severity="info">No custom fields. Add your own custom fields below, or select from commonly used fields.</Alert>
   
      </ListItem>
    ) : (
      customFields?.map((field, attribute) => (
        <ListItem key={field.id}>
          <FormControlLabel
            control={
              <IconButton onClick={() => handleAttributeChange(field.id, false, {type:field.type})}>
                {selectedAttributes[field.id] ? (
                   <RemoveCircleOutlineOutlinedIcon color="primary" />
                ) : (
                  <AddCircleOutlineOutlinedIcon />
                )}
              </IconButton>
            }
            label={field.id}
          />
        </ListItem>
      ))
    )}
  </List>



  <Divider variant="middle" sx={{ my: 2 }} >
  <Typography variant="body2" color="textSecondary" sx={{ textTransform: 'uppercase' }}>
    Create Custom Fields
  </Typography>
</Divider>



      <List
        dense={true}
        // subheader={<ListSubheader>Create Custom Field</ListSubheader>}
      >
 

        <ListItem display="flex">
          {/* <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" marginBottom={1}> */}
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              <TextField
                label="Custom Field Name"
                value={customFieldName}
                onChange={(e) => setCustomFieldName(e.target.value)}
                sx={{ marginRight: 1, flexGrow: 1 }}
              />

              <FormControl >
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
          
                  label="Type"
                  value={customFieldType}
                  onChange={(e) => setCustomFieldType(e.target.value)}
               
                >
                  <MenuItem value="string">Text</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="boolean">True / False</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box pt={2} display="flex" justifyContent="flex-end">
            <Button variant="text" onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleCustomFieldCreate}>
                Add
              </Button>
            </Box>
          </div>
        </ListItem>
      </List>




      <Divider variant="middle" sx={{ my: 2 }}>
  <Typography variant="body2" color="textSecondary" sx={{ textTransform: 'uppercase' }}>
    Commonly Used Fields
  </Typography>
</Divider>


{/* <Divider /> */}
      {/* Suggested Fields */}

      <List
        dense={true}
        // subheader={<ListSubheader>Suggested Fields</ListSubheader>}
      >
        {defaultItemFields
          .filter((attribute) => !selectedAttributes[attribute.id])
          .map((attribute) => (
            <div key={attribute.id}>
            <ListItem>
              <FormControlLabel
                control={
                  <IconButton
                    onClick={() => {

                      console.log("CustomItemFields attribute", attribute)
                      handleAttributeChange(
                        attribute.id,
                        !selectedAttributes[attribute.id],
                        {...attribute}

                      )

                    }
                    }
                  >
                    {selectedAttributes[attribute.id] ? (
                      <RemoveCircleOutlineOutlinedIcon color="error" />
                    ) : (
                      <AddCircleOutlineOutlinedIcon />
                    )}
                  </IconButton>
                }
                label={attribute.id}
              />
            </ListItem>
            </div>
          ))}
      </List>

    </>
  );
}

export default CustomItemFields;
