import React from 'react';

const Debug = ({ children }) => {
  const debug = process.env.REACT_APP_DEBUG === 'true';

  if (debug) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default Debug;
