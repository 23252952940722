import React, { useState, useEffect } from "react";
import { getListsByOwner } from "../util/db";

function ListsByOwner({ userId }) {
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchLists = async () => {
        try {
          const data = await getListsByOwner(userId); // Using the getListsByOwner function
          setLists(data);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchLists();
    }, [userId]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    return (
      <div>
 
        <ul>
          {lists?.map((list, index) => (
            <li key={index}>{list.name}</li>
          ))}
        </ul>
      </div>
    );
  }
  
  export default ListsByOwner;