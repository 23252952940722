import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Section from "./Section";
import { Link } from "./../util/router";
import { useTheme } from "@mui/styles";

import { useLocation } from "react-router-dom";
import { Divider } from "@mui/material";




function Footer(props) {
  const theme = useTheme();
  const location = useLocation();
  const noFooterPaths = ["/spreadsheet", ]; // Add all paths where you do not want the footer to display

  const shouldDisplayFooter = () => {
    for (let path of noFooterPaths) {
      if (location.pathname.startsWith(path)) {
        return false;
      }
    }
    return true;
  }

  if (!shouldDisplayFooter()) {
    return null;
  }
  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && theme.name === "dark"
      ? props.logoInverted
      : props.logo;

  const styles = {
    item: {
      display: "flex",
      flex: "none",
      justifyContent: "center",
      width: "100%",
      mb: "24px",
      [theme.breakpoints.up("sm")]: {
        // Take up half the width
        flex: "50%",
      },
    },
    leftItem: {
      [theme.breakpoints.up("sm")]: {
        // Position children to the left
        justifyContent: "flex-start",
      },
    },
    rightItem: {
      [theme.breakpoints.up("sm")]: {
        // Position children to the right
        justifyContent: "flex-end",
      },
      // Social and nav links
      "& a": {
        color: "inherit",
        lineHeight: 1,
        "&:not(:last-of-type)": {
          mr: "1.2rem",
        },
      },
    },
  };

  return (
  
    <Section
      mt={15}
      bgColor={props.bgColor}
      size='auto'
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      sx={{
        ...(props.sticky && {
          mt: "auto",
        }),
      }}
    >
        <Divider />

      <Container sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={[styles.item, styles.leftItem]}>

        </Box>
        <Box
          sx={[
            {
              // On mobile show link row
              // above social icons
              [theme.breakpoints.up("sm")]: {
                order: 1,
              },
            },
            styles.item,
            styles.rightItem,
          ]}
        >
          <Typography>
            {/* <MuiLink component={Link} to="/about">
              About
            </MuiLink> */}
            {/* <MuiLink component={Link} to="/faq">
              FAQ
            </MuiLink> */}
            {/* <MuiLink component={Link} to="/contact">
              Contact
            </MuiLink> */}
            {/* <MuiLink href="https://medium.com" target="_blank" rel="noreferrer">
              Blog
            </MuiLink> */}
          </Typography>
        </Box>
        {/* <Box
          sx={[
            {
              // Position icons at bottom
              // so closer to nav links
              alignItems: "flex-end",
            },
            styles.item,
            styles.rightItem,
          ]}
        >
          <a href="https://twitter.com/divjoy" target="_blank" rel="noreferrer">
            <TwitterIcon fontSize="small" />
          </a>
          <a
            href="https://facebook.com/DivjoyOfficial"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon fontSize="small" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noreferrer">
            <InstagramIcon fontSize="small" />
          </a>
        </Box> */}


        <Box
          pt={3}
          sx={[
            {
              alignItems: "flex-start",
              fontSize: "0.875rem",
              opacity: 0.6,
              "& a": {
                color: "inherit",
                ml: "0.8rem",
              },
            },
            styles.item,
            styles.leftItem,
          ]}
        >


         
          {props.copyright}
          <MuiLink component={Link} to="/legal/terms-of-service">
            Terms
          </MuiLink>
          <MuiLink component={Link} to="/legal/privacy-policy">
            Privacy
          </MuiLink>
          <MuiLink component={Link} to="/contact">
         Contact Us
          </MuiLink>
        </Box>
      </Container>
    </Section>
  );
}

export default Footer;
