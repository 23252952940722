import React, { useState } from 'react';
import { Dialog, MobileStepper, Button, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, Close as CloseIcon } from '@mui/icons-material';

export default function ImageCarousel({ images, open, onClose }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <IconButton 
        style={{ position: 'absolute', top: 10, right: 10, zIndex: 2 }} 
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
        <IconButton 
          style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }} 
          onClick={handleBack} 
          disabled={activeStep === 0}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <img
          src={images[activeStep]?.main || ""}
          alt={`Image ${activeStep + 1}`}
          style={{ width: 'auto', maxHeight: '60vh', objectFit: 'contain', maxWidth:'100%' }}
        />
        <IconButton 
          style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }} 
          onClick={handleNext} 
          disabled={activeStep === images.length - 1}
        >
          <KeyboardArrowRight fontSize="large" />
        </IconButton>
        <MobileStepper
          steps={images.length}
          variant="dots"
          position="static"
          activeStep={activeStep}
          style={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)', backgroundColor: 'transparent' }}
          nextButton={<></>}
          backButton={<></>}
        />
      </div>
    </Dialog>
  );
}
