import React from "react";
import Meta from "../components/Meta";
import ContentCardsSection from "../components/ContentCardsSection";
import TotalPriceComponent from "../components/TotalPriceItems";
import TotalQuantityItems from "../components/TotalQuantityItems";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Content" />

      <ContentCardsSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title="Item Summary (beta)"
        subtitle=""
      />
    </>
  );
}

export default DashboardPage;
