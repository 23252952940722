import React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Section from "./Section";
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from "./../util/auth";
import { useTheme } from "@mui/styles";
import { Chip, Divider, Stack } from "@mui/material";
import { darken, alpha } from '@mui/system';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

function NavSecondary(props) {
  const theme = useTheme();
  const auth = useAuth();

  const NavChip = ({ label, additionalActivePaths = [] }) => {
    const history = useHistory();
    const location = useLocation();
    const isActive = location.pathname === `/${label.toLowerCase()}` || additionalActivePaths.some(path => location.pathname.startsWith(path));    const handleNavigation = () => {
      history.push(`/${label.toLowerCase()}`);
    };



    return (
      <Chip
      label={label}
      onClick={handleNavigation}
      variant={isActive ? 'filled' : 'outlined'}
      color="primary"
      sx={{
        cursor: 'pointer',
      }}
    />
    );
  };

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container maxWidth={false} disableGutters={true}>
          <Toolbar>
            <Stack direction="row" spacing={1} sx={{ overflow: 'auto', whiteSpace: 'nowrap',  '&::-webkit-scrollbar': {
    display: 'none'
  } }}>
              <NavChip label="Dashboard" />
              {/* <NavChip label="Inventory" additionalActivePaths={['/location']} /> */}
              <NavChip label="Inventory" additionalActivePaths={['/location', '/item']} />
              <NavChip label="Lists" />
              <NavChip label="Spreadsheet" />
              <NavChip label="QR-Codes" />
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Divider />
    </Section>
 
  );
}

export default NavSecondary;