import React, { useEffect, useState } from "react";
import AnnouncementPage from "./announcement";
import NavbarPage from "./navbar";
import HeroPage from "./hero";
import CallToActionPage from "./call-to-action";
import FaqPage from "./faq";
import NewsletterPage from "./newsletter";
import FeaturesPage from "./features";
import ClientsPage from "./clients";
// import ContentPage from "./content";
import MediaPage from "./media";
import TestimonialsPage from "./testimonials";
import SocialPage from "./social";
// import TeamPage from "./team";
import StatsPage from "./stats";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import FooterPage from "./footer";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import DashboardPage from "./dashboard";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import Nav from "./../Nav.js";
import IndexPage from "./index";
import SpreadsheetPage from "./spreadsheet";
import LocationPage from "./location";
import Footer from "./../components/Footer"
import QRPage from "./qr";
import ListPage from "./lists"
import PublicItemPage from "./publicitem";
import PublicListPage from "./publiclist";
import PublicListsPage from "./publiclists";
function App(props) {



  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
   
          <Router>
            <Nav />
            <div style={{ flex: 1 }}>
            <Switch>
              <Route exact path="/" component={IndexPage} />
              <Route exact path="/firebase-action" component={FirebaseActionPage} />
              <Route exact path="/announcement" component={AnnouncementPage} />

              <Route exact path="/navbar" component={NavbarPage} />

              <Route exact path="/hero" component={HeroPage} />

              <Route exact path="/dashboard" component={DashboardPage} />

              <Route
                exact
                path="/call-to-action"
                component={CallToActionPage}
              />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/newsletter" component={NewsletterPage} />

              <Route exact path="/features" component={FeaturesPage} />

              <Route exact path="/clients" component={ClientsPage} />

              {/* <Route exact path="/content" component={ContentPage} /> */}

              <Route exact path="/media" component={MediaPage} />

              <Route exact path="/testimonials" component={TestimonialsPage} />

              <Route exact path="/social" component={SocialPage} />

            

              {/* <Route exact path="/team" component={TeamPage} /> */}

              <Route exact path="/stats" component={StatsPage} />

              <Route exact path="/pricing" component={PricingPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/footer" component={FooterPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              {/* <Route exact path="/dashboard" component={DashboardPage} /> */}
       
             
           

{/* ADMIN ROUTES */}

              <Route exact path="/spreadsheet" component={SpreadsheetPage} />
              <Route exact path="/inventory" component={LocationPage} />
              <Route exact path="/lists" component={ListPage} />
              <Route exact path="/qr-codes" component={QRPage} />
              <Route exact path="/purchase/:plan" component={PurchasePage} />
              <Route path="/location/:locationId" component={LocationPage} />
              <Route path="/item/:itemId" component={LocationPage} />

          



              <Route path="/p/item/:itemId" component={PublicItemPage} />
              
              <Route path="/:listId" component={PublicListPage} />
              {/* <Route path="/:listid([a-zA-Z0-9]+-[A-Za-z0-9]+)" component={PublicListPage} />  */}

              {/* <Route path="/:username" component={PublicListsPage} /> */}

              <Route path="*" component={NotFoundPage} />
           
            </Switch>
            </div>
            <Footer  />
          </Router>
        </AuthProvider>
    
      </ThemeProvider>
    </QueryClientProvider>
    </div>
  );
}

export default App;
