import React, { useState, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, useTheme, useMediaQuery, DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import Select from "react-select/creatable";
import {
  useLocationsByOwner,
  useLocation,
  updateLocation,
  createLocation,
} from "./../util/db";

import { getNestedChildren } from "./../util/functions";

import { makeStyles } from "@mui/styles";
// import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LocationRow from "./LocationRow";
import {
  FormControl,
  InputLabel,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Debug from "./Debug";
import QRCode from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";
import SelectLocation from "./SelectLocation";
import QrCodePrint from "./QrCodePrint";
import { useHistory } from "react-router-dom";

import QRCodeAdd from "./QRCodeAdd";

///
//import { firestore } from "firebase/app";
//import "firebase/firestore";
//import { firebaseApp } from "./firebase";
//

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 124,
  },
}));

function EditLocationModal(props) {
  const { onDone, selectedLocationId, handleDelete } = props;
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [parentLocations, setParentLocations] = useState([]);

  const [availableLocations, setAvailableLocations] = useState([]);

  const theme = useTheme();

  const [scanCode, setScanCode] = useState();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { data: allLocations = [], status: allLocationsStatus } =
    useLocationsByOwner(auth.user.uid);

  console.log("all locations", allLocations);

  console.log("auth user uid", auth.user.uid);

  const handleParentLocationChange = (event) => {
    console.log("event", event);
    console.log("event.target", event.value);
    setParentLocationId(event.value);
  };

  const [locationId, setLocationId] = useState();

  useEffect(() => {
    if (props.id == null) {
      setLocationId(uuid());
      return;
    }
    setLocationId(props.id);
    //const locationId = props.id ? props.id : uuid()
  }, [props.id]);

  useEffect(() => {
    if (locationId == null) {
      return;
    }
    //if (props.open === false) {return;}
    //if (selectedLocationId === locationId) {return;}
    // history.push(`/location/${locationId}`);
  }, [locationId]);

  useEffect(() => {
    if (props.open === false) {
      return;
    }
    if (locationId == null) {
      return;
    }
    history.push(`/location/${locationId}`);
  }, [props.open]);

  /*
  useEffect(() => { 
  //const newDocRef = firestore().collection("locations").doc();
  //const newDocRef = doc(db, 'locations');

  const newDocRef = doc(collection(db, 'locations'));

  const newLocationId = newDocRef.id;
  console.log("newlocationid", newLocationId);

},[]); 
*/
  useEffect(() => {
    if (allLocationsStatus === "success") {
      // This filteredLocations was not used.
      // Leaving it here for now. Vetigial.
      const filteredLocations = allLocations.filter(
        (location) =>
          location.id !== props.id &&
          location.id !== selectedLocationId &&
          !location.parentLocationId
      );

      // Build an array l containing the self location
      // and all child locations.

      const childLocations = getNestedChildren(allLocations, locationId);
      const l = childLocations.map((location) => {
        return location.id;
      });

      //console.log("childLocations l", l);

      // You cannot move a parent to a child.
      // So remove these.
      const strippedLocations = allLocations.filter((location) => {
        if (l.includes(location.id)) {
          //        return true;
          return false;
        }
        return true;
        //      return false;
      });

      setAvailableLocations(strippedLocations);

      setParentLocations(filteredLocations);
    }
  }, [allLocations, allLocationsStatus, props.id, selectedLocationId]);

  const baseURL = "https://wheresmything.com/location/";
  const locationURL = baseURL + selectedLocationId;

  const { register, handleSubmit, errors } = useForm();

  const { data: locationData, status: locationStatus } = useLocation(props.id);
  const [parentLocationId, setParentLocationId] = useState("");

  useEffect(() => {
    if (props.id) {
      setParentLocationId(locationData?.parentLocationId || "");
    } else {
      setParentLocationId(selectedLocationId || "");
    }
  }, [props.id, locationData, selectedLocationId]);

  const onNewScanResult = async (decodedText, decodedResult) => {
    // handle decoded results here
    //await handleScan(db, decodedText);
    console.log(
      "EditItemModal decodedText decodedResult",
      decodedText,
      decodedResult
    );

    setScanCode(decodedText);
    // props.onSnackbarMessage("Saw unique identifier " + decodedText);
  };

  // If we are updating an existing location
  // don't show modal until location data is fetched.
  if (props.id && locationStatus !== "success") {
    return null;
  }

  const onSubmit = (data) => {
    try {
      setPending(true);
      setFormAlert(null);
      console.log("EditLocationModal x data", data);

      // Check if no location is selected and set parentLocationId to null
      const tempParentLocationId =
        parentLocationId == null || parentLocationId === ""
          ? null
          : parentLocationId;

      if (typeof scanCode !== "undefined") {
        data["scanCode"] = scanCode;
      }

      // Remove parentLocationId from data
      delete data.parentLocationId;

      const newData = { ...data, parentLocationId: tempParentLocationId };
      //console.log("parentLocationId", parentLocationId, newData)

      console.log("EditLocationModal x newData", newData);

      // const query = props.id
      //const isNewLocation = !props.id;
      //const locationId = isNewLocation ? newDocRef.id : props.id;
      //    const query = !isNewLocation
      //      ? updateLocation(props.id, newData)
      //      : createLocation({ id: locationId, owner: auth.user.uid, ...newData });

      const query = props.id
        ? updateLocation(props.id, newData)
        : createLocation({ id: locationId, owner: auth.user.uid, ...newData });

      if (navigator.onLine) {
        query
          .then(() => {
            // Let parent know we're done so they can hide modal

            props.onDone();

            props.onSnackbarMessage({
              message: props.id
                ? "Location Updated Successfully"
                : "Location Created Successfully",
              type: "success",
            });
          })
          .catch((error) => {
            // Show error alert message
            setFormAlert({
              type: "error",
              message: error.message,
            });
          })
          .finally(() => {
            // Hide pending indicator
            history.push(`/location/${locationId}`);
            setPending(false);
          });
      } else {
        // If offline, let the user know the data will be synced later
        console.log("Offline. Data will be synced when online.");
        history.push(`/location/${locationId}`);
        setPending(false);

        props.onDone();

        props.onSnackbarMessage({
          message:
            "Offline. Data has been saved locally and will be synced when online.",
          type: "success",
        });
      }
    } catch (error) {
      console.error("Error block in onSubmit:", error);
      // You can also show an error message to the user here
      setPending(false);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <Debug>{selectedLocationId}</Debug>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.id && <>Update</>}
        {!props.id && <>Create</>}
        {` `}Location
        <IconButton onClick={props.onDone}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>


        {formAlert && formAlert.message && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

        {scanCode}

        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <QRCodeAdd
                inputScanCode={locationData?.scanCode}
                fps={100}
                qrbox={350}
                disableFlip={false}
                qrCodeSuccessCallback={(decodedText, decodedResult) =>
                  onNewScanResult(decodedText, decodedResult)
                }
              />
            </Grid>
            {/* <Grid item={true} xs={12}>
<QrCodePrint locationURL={locationURL} />
</Grid> */}

            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label="Location Name"
                name="name"
                defaultValue={locationData && locationData?.name}
                error={errors.name ? true : false}
                helperText={errors.name && errors?.name.message}
                fullWidth={true}
                autoFocus={true}
                inputRef={register({
                  required: "Please enter a location name",
                })}
              />
            </Grid>
            <Debug>
              {selectedLocationId}
              Uuid to be created {locationId}
              <br />
            </Debug>

      
            <Grid item={true} xs={12}>
            
              <SelectLocation
                //allLocations={allLocations}
                allLocations={availableLocations}
                handleParentLocationChange={handleParentLocationChange}
                parentLocationId={parentLocationId}
                selectedLocationId={selectedLocationId}
                locationId={locationId}
              />
             
            </Grid>

</Grid>

</DialogContent>


<Box sx={{bottom:'0', position:'absolute', width:'100%'}}>
<DialogActions
                
                style={{
                  // position: "sticky",
                  // bottom: "0",
                  background: "#ffffff",
                  width:'100%',
                  padding:'20px', 
                  marginTop: "10px",
                  // zIndex:"1",
                  borderTop:'2px solid grey' 

                }}
              >
              {props.id && (
                <>
                  {" "}
                  <Button
                    size="small"
                    color="secondary"
                    onMouseDown={(e) => {
                      e.preventDefault(); // Prevent default mousedown behaviors
                      handleDelete();
                    }}
                    onTouchStart={(e) => {
                      e.preventDefault(); // Prevent default touch behaviors
                      handleDelete();
                    }}
                  >
                    Delete Location
                  </Button>
                </>
              )}

              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
                style={{ marginLeft: "1rem" }}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent default mousedown behaviors
                  handleSubmit(onSubmit)();
                }}
                onTouchStart={(e) => {
                  e.preventDefault(); // Prevent default touch behaviors
                  handleSubmit(onSubmit)();
                }}
              >
                {!props.id && <span>Create Location</span>}

                {!pending && props.id && <span>Save</span>}
                {pending && <CircularProgress size={28} />}
              </Button>

</DialogActions>
</Box>
        </form>
    
    </Dialog>
  );
}

export default EditLocationModal;
